import { useState, useEffect } from "react"
import * as React from "react"
import styled from "styled-components"
import { useLanguageState } from "../globalStates/LanguageState"
import { useLoggedInState } from "../globalStates/LoggedInUser"
import {
    getPrivacyUserAnswer,
    addPrivacyPolicyAnswer,
    BackendServiceError,
    createPrivacyUserQuestionId
} from "../backendServices/BackendServices"
import { PrivacyUserAnswer } from "../backendServices/Types"
import Linkify from "react-linkify"
import branding from "../branding/branding"
import ReactDOM from "react-dom"
import parse from "html-react-parser"
import { SimpleOrganization } from "../contentArea/ExhibitorsPageContent"

enum AnswerOption {
    AGREE = "agree",
    DISAGREE = "disagree",
    NONE = "none"
}

const ModalRoot = styled.div`
    font-family: ${branding.font1};
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    color: ${branding.mainInfoColor};

    a {
        color: ${branding.mainInfoColor};
    }
`

const ModalDialog = styled.div`
    max-width: 550px;
`
const ModalContent = styled.div`
    padding: 30px;
    border-radius: 5px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
`

const ModalBody = styled.div``

const ModalBodyText = styled.div`
    font-size: 12px;
    line-height: 20px;
    font-style: normal;
    font-family: ${branding.font1};
    font-weight: normal;
    white-space: pre-line;
`

const ModalBodyTitle = styled.p`
    width: auto;
    height: 20px;
    font-size: 18px;
    line-height: 20px;
    font-style: normal;
    font-family: ${branding.font1};
    font-weight: bold;
    margin-bottom: 25px !important;

    @media (max-width: 1400px) {
        font-size: 16px;
    }
`
const SubmitButtonContainer = styled.div`
    justify-content: flex-end;
    margin-top: 4rem;
`
const AgreeButton = styled.div`
    display: block;
    width: 30%;
    height: 35px;
    /* margin-top: 20px; */
    color: ${branding.globalTexts.agreeBtnTextColor ?? "#fff"};
    background: ${branding.globalTexts.agreeBtnBackgroundColor ?? "#000"};
    border: 1px solid ${branding.recommendModal.submitBtnPrimaryBorderColor}!important;
    border-radius: 5px;
    font-size: ${branding.globalTexts.privacyPolicyFontSize ?? "15px"};
    line-height: 17px;
    font-family: ${branding.font1};
    cursor: pointer;
    text-align: center;
    padding: ${branding.recommendModal.privacyPolicyDisagreeButtonPadding ?? "0px"};

    :hover {
        background-color: ${branding.recommendModal.submitBtnPrimaryOnHoverBgColor} !important;
        color: ${branding.recommendModal.submitBtnPrimaryOnHoverTextColor} !important;
    }
`

const DisagreeButton = styled.div`
    display: block;
    width: 30%;
    height: 35px;
    color: ${branding.recommendModal.cancelBtnPrimaryTextColor ?? "#000"};
    background-color: transparent;
    border: 1px solid ${branding.recommendModal.cancelBtnPrimaryBorderColor}!important;
    border-radius: 5px;
    font-size: ${branding.globalTexts.privacyPolicyFontSize ?? "15px"};
    line-height: 17px;
    font-family: ${branding.font1};
    margin-right: 15px;
    cursor: pointer;
    text-align: center;
    padding: ${branding.recommendModal.privacyPolicyDisagreeButtonPadding ?? "0px"};

    :hover {
        background-color: ${branding.recommendModal.submitBtnPrimaryOnHoverBgColor} !important;
        color: ${branding.recommendModal.submitBtnPrimaryOnHoverTextColor} !important;
        text-decoration: none;
    }
`

interface PrivacyPolicyTextProps {
    privacyPolicyText: string
    consentText?: string
}

const PrivacyPolicyText: React.FunctionComponent<PrivacyPolicyTextProps> = (props) => {
    const strings = useLanguageState().getStrings().globalTexts
    let searchStr = strings.privacyPolicyBoldText
    let index = props.privacyPolicyText.indexOf(searchStr)
    let first = props.privacyPolicyText.substring(0, index)
    let boldStr = <b>{searchStr}</b>
    let second = props.privacyPolicyText.substring(index + searchStr.length, props.privacyPolicyText.length)

    let content = null

    const componentDecorator = (href: string, text: string, key: number) => (
        <a
            style={{ color: branding.mainInfoColor, textDecoration: "underline" }}
            href={href}
            key={key}
            target="_blank"
            rel="noopener noreferrer"
        >
            {text}
        </a>
    )

    if (props.consentText) {
        content = <Linkify componentDecorator={componentDecorator}>{parse(props.consentText.replaceAll("\\n", ""))}</Linkify>
    } else {
        content = branding.globalTexts.hasLinkAtTheEnd ? (
            <Linkify componentDecorator={componentDecorator}>
                {first}
                {boldStr}
                {second}
                {
                    <a
                        href={strings.privacyPolicyEndLink}
                        key={" "}
                        style={{ color: branding.mainInfoColor, textDecoration: "underline" }}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {strings.privacyPolicyEndLinkText}
                    </a>
                }
            </Linkify>
        ) : (
            <Linkify componentDecorator={componentDecorator}>
                {first}
                {boldStr}
                {second}
            </Linkify>
        )
    }

    return <ModalBodyText>{content}</ModalBodyText>
}

interface PrivacyPolicyModalProps {
    callback: (accepted: boolean) => void
    organizationId: string
    consentText?: string
    consentTitle?: string
}

const PrivacyPolicyModal: React.FunctionComponent<PrivacyPolicyModalProps> = (props) => {
    const questionId = createPrivacyUserQuestionId(props.organizationId)
    const userLink = useLoggedInState()
    const profileId = userLink.user()?.profileId
    const privacyUserAnswer = userLink.getPrivacyUserAnswer(questionId)
    const strings = useLanguageState().getStrings()
    const [isLoaded, setIsLoaded] = useState(privacyUserAnswer?.accepted)
    const [accepted, setAccepted] = useState(isLoaded)

    useEffect(() => {
        if (isLoaded && accepted) {
            props.callback(true)
        }
    }, [isLoaded, accepted]) // eslint-disable-line

    function addPrivacyAnswer(e: React.MouseEvent<HTMLElement>) {
        e.stopPropagation()
        e.preventDefault()

        const answerText = e.currentTarget.id
        if (answerText === AnswerOption.AGREE) {
            addPrivacyPolicyAnswer({
                sotUserId: profileId!,
                questionText: strings.globalTexts.privacyPolicyText,
                answerText: answerText,
                organizationId: props.organizationId
            }).then((response: PrivacyUserAnswer | BackendServiceError) => {
                if ((response as PrivacyUserAnswer).questionId) {
                    userLink.setPrivacyUserAnswer(response as PrivacyUserAnswer)
                    setAccepted(true)
                }
            })
        } else {
            props.callback(false)
        }
    }

    useEffect(() => {
        if (profileId && props.organizationId && !isLoaded)
            getPrivacyUserAnswer(profileId!, props.organizationId).then((response: PrivacyUserAnswer | undefined) => {
                setIsLoaded(true)
                setAccepted(response?.accepted || false)
                if (response) {
                    userLink.setPrivacyUserAnswer(response)
                }
            })
        //eslint-disable-next-line
    }, [props.organizationId])

    if (!isLoaded) {
        return null
    } else if (accepted) {
        return null
    } else {
        return (
            <ModalRoot className="modal d-block" role="dialog">
                <ModalDialog className="modal-dialog modal-dialog-centered" role="document">
                    <ModalContent className="modal-content">
                        <ModalBody className="modal-body">
                            <div className="row">
                                <ModalBodyTitle>{props.consentTitle ?? strings.globalTexts.privacyPolicyTitle}</ModalBodyTitle>
                                <PrivacyPolicyText
                                    privacyPolicyText={strings.globalTexts.privacyPolicyText}
                                    consentText={props.consentText}
                                />
                            </div>
                            <SubmitButtonContainer className="row d-flex">
                                <DisagreeButton
                                    id={AnswerOption.DISAGREE}
                                    onClick={addPrivacyAnswer}
                                    className="d-flex align-items-center justify-content-center"
                                >
                                    {strings.globalTexts.privacyPolicyDisagreeText}
                                </DisagreeButton>
                                <AgreeButton
                                    id={AnswerOption.AGREE}
                                    onClick={addPrivacyAnswer}
                                    className="d-flex align-items-center justify-content-center"
                                >
                                    {strings.globalTexts.privacyPolicyAgreeText}
                                </AgreeButton>
                            </SubmitButtonContainer>
                        </ModalBody>
                    </ModalContent>
                </ModalDialog>
            </ModalRoot>
        )
    }
}

export default PrivacyPolicyModal

export const usePrivacyPolicyModal = () => {
    const [isVisible, setIsVisible] = useState(false)
    const [data, setData] = useState<{ organization: SimpleOrganization | null; callback: (accepted: boolean) => void }>({
        organization: null,
        callback: (accepted) => {}
    })

    const showPrivacyPolicyModal = (organization: SimpleOrganization, callback: (accepted: boolean) => void) => {
        setData({ organization: organization, callback: callback })
        setIsVisible(true)
    }

    const PrivacyModal = () =>
        ReactDOM.createPortal(
            <React.Fragment>
                {isVisible && data.organization && (
                    <PrivacyPolicyModal
                        callback={(accepted) => {
                            setIsVisible(false)
                            data.callback(accepted)
                        }}
                        organizationId={data.organization.id}
                        consentText={data.organization.consentText}
                        consentTitle={data.organization.consentTitle}
                    />
                )}
            </React.Fragment>,
            document.body
        )

    return {
        showPrivacyPolicyModal,
        PrivacyModal
    }
}
