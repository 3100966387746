import { Fragment, useState, useEffect } from "react"
import * as React from "react"
import TileRow from "../../ui/TileRow"
import { useLanguageState } from "../../globalStates/LanguageState"
import styled from "styled-components"
import branding from "../../branding/branding"
import { IconSchedule, IconMeetingSchedule, IconOrganization, IconLinkRightArrow } from "../../ui/Icons"
import {
    mySchedulePageRoute,
    programPageRoute,
    bookmarkedExhibitorsPageRoute,
    showfloorPageRoute,
    bookmarkedSpeakersPageRoute,
    speakersPageRoute,
    productsPageRoute,
    trademarksPageRoute,
    newsPageRoute,
    bookmarkedProductsPageRoute,
    bookmarkedTrademarksPageRoute,
    bookmarkedNewsPageRoute,
    bookmarkedJoboffersPageRoute,
    joboffersPageRoute
} from "../../navigationArea/RoutePaths"
import EventDateEntry from "../program/EventDateEntry"
import { EventDate, Person, ShareTargetType, Exhibitor, Product, Trademark, News, JobOffer } from "../../backendServices/Types"
import { CalendarEntryModalViewMode } from "../../ui/CalendarEntryModal"
import {
    CalendarEntry,
    getCalendarEntries,
    loadEventDateList,
    loadExhibitorsData,
    loadProductsData,
    loadTrademarksData,
    loadEventSpeakersData,
    loadEventDateDates,
    loadNewsListData,
    loadJobofferListData
} from "../../backendServices/BackendServices"
import { CalendarEntryParticipationStatus } from "../../API"
import { useLoggedInState } from "../../globalStates/LoggedInUser"
import moment from "moment"
import { useFavoriteState } from "../../globalStates/Favorites"
import Schedule from "../program/Schedule"
import EmptyTile from "./EmptyTile"
import { buildDetailLink } from "../detailPages/DetailNavLink"
import BookmarkWithToggle from "../../ui/BookmarkWithToggle"
import { useNavigationSource } from "../../tracking/NavigationSource"
import { momentWithoutTimezoneFromTimezonedMoment, getIndexOfInitialDayToShow } from "../../utils/DateUtils"
import { NavLink } from "react-router-dom"
import { useAppState } from "../../globalStates/AppState"
import { orderBy } from "lodash"
import VizSensor from "react-visibility-sensor"
import { Tooltip } from "react-bootstrap"
import { SimpleOrganization } from "../ExhibitorsPageContent"
import { ActionType } from "../entitiesActions/actionsUtils"
import { getNextPackage, getPackageNumber } from "./LobbyPageContent"
import { BasisPremiumType } from "../../branding/BasisPremiumBranding"
import { LobbyExhibitorsBasisPremiumType } from "./ReceptionPageContentBranding"
import { device } from "../../utils/Device"
import { EntityCarousel } from "../../ui/carousels/EntityCarousel"
import { CustomOverlayTrigger } from "../../ui/CustomOverlayTrigger"

const MyScheduleContainer = styled.div`
    .verticalBarRoot {
        height: 80%;
        top: 120px;
    }
`

const MyScheduleRoot = styled.div`
    width: 99%;
    position: relative;

    .schedule-root {
        height: 50vh;
        overflow: hidden;
    }
    .my-schedule-root {
        margin-left: 0 !important;
    }
`

const ExhibitorTile = styled(NavLink)`
    position: relative;
    display: flex;
    flex-basis: 250px;
    justify-content: center;
    align-items: center;
    width: 250px;
    height: 250px;
    border-style: solid;
    border-width: 1px;
    border: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"};
    padding: 20px;
    margin: 0px 0 1.25rem 0px;
    color: #000;
    margin-right: ${branding.receptionPage.myFairPageExhibitorTilesMargin ?? "10px"};
    border-radius: 5px;

    &::before {
        background-color: white;
    }

    &:hover .show-on-hover {
        display: inline-block;
    }

    & .show-on-hover {
        display: none;
        color: ${branding.receptionPage.myFairBookmarkTileTextColor};
    }

    & .exhibitor-bookmark {
        position: absolute;
        top: 15px;
        right: 15px;
    }

    &.mobile-layout {
        flex-basis: 95px;
        width: 95px !important;
        height: 95px;
        padding: 10px;
    }

    &.mobile-layout .show-on-hover {
        color: ${branding.crsTabs.defaultActionItemColor};
    }
`

const ExhibitorTileLogo = styled.div`
    width: 70%;
    height: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;

    & img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    p  {
        font-family: ${branding.font1};

        @media ${device.mobile} {
            font-size: 12px;
        }
    }
`

const VisitExhibitorLink = styled.span`
    font-family: ${branding.font1};
    display: inline-block;
    text-decoration: none;
    position: absolute;
    width: 80%;
    z-index: 1;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    color: ${branding.receptionPage.myFairBookmarkTileTextColor};
    font-size: 16px;
    text-align: center;
`

export enum CalendarEntrySortType {
    ALL,
    PAST,
    FUTURE
}

interface MyPageContentProps {
    eventKey: string
    guestBannerHeight: number
    isMobile: boolean
    onViewModeSet: (value: CalendarEntryModalViewMode) => void
    onSetShowOrganizationModal: React.Dispatch<React.SetStateAction<boolean>>
    onSetTarget: React.Dispatch<React.SetStateAction<SimpleOrganization | undefined>> // for organization
    onSetTargetId: React.Dispatch<React.SetStateAction<string>> // for other entity types
    onSetLink: React.Dispatch<React.SetStateAction<string>>
    onSetType: React.Dispatch<React.SetStateAction<ShareTargetType | undefined>>
    onSelectedMeetingSet: React.Dispatch<React.SetStateAction<CalendarEntry | undefined>>
    onSetAction: React.Dispatch<React.SetStateAction<ActionType | undefined>>
    onSetShowPrivacyPolicyModal: React.Dispatch<React.SetStateAction<boolean>>
    onSetShowGuestModal: React.Dispatch<React.SetStateAction<boolean>>
}

const MyPageContent: React.FunctionComponent<MyPageContentProps> = (props) => {
    const abortController = new AbortController()
    const abortSignal = abortController.signal
    const languageState = useLanguageState()
    const lang = languageState.getLanguage()
    const strings = languageState.getStrings()
    let userState = useLoggedInState()
    const favorites = useFavoriteState()
    const nav = useNavigationSource()

    const [eventDates, setEventDates] = useState<EventDate[]>([])

    const [meetings, setMeetings] = useState<CalendarEntry[]>([])
    let [all, setAll]: Array<any> = useState([]) //meetings + eventdates
    const [personsBookmarks, setPersonsBookmarks] = useState<Person[]>([])
    const [showfloorsBookmarks, setShowfloorsBookmarks] = useState<Exhibitor[]>([])
    const [productsBookmarks, setProductsBookmarks] = useState<Product[]>([])
    const [trademarksBookmarks, setTrademarksBookmarks] = useState<Trademark[]>([])
    const [newsBookmarks, setNewsBookmarks] = useState<News[]>([])
    const [joboffersBookmarks, setJoboffersBookmarks] = useState<JobOffer[]>([])
    const [myPageExhibitorSponsors, setMyPageExhibitorSponsors] = useState<Exhibitor[]>()
    const [exhibitors, setExhibitors] = useState<Exhibitor[]>([])
    const [isLoaded, setIsLoaded] = useState<boolean>(false)
    const timezone = useAppState().timezone

    const [nextPackage, setNextPackage] = useState<BasisPremiumType>(BasisPremiumType.NONE)

    const [refreshKey, setRefreshKey] = useState(0)

    const [days, setDays] = useState<moment.Moment[]>([])
    const eventDays = branding.eventTiming.eventDays
    const [selectedDay, setSelectedDay] = useState(0)

    const basisPremiumList = branding.receptionPage.lobbyExhibitorsBasisPremiumTypesList

    const componentOrder: string[] = branding.receptionPage.myPageComponentOrder ?? [
        "SPONSORS",
        "UP_NEXT",
        "MY_SCHEDULE",
        "SPEAKERS",
        "EXHIBITORS",
        "PRODUCTS",
        "TRADEMARKS",
        "NEWS",
        "JOBOFFERS"
    ]

    const eventDateFavorites = favorites.get("eventdate", true)
    useEffect(() => {
        setEventDates((favoriteList) => favoriteList.filter((favorite) => favorites.is("eventdate", favorite.id)))
    }, [eventDateFavorites]) //eslint-disable-line

    const personFavorites = favorites.get("person", true)
    useEffect(() => {
        setPersonsBookmarks((favoriteList) => favoriteList.filter((favorite) => favorites.is("person", favorite.id)))
    }, [personFavorites]) //eslint-disable-line

    const showfloorsFavorites = favorites.get("organization", true)
    useEffect(() => {
        setShowfloorsBookmarks((favoriteList) => favoriteList.filter((favorite) => favorites.is("organization", favorite.id)))
    }, [showfloorsFavorites]) //eslint-disable-line

    const productFavorites = favorites.get("product", true)
    useEffect(() => {
        setProductsBookmarks((favoriteList) => favoriteList.filter((favorite) => favorites.is("product", favorite.id)))
    }, [productFavorites]) //eslint-disable-line

    const trademarkFavorites = favorites.get("trademark", true)
    useEffect(() => {
        setTrademarksBookmarks((favoriteList) => favoriteList.filter((favorite) => favorites.is("trademark", favorite.id)))
    }, [trademarkFavorites]) //eslint-disable-line

    const newsFavorites = favorites.get("news", true)
    useEffect(() => {
        setNewsBookmarks((favoriteList) => favoriteList.filter((favorite) => favorites.is("news", favorite.id)))
    }, [newsFavorites]) //eslint-disable-line

    const joboffersFavorites = favorites.get("joboffer", true)
    useEffect(() => {
        setJoboffersBookmarks((favoriteList) => favoriteList.filter((favorite) => favorites.is("joboffer", favorite.id)))
    }, [joboffersFavorites]) //eslint-disable-line

    const loadPremiumOrStandardExhibitors = (basisPremium: BasisPremiumType, numResultRows?: number) => {
        const basisPremiumPackage: LobbyExhibitorsBasisPremiumType | undefined = basisPremiumList.find(
            (e: LobbyExhibitorsBasisPremiumType) => e.packageName === basisPremium
        )

        if (basisPremiumPackage as LobbyExhibitorsBasisPremiumType) {
            const params: any = {
                filterlist: "entity_orga",
                basispremium: getPackageNumber(basisPremium),
                startresultrow: 0,
                numresultrows: basisPremiumPackage?.numberOfExhibitors,
                order: "random",
                lang: lang
            }

            loadExhibitorsData(params).then((resp) => {
                const ex = resp.entities as Exhibitor[]
                const exhibitorsTemp = exhibitors.filter((it) => !ex.some((e) => e.id === it.id))

                const respData = branding.receptionPage.lobbyExhibitorsShowTopSponsors
                    ? ex.filter((it) => !branding.receptionPage.lobbyTopSponsors.some((e) => e.id === it.id))
                    : ex

                setExhibitors(exhibitorsTemp.length === 0 ? respData : exhibitorsTemp.concat(respData))
                setNextPackage(getNextPackage(basisPremium))
            })
        } else {
            setNextPackage(getNextPackage(basisPremium))
            return
        }
    }

    useEffect(() => {
        if (favorites.get("eventdate").length > 0) {
            const upNextFavoriteEventsParams: any = {
                filterlist: [favorites.get("eventdate")],
                startresultrow: 0,
                numresultrows: 50,
                order: "chrono",
                day: eventDays[selectedDay],
                lang: lang,
                future: "true"
            }

            loadEventDateList(upNextFavoriteEventsParams, abortController.signal).then((response) => {
                setEventDates(response.eventDates)
            })
        }
        if (favorites.get("person").length > 0) {
            const defaultPersonBookmarksParams: any = {
                filterlist: "",
                personIds: [favorites.get("person", true)],
                personFunctions: branding.configuration.speakerPersonFunctions,
                eventDateParticipation: branding.configuration.eventDateParticipation,
                startresultrow: 0,
                numresultrows: 15,
                order: branding.programSpeakers.orderType,
                lang: lang
            }

            loadEventSpeakersData(defaultPersonBookmarksParams, abortSignal).then((response) => {
                setPersonsBookmarks(response.persons)
            })
        }
        if (favorites.get("organization").length > 0) {
            const defaultShowfloorBookmarkParams: any = {
                filterlist: ["entity_orga", favorites.get("organization")],
                startresultrow: 0,
                numresultrows: 15,
                order: "lexic",
                lang: lang
            }

            loadExhibitorsData(defaultShowfloorBookmarkParams, abortSignal).then((resp) => {
                setShowfloorsBookmarks(resp.entities as Exhibitor[])
            })
        }
        if (favorites.get("product").length > 0) {
            const defaultProductsBookmarkParams: any = {
                filterlist: ["entity_prod", favorites.get("product")],
                startresultrow: 0,
                numresultrows: 15,
                order: "lexic",
                lang: lang
            }

            loadProductsData(defaultProductsBookmarkParams, abortSignal).then((resp) => {
                setProductsBookmarks(resp.products)
            })
        }
        if (favorites.get("trademark").length > 0) {
            const defaultTrademarksBookmarkParams: any = {
                filterlist: ["entity_trad", favorites.get("trademark")],
                startresultrow: 0,
                numresultrows: 15,
                order: "lexic",
                lang: lang
            }

            loadTrademarksData(defaultTrademarksBookmarkParams, abortSignal).then((resp) => {
                setTrademarksBookmarks(resp.trademarks)
            })
        }
        if (favorites.get("news").length > 0) {
            const defaultNewsBookmarkParams: any = {
                filterlist: ["entity_news", favorites.get("news")],
                startresultrow: 0,
                numresultrows: 15,
                order: "lexic",
                lang: lang
            }

            loadNewsListData(defaultNewsBookmarkParams, abortSignal).then((resp) => {
                setNewsBookmarks(resp.newsList)
            })
        }

        if (favorites.get("joboffer").length > 0) {
            const defaultJoboffersBookmarkParams: any = {
                filterlist: ["entity_job", favorites.get("joboffer")],
                startresultrow: 0,
                numresultrows: 15,
                order: "lexic",
                lang: lang
            }

            loadJobofferListData(defaultJoboffersBookmarkParams, abortSignal).then((resp) => {
                setJoboffersBookmarks(resp.jobofferList)
            })
        }

        getCalendarEntries(
            userState.user()?.profileId!,
            CalendarEntrySortType.ALL,
            CalendarEntryParticipationStatus.ACCEPTED,
            undefined
        ).then((resp) => {
            let temp: CalendarEntry[] = []
            if (resp)
                resp.items.forEach((x) => {
                    temp.push(x.calendarEntry)
                })
            setMeetings(temp)
        })

        const sponsoredExhibitorsMyPageList = branding.receptionPage.sponsoredExhibitorsMyPageList
        let sponsoredExhibitorsFilterList: string[] = []

        if (sponsoredExhibitorsMyPageList) {
            sponsoredExhibitorsMyPageList.forEach((orga) => {
                sponsoredExhibitorsFilterList.push("id_orga_" + orga.id)
            })

            let sponsoredExhibitorsParams: any = {
                filterlist: "entity_orga, " + sponsoredExhibitorsFilterList.join(", "),
                startresultrow: 0,
                order: "lexic",
                lang: lang
            }
            loadExhibitorsData(sponsoredExhibitorsParams, abortSignal).then((resp) => {
                const ex = resp.entities as Exhibitor[]
                if (ex) {
                    ex.forEach((exhibitor) => {
                        exhibitor.order = sponsoredExhibitorsMyPageList.find((x) => x.id === exhibitor.id)?.order || -1
                    })

                    setMyPageExhibitorSponsors((e) => orderBy(ex, ["order"], ["asc"]))
                }
            })

            setIsLoaded(true)
        }

        let params = { lang: lang }
        loadEventDateDates(params)
            .then((resp) => {
                if (resp.dates?.length > 0) {
                    setDays(resp.dates.map((date) => moment(date)))
                }
            })
            .catch((e: { message: React.SetStateAction<string> }) => {
                setDays(eventDays.map((x) => moment(x)))
            })
        setRefreshKey(0)

        return () => {
            abortController.abort()
        }
        // Load the stuff only if we are logged in …
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.eventKey, lang, refreshKey, timezone])

    useEffect(() => {
        setNextPackage(BasisPremiumType.ULTIMATE)
    }, [isLoaded])

    useEffect(() => {
        if (nextPackage === BasisPremiumType.NONE) {
            return
        }
        loadPremiumOrStandardExhibitors(nextPackage)

        //eslint-disable-next-line
    }, [nextPackage])

    useEffect(() => {
        setSelectedDay(getIndexOfInitialDayToShow(days))
    }, [days, timezone])

    useEffect(() => {
        if (basisPremiumList.length > 1) {
            loadPremiumOrStandardExhibitors(basisPremiumList[1].packageName, basisPremiumList[1].numberOfExhibitors)
        }

        //eslint-disable-next-line
    }, [isLoaded])

    useEffect(() => {
        let temp: Array<any> = []
        let currentTime = momentWithoutTimezoneFromTimezonedMoment(moment(), timezone).format("HH:mm")
        let [currentHour, currentMinutes] = currentTime.split(":")
        let currentTimeStamp = parseInt(currentHour) * 60 + parseInt(currentMinutes)
        if (eventDates) {
            eventDates.forEach((x) => {
                if (x.endHour * 60 + x.endMinutes > currentTimeStamp) {
                    temp.push({
                        type: "eventdate",
                        object: x
                    })
                }
            })
        }

        if (meetings) {
            meetings.forEach((x) => {
                let date = momentWithoutTimezoneFromTimezonedMoment(moment(x.end), timezone).format("HH:mm")
                let [dateHours, dateMinutes] = date.split(":")
                let dateTimeStamp = parseInt(dateHours) * 60 + parseInt(dateMinutes)
                if (
                    moment(x.start).format("YYYY-MM-DD") === moment(new Date()).format("YYYY-MM-DD") &&
                    dateTimeStamp > currentTimeStamp
                ) {
                    temp.push({
                        type: "meeting",
                        object: x
                    })
                }
            })
        }

        setAll(temp)
    }, [eventDates, meetings, props.eventKey]) // eslint-disable-line

    const showMySchedule = eventDates.length > 0 || meetings.length > 0

    return (
        <Fragment>
            {componentOrder.map((component, outerKey) => {
                switch (component) {
                    case "SPONSORS":
                        const allOrgas: Exhibitor[] = []
                        if (myPageExhibitorSponsors) allOrgas.push(...myPageExhibitorSponsors)
                        if (branding.receptionPage.showPremiumExhibitorsWithSponsors && exhibitors) allOrgas.push(...exhibitors)
                        return <EntityCarousel key={outerKey} type="organization" entities={allOrgas} />
                    case "UP_NEXT":
                        return (
                            all.length > 0 && (
                                /* Up next section */
                                <TileRow
                                    key={outerKey}
                                    icon={IconSchedule({ fill: branding.sideIconBar.sideIconColorDark })}
                                    iconVisible={true}
                                    title={strings.receptionPage.upNextTitle}
                                    titleVisible={true}
                                    navLink={mySchedulePageRoute}
                                    navLinkTextVisible={true}
                                    navLinkText={strings.receptionPage.viewMySchedule}
                                    height={props.isMobile ? "95px" : "220px"}
                                    scrollBar={!props.isMobile}
                                    hideShadows={true}
                                    childCount={all.length}
                                    childWidth={props.isMobile ? 205 + 4 : 350 + 5}
                                >
                                    {all.slice(0, all.length).map((eventDate: any) => {
                                        return (
                                            <div
                                                key={eventDate.id}
                                                style={{
                                                    marginRight: props.isMobile ? "0px" : "10px",
                                                    marginBottom: props.isMobile ? "0px" : "1.25rem"
                                                }}
                                            >
                                                <EventDateEntry
                                                    key={eventDate.id}
                                                    eventDate={eventDate.object}
                                                    myScheduleObject={eventDate.object}
                                                    marginTop={10}
                                                    height={280}
                                                    mySchedule={eventDate.object.hasOwnProperty("title")}
                                                    upcoming={true}
                                                    isMobile={props.isMobile}
                                                />
                                            </div>
                                        )
                                    })}
                                </TileRow>
                            )
                        )
                    case "MY_SCHEDULE":
                        return (
                            /* My schedule */
                            <>
                                {props.isMobile ? null : (
                                    <MyScheduleContainer key={outerKey}>
                                        <TileRow
                                            icon={IconMeetingSchedule({
                                                stroke: branding.sideIconBar.sideIconColorDark,
                                                fill: branding.sideIconBar.sideIconColorDark
                                            })}
                                            iconVisible={true}
                                            title={strings.receptionPage.mySchedule}
                                            titleVisible={true}
                                            navLink={showMySchedule ? mySchedulePageRoute : programPageRoute}
                                            navLinkText={
                                                showMySchedule
                                                    ? strings.receptionPage.viewMySchedule
                                                    : strings.receptionPage.liveSessionsNavLinkText
                                            }
                                            navLinkTextVisible={true}
                                            scrollBar={false}
                                            childCount={1}
                                            childWidth={0}
                                        >
                                            {showMySchedule ? (
                                                <MyScheduleRoot>
                                                    <Schedule
                                                        roundScroller={true}
                                                        onlyFavorites={true}
                                                        dateColumns={true}
                                                        dateColumnsDays={[]}
                                                        onScroll={() => {}}
                                                        isInMyFairPage={true}
                                                        listViewMode={false}
                                                        mySchedule={true}
                                                        myScheduleResponsive={true}
                                                        day={selectedDay}
                                                        guestBannerHeight={props.guestBannerHeight}
                                                    />
                                                </MyScheduleRoot>
                                            ) : (
                                                <EmptyTile
                                                    header={strings.receptionPage.noEventsScheduled}
                                                    buttonNavLink={programPageRoute}
                                                    buttonMessage={strings.receptionPage.liveSessionsNavLinkText}
                                                    hideButton={true}
                                                    bgColor="#F2F2F2"
                                                />
                                            )}
                                        </TileRow>
                                    </MyScheduleContainer>
                                )}
                            </>
                        )
                    case "SPEAKERS":
                        return (
                            <EntityCarousel
                                emptyTileMessage={strings.receptionPage.noBookmarkedSpeakers}
                                type="person"
                                entities={personsBookmarks}
                                linkText={
                                    personsBookmarks.length > 0
                                        ? strings.receptionPage.speakersBookmarkedNavLinkText
                                        : strings.receptionPage.speakersNavLinkText
                                }
                                link={personsBookmarks.length > 0 ? bookmarkedSpeakersPageRoute : speakersPageRoute}
                            />
                        )
                    case "EXHIBITORS":
                        return (
                            /* Bookmarked exhibitors */
                            <TileRow
                                key={outerKey}
                                icon={IconOrganization({
                                    fill: branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor
                                })}
                                iconVisible={true}
                                title={strings.receptionPage.exhibitors}
                                titleVisible={true}
                                navLink={showfloorsBookmarks.length > 0 ? bookmarkedExhibitorsPageRoute : showfloorPageRoute}
                                navLinkText={
                                    showfloorsBookmarks.length > 0
                                        ? strings.receptionPage.showFloorBookmarkedNavLinkText
                                        : strings.receptionPage.showFloorNavLinkText
                                }
                                navLinkTextVisible={true}
                                hideShadows={props.isMobile}
                                height={props.isMobile ? "95px" : "250px"}
                                scrollBar={showfloorsBookmarks && showfloorsBookmarks.length > 0}
                                isMyFairPage
                                childWidth={props.isMobile ? 95 : 250}
                                childCount={showfloorsBookmarks?.length ?? 0}
                            >
                                {showfloorsBookmarks.length > 0 ? (
                                    showfloorsBookmarks.map((exhibitor, index) => (
                                        <>
                                            <VizSensor key={index} partialVisibility={true}>
                                                {({ isVisible }) =>
                                                    isVisible ? (
                                                        <ExhibitorTile
                                                            className={props.isMobile ? "mobile-layout" : ""}
                                                            onClick={() => nav.set("LOBBY")}
                                                            key={index}
                                                            to={buildDetailLink(exhibitor.id, exhibitor.name, "organization")}
                                                        >
                                                            {
                                                                <CustomOverlayTrigger
                                                                    placement="bottom"
                                                                    delay={{ show: 250, hide: 400 }}
                                                                    overlay={
                                                                        <Tooltip
                                                                            id="tooltip"
                                                                            style={{ fontFamily: branding.font1 }}
                                                                        >
                                                                            {showfloorsFavorites
                                                                                ? branding.organizationDetailPageContent
                                                                                      .unBookmarkPerson
                                                                                : branding.organizationDetailPageContent
                                                                                      .bookmarkPerson}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <div className={"exhibitor-bookmark"}>
                                                                        <BookmarkWithToggle
                                                                            className={"show-on-hover"}
                                                                            newBookmarkItem={true}
                                                                            type={"organization"}
                                                                            id={exhibitor.id}
                                                                            name={exhibitor.name}
                                                                            color={
                                                                                branding.receptionPage.myFairBookmarkTileIconColor
                                                                            }
                                                                            stroke={
                                                                                branding.receptionPage.myFairBookmarkTileIconColor
                                                                            }
                                                                        ></BookmarkWithToggle>
                                                                    </div>
                                                                </CustomOverlayTrigger>
                                                            }
                                                            <ExhibitorTileLogo>
                                                                {exhibitor.pictureURL ? (
                                                                    <img
                                                                        src={exhibitor.pictureURL}
                                                                        alt={exhibitor.name + " logo"}
                                                                    />
                                                                ) : (
                                                                    <p>
                                                                        {exhibitor.name.replace(
                                                                            props.isMobile ? /(.{25})..+/ : /(.{80})..+/,
                                                                            "$1…"
                                                                        )}
                                                                    </p>
                                                                )}
                                                            </ExhibitorTileLogo>
                                                            <VisitExhibitorLink className={"show-on-hover"}>
                                                                {strings.receptionPage.visitExhibitorText}
                                                            </VisitExhibitorLink>
                                                            <VisitExhibitorLink
                                                                className={"show-on-hover"}
                                                                style={{ left: "80%" }}
                                                            >
                                                                {IconLinkRightArrow({
                                                                    stroke: branding.receptionPage.myFairBookmarkTileTextColor
                                                                })}
                                                            </VisitExhibitorLink>
                                                        </ExhibitorTile>
                                                    ) : (
                                                        <div
                                                            style={{
                                                                width: props.isMobile ? 95 : 250,
                                                                height: props.isMobile ? 95 : 250
                                                            }}
                                                        ></div>
                                                    )
                                                }
                                            </VizSensor>
                                        </>
                                    ))
                                ) : (
                                    <EmptyTile
                                        header={strings.receptionPage.noBookmarkedExhibitors}
                                        buttonNavLink={showfloorPageRoute}
                                        buttonMessage={strings.receptionPage.showFloorNavLinkText}
                                        hideButton={true}
                                        bgColor="#F2F2F2"
                                    />
                                )}
                            </TileRow>
                        )
                    case "PRODUCTS":
                        return (
                            /* Bookmarked products */
                            <EntityCarousel
                                type="product"
                                entities={productsBookmarks}
                                emptyTileMessage={strings.receptionPage.noBookmarkedProducts}
                                linkText={
                                    productsBookmarks.length > 0
                                        ? strings.receptionPage.productsBookmarkedNavLinkText
                                        : strings.receptionPage.productsNavLinkText
                                }
                                link={productsBookmarks.length > 0 ? bookmarkedProductsPageRoute : productsPageRoute}
                            />
                        )
                    case "TRADEMARKS":
                        return (
                            /* Bookmarked trademarks */
                            <EntityCarousel
                                type="trademark"
                                entities={trademarksBookmarks}
                                emptyTileMessage={strings.receptionPage.noBookmarkedTrademarks}
                                linkText={
                                    trademarksBookmarks.length > 0
                                        ? strings.receptionPage.trademarksBookmarkedNavLinkText
                                        : strings.receptionPage.trademarksNavLinkText
                                }
                                link={trademarksBookmarks.length > 0 ? bookmarkedTrademarksPageRoute : trademarksPageRoute}
                            />
                        )
                    case "NEWS":
                        return (
                            /* Bookmarked news */
                            <EntityCarousel
                                type="news"
                                entities={newsBookmarks}
                                emptyTileMessage={strings.receptionPage.noBookmarkedNews}
                                linkText={
                                    newsBookmarks.length > 0
                                        ? strings.receptionPage.newsBookmarkedNavLinkText
                                        : strings.receptionPage.newsNavLinkText
                                }
                                link={newsBookmarks.length > 0 ? bookmarkedNewsPageRoute : newsPageRoute}
                            />
                        )
                    case "JOBOFFERS":
                        return (
                            /* Bookmarked joboffers */
                            <EntityCarousel
                                type="joboffer"
                                entities={joboffersBookmarks}
                                emptyTileMessage={strings.receptionPage.noBookmarkedJoboffers}
                                linkText={
                                    joboffersBookmarks.length > 0
                                        ? strings.receptionPage.joboffersBookmarkedNavLinkText
                                        : strings.receptionPage.joboffersNavLinkText
                                }
                                link={joboffersBookmarks.length > 0 ? bookmarkedJoboffersPageRoute : joboffersPageRoute}
                            />
                        )
                    default:
                        return null
                }
            })}
        </Fragment>
    )
}

export default MyPageContent
