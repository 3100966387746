import { useRef, useEffect } from "react"
import videojs, { VideoJsPlayer } from "video.js"
import styled from "styled-components"
import { keyframes } from "styled-components"
import CustomLogoImage from "./CustomLogoImage"
import { useAppState } from "../../globalStates/AppState"
import "./Video-js.css"
import { useLanguageState } from "../../globalStates/LanguageState"
import { getActiveLanguage } from "../../globalStates/LanguageState"
import CustomOverlayForLiveStreams from "./CustomOverlayForRemoteLivestreams"
import { RemoteLivestream } from "../../backendServices/Types"

/* livestream-capable video-player which uses the open source library video.js
video.js implements HLS-Streaming
the UI is styled by changing the default CSS-skin that video.js provides 
the live-prop switches between live-stream-player (true) or video-on-demand-player (false)
*/

/* ################### live stream player logic #######################################*/

export const usePlayer = (src: string, controls: boolean, live: boolean, remoteStreamLayout?: boolean) => {
    const strings = useLanguageState().getStrings()
    const appState = useAppState()
    const options = {
        autoplay: false,
        controls: controls,
        preload: "auto",
        liveui: true,
        fluid: true,
        userActions: {
            hotkeys: true
        },
        html5: {
            vhs: {
                withCredentials: true,
                enableLowInitialPlaylist: true,
                smoothQualityChange: true,
                overrideNative: true
            },
            nativeAudioTracks: false,
            nativeVideoTracks: false
        },
        sources: [
            {
                src: src,
                type: "application/x-mpegURL"
            }
        ]
    }

    const videoRef = useRef(null)

    // instantiate and dispose of player only once
    // similar to componentDidMount / componentDidUpdate / componentWillUnmount
    // do something after render
    useEffect(() => {
        const vjsPlayer = videojs(videoRef?.current!, { ...options }, function (this: VideoJsPlayer) {
            /*  on player ready callback */

            // read volume level, mute-state and play/pause-state from appState, so it stays the same when user switches
            // between Video- and PiP-Player

            /* the following lines deal with the feature, that video.js-Player allows a state where volume is 0 but mute 
            is set to false and vice versa, this is probably due to the fact that muting can be toggled via two UI-Elements:
            volume-slider and mute-toggle-button,
            this is probably on purpose so the user can mute player via mute button and then switch back to the previous
            volume, when mute is turned off again */
            const tmpAppstate = appState.videoPlayerStatus
            if (tmpAppstate) {
                if (tmpAppstate.volume === 0) {
                    this.volume(0)
                    this.muted(true)
                    this.trigger("volumechange") // update UI-Element Volume-Bar
                } else if (tmpAppstate.isMuted === true) {
                    this.muted(true)
                    this.trigger("volumechange") // update UI-Element Volume-Bar
                } else {
                    if (tmpAppstate.volume) {
                        this.volume(tmpAppstate.volume)
                    }
                    this.muted(false)
                    this.trigger("volumechange") // update UI-Element Volume-Bar
                }

                if (tmpAppstate.isPaused !== undefined) {
                    if (tmpAppstate.isPaused) {
                        vjsPlayer.pause()
                    } else {
                        vjsPlayer.play()
                    }
                }
            }

            // pick audio language channel if several are available
            const audioTrackList = vjsPlayer.tech({ IWillNotUseThisInPlugins: true })?.audioTracks() // { IWillNotUseThisInPlugins: true } suppresses warning about tech usage
            if (audioTrackList) {
                audioTrackList.addEventListener("addtrack", function (e: Event) {
                    // don't do anything, if only one audio track was added
                    if (audioTrackList.length > 1) {
                        // if audio track was previously set in appState, choose this audio track
                        const audioTrackFromAppState = appState.videoPlayerStatus?.audioTrack
                        if (audioTrackFromAppState) {
                            for (let i = 0; i < audioTrackList.length; i++) {
                                let track = audioTrackList[i]
                                if (track !== undefined) {
                                    const trackAny = track as any
                                    if (trackAny.label === audioTrackFromAppState) {
                                        // setting enabled to true does not seem to trigger a change event, so we trigger it ourselves
                                        // for more information see trigger function in events.js, where element does not seem to have dispatcher:
                                        // https://github.com/videojs/video.js/blob/4238f5c1d88890547153e7e1de7bd0d1d8e0b236/src/js/utils/events.js#L440
                                        trackAny.enabled = true
                                        audioTrackList.trigger("change")
                                    } else {
                                        trackAny.enabled = false
                                    }
                                }
                            }
                        } else {
                            // take application language if it corresponds with one of the available channels
                            const languageFromAppState = getActiveLanguage()
                            // refers to the NAME parameter of the EXT-X-MEDIA tag, i.e. the audio channel language in the m3u8-manifest
                            let possibleLanguageStrings: string[] = []

                            if (languageFromAppState === "en") {
                                possibleLanguageStrings = ["english", "englisch", "en", "eng", "engl"]
                            } else if (languageFromAppState === "de") {
                                possibleLanguageStrings = ["deutsch", "de", "deu", "german", "ger"]
                            }
                            for (let i = 0; i < audioTrackList.length; i++) {
                                let track = audioTrackList[i]
                                if (track !== undefined) {
                                    const trackAny = track as any
                                    let shouldActivate = false
                                    possibleLanguageStrings.forEach((langString) => {
                                        if (trackAny.label.toLowerCase() === langString) {
                                            shouldActivate = true
                                        }
                                    })
                                    if (shouldActivate) {
                                        trackAny.enabled = true
                                        audioTrackList.trigger("change")
                                    } else {
                                        trackAny.enabled = false
                                    }
                                }
                            }
                        }
                    }
                })

                audioTrackList.addEventListener("change", function (e: Event) {
                    // prevent activating two audio tracks at the same time or none at all,
                    // i.e. only one audio track is active at all times
                    if (audioTrackList.length > 1) {
                        let count = 0
                        for (let i = 0; i < audioTrackList.length; i++) {
                            let track = audioTrackList[i]
                            if (track !== undefined) {
                                const trackAny = track as any
                                if (trackAny.enabled) {
                                    count++
                                }
                            }
                        }
                        if (count !== 1) {
                            e.stopImmediatePropagation()
                        }
                    }
                })
            }
        }) // end player ready callback

        // load error is displayed using appstrings from branding.js
        videojs.addLanguage("de", {
            "The media could not be loaded, either because the server or network failed or because the format is not supported.":
                strings.videoPlayerBranding.videoPlayerStreamErrorMessage
        })

        videojs.addLanguage("en", {
            "The media could not be loaded, either because the server or network failed or because the format is not supported.":
                strings.videoPlayerBranding.videoPlayerStreamErrorMessage
        })

        if (src !== null) vjsPlayer.src({ src })

        // remove unnecessary items from the controlbar
        const controlBar = vjsPlayer.getChild("ControlBar")

        if (controlBar) {
            const pip = controlBar.getChild("PictureInPictureToggle")
            if (pip) {
                controlBar.removeChild(pip)
            }
            const remainingTimeDisplay = controlBar.getChild("RemainingTimeDisplay")
            if (remainingTimeDisplay) {
                controlBar.removeChild(remainingTimeDisplay)
            }
            const playbackRateMenuButton = controlBar.getChild("PlaybackRateMenuButton")
            if (playbackRateMenuButton) {
                controlBar.removeChild(playbackRateMenuButton)
            }

            const chaptersButton = controlBar.getChild("ChaptersButton")
            if (chaptersButton) {
                controlBar.removeChild(chaptersButton)
            }

            const descriptionsButton = controlBar.getChild("DescriptionsButton")
            if (descriptionsButton) {
                controlBar.removeChild(descriptionsButton)
            }

            const subtitlesButton = controlBar.getChild("SubsCapsButton")
            if (subtitlesButton) {
                controlBar.removeChild(subtitlesButton)
            }

            const seekToLive = controlBar.getChild("SeekToLive")
            if (seekToLive && !live) {
                controlBar.removeChild(seekToLive)
            }
        }

        // attach logo to player, so that pause-play-animations work
        if (!remoteStreamLayout) {
            const domControlBar = document.querySelectorAll('div[data-vjs-player="true"]')[0]
            const logo = document.querySelectorAll(`.vjs-logo`)[0]
            logo?.parentNode?.removeChild(logo)
            domControlBar?.appendChild(logo)
        }

        // returned function is called as cleanup
        return () => {
            const audioTrackList = vjsPlayer.tech({ IWillNotUseThisInPlugins: true })?.audioTracks()
            let audioTrack: string | undefined = undefined
            if (audioTrackList) {
                for (let i = 0; i < audioTrackList.length; i++) {
                    let track = audioTrackList[i]
                    if (track !== undefined) {
                        const trackAny = track as any
                        if (trackAny.enabled) {
                            audioTrack = trackAny.label
                            break
                        }
                    }
                }
            }
            // write volume level, mute-state, play/pause-state and audio track (language) into appState,
            // so it stays the same when user switches between Video- and PiP-Player
            appState.setVideoPlayerStatus({
                volume: vjsPlayer.volume(),
                isMuted: vjsPlayer.muted(),
                isPaused: vjsPlayer.paused(),
                audioTrack: audioTrack
            })

            vjsPlayer.dispose()
        }
    }, []) //eslint-disable-line

    // cleanup
    return videoRef
} // end usePlayer

const VideoPlayerReal = (props: {
    src: string
    title?: string
    controls: boolean
    live: boolean
    disablePlaying?: boolean
    remoteStreamLayout?: boolean
    remoteLivestream?: RemoteLivestream
    rerenderOnUnlockedChanged?: boolean
    // pass userAccessState.isUnlocked() here to update component when access is changed in backoffice
    // we don't actually do anything with this property here
}) => {
    const playerRef = usePlayer(props.src, props.controls, props.live, props.remoteStreamLayout)

    return (
        <VideoJSPlayerRoot disablePlaying={props.disablePlaying} remoteStreamLayout={props.remoteStreamLayout}>
            <div data-vjs-player>
                <video
                    ref={playerRef}
                    className="video-js"
                    data-isPip="false"
                    data-isStream={props.live}
                    title={props.remoteLivestream?.title ?? props.title}
                />
            </div>
            {props.remoteStreamLayout ? (
                <CustomOverlayForLiveStreams remoteLivestream={props.remoteLivestream!} />
            ) : (
                <CustomLogoImage />
            )}
        </VideoJSPlayerRoot>
    )
}

/* ################### UI styling #####################################################*/

// keyframes for fading animations (pause and play)

// pause animation
const screenFadePause = keyframes`
    from {
        opacity: 1;
    }

    to {
        opacity: 0.25;
    }
`

const logoFadePause = keyframes`
    from {
        opacity: 0.25;
        transform: translate(-50%, -50%) scale(2);
    }

    to {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }
`

// play animation
const screenFadePlay = keyframes`
    from {
        opacity: 0.25;
    }

    to {
        opacity: 1;
    }
`

const logoFadePlay = keyframes`
    from {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }

    to {
        opacity: 0.25;
        transform: translate(-50%, -50%) scale(2);
    }
`

interface VideoJSPlayerRootProps {
    disablePlaying?: boolean
    remoteStreamLayout?: boolean
}

export const VideoJSPlayerRoot = styled.div<VideoJSPlayerRootProps>`
    background-color: ${(props) => (props.remoteStreamLayout ? "transparent" : "#000")};
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    ${(props) => (props.disablePlaying ? "pointer-events: none" : "")};
    ${(props) => (props.remoteStreamLayout ? "cursor: pointer" : "")};

    #virtualGuide & .video-js {
        background-color: ${(props) => (props.remoteStreamLayout ? "transparent" : "#000")};
    }

    /* video player  */
    #virtualGuide & div[data-vjs-player="true"] {
    }

    /* video element */
    #virtualGuide & .vjs-tech {
        ${(props) => (props.remoteStreamLayout ? "border-radius: 5px;" : "")};
    }

    /* paused: fade out to darkened screen and fade in logo when paused */
    #virtualGuide & .vjs-paused .vjs-tech {
        animation: ${screenFadePause} 0.2s linear;
        opacity: 0.25;
    }

    #virtualGuide & .vjs-logo {
        opacity: 0;
        cursor: default;
        pointer-events: none;
        /* center logo */
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        /* logo overlays video */
        z-index: 800;
    }

    #virtualGuide & .vjs-paused.vjs-has-started .vjs-logo {
        opacity: 1;
        animation: ${logoFadePause} 0.2s linear;
    }

    /* playing: reverse on-pause-animation when playback resumes*/
    #virtualGuide & .video-js.vjs-playing .vjs-tech {
        animation: ${screenFadePlay} 0.2s linear;
    }

    #virtualGuide & .video-js.vjs-playing.vjs-has-started .vjs-logo {
        opacity: 1;
        animation: ${logoFadePlay} 0.2s linear;
        opacity: 0;
    }

    #virtualGuide & .video-js:hover .vjs-big-play-button {
        background-color: transparent;
    }

    /* control bar */
    #virtualGuide & .vjs-control-bar {
        height: 80px;
        width: auto;
        padding-top: 20px;
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100 #virtualGuide &1+0,0+100 */
        background: -moz-linear-gradient(bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(
            to top,
            rgba(0, 0, 0, 1) 0%,
            rgba(0, 0, 0, 0) 100%
        ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
    }

    #virtualGuide & .video-js.vjs-playing .vjs-control-bar,
    #virtualGuide & .video-js.vjs-scrubbing .vjs-control-bar,
    #virtualGuide & .video-js.vjs-seeking .vjs-control-bar,
    #virtualGuide & .video-js.vjs-waiting .vjs-control-bar {
        display: ${(props) => (props.remoteStreamLayout ? "flex" : "")};
    }

    #virtualGuide
        &
        :not(.video-js.vjs-playing):not(.video-js.vjs-scrubbing):not(.video-js.vjs-seeking):not(.video-js.vjs-waiting)
        .vjs-control-bar {
        display: ${(props) => (props.remoteStreamLayout ? "none" : "flex")};
    }

    #virtualGuide & .video-js.vjs-playing + .remote-livestream-overlay,
    #virtualGuide & .video-js.vjs-scrubbing + .remote-livestream-overlay,
    #virtualGuide & .video-js.vjs-seeking + .remote-livestream-overlay,
    #virtualGuide & .video-js.vjs-waiting + .remote-livestream-overlay {
        display: ${(props) => (props.remoteStreamLayout ? "none" : "flex")};
    }

    #virtualGuide
        &
        :not(.video-js.vjs-playing):not(.video-js.vjs-scrubbing):not(.video-js.vjs-seeking):not(.video-js.vjs-waiting)
        + .remote-livestream-overlay {
        display: ${(props) => (props.remoteStreamLayout ? "flex" : "none")};
    }

    /* control buttons - icons */
    #virtualGuide & .vjs-big-play-button {
        display: ${(props) => (props.remoteStreamLayout ? "none" : "")};
        background: url(/videoPlayerIcons/play.svg) no-repeat;
        margin: center !important;
        height: 18% !important;
        width: 10% !important;
        border-style: none !important;
        /* center big play button */
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    #virtualGuide & .vjs-button[title="Play"],
    #virtualGuide & .vjs-button[title="Play Video"] {
        background: url(/videoPlayerIcons/play.svg) no-repeat;
        margin-left: 32px !important;
        width: 23px !important;
    }

    .vjs-play-control.vjs-control.vjs-button.vjs-playing {
        background: url(/videoPlayerIcons/pause.svg) no-repeat;
        margin-left: 29px !important;
    }

    #virtualGuide & .vjs-button[title="Picture-in-Picture"] {
        background: url(/videoPlayerIcons/minimize.svg) no-repeat;
        margin-left: 16px !important;
    }

    #virtualGuide & .vjs-button[title="Exit Picture-in-Picture"] {
        background: url(/videoPlayerIcons/maximize.svg) no-repeat;
        margin-left: 16px !important;
    }

    #virtualGuide & .vjs-button[title="Fullscreen"] {
        background: url(/videoPlayerIcons/fullscreen.svg) no-repeat;
        margin-right: 29px !important;
        margin-top: 1px !important;
        height: 38px !important;
        width: 38px !important;
    }

    #virtualGuide & .vjs-button[title="Non-Fullscreen"] {
        background: url(/videoPlayerIcons/exit-fullscreen.svg) no-repeat;
        margin-right: 29px !important;
        margin-top: 1px !important;
        height: 38px !important;
        width: 38px !important;
    }

    #virtualGuide & .vjs-button[title="Mute"] {
        background: url(/videoPlayerIcons/volume-3.svg) no-repeat;
    }

    #virtualGuide & .vjs-mute-control[title="Unmute"] {
        background: url(/videoPlayerIcons/volume-mute.svg) no-repeat;
    }

    #virtualGuide & .vjs-button[title="Replay"] {
        background: url(/videoPlayerIcons/replay.svg) no-repeat;
        margin-left: 32px !important;
    }

    #virtualGuide & .vjs-button[title="Captions"] {
        background: url(/videoPlayerIcons/subtitles.svg) no-repeat;
    }

    #virtualGuide & .vjs-theatermode-control {
        background: url(/videoPlayerIcons/theatre-mode.svg) no-repeat;
        height: 38px !important;
        width: 38px !important;
        margin-top: 1px !important;
    }

    /* animate volume icon for different levels */
    #virtualGuide & .vjs-icon-volume-low {
        background: url(/videoPlayerIcons/volume-low.svg) no-repeat;
    }

    #virtualGuide & .vjs-button.vjs-vol-1[title="Mute"] {
        background: url(/videoPlayerIcons/volume-1.svg) no-repeat;
    }
    #virtualGuide & .vjs-button.vjs-vol-2[title="Mute"] {
        background: url(/videoPlayerIcons/volume-2.svg) no-repeat;
    }

    /* headphone button when different audio tracks available */
    #virtualGuide & .vjs-button[title="Audio Track"] {
        background: url(/videoPlayerIcons/music-note.svg) no-repeat;
    }

    /* remove yellow border from buttons when hovered over or clicked */
    #virtualGuide & .vjs-button:active,
    #virtualGuide & .vjs-button:hover,
    #virtualGuide & .vjs-button:focus,
    #virtualGuide & .vjs-button:visited,
    #virtualGuide & .vjs-big-play-button:active,
    #virtualGuide & .vjs-big-play-button:hover,
    #virtualGuide & .vjs-big-play-button:focus,
    #virtualGuide & .vjs-big-play-button:visited,
    #virtualGuide & .vjs-control-bar:focus {
        border-style: none !important;
        outline-style: none !important;
        background-color: transparent !important;
    }

    /* styling that all buttons in control bar have in common*/
    #virtualGuide & .vjs-big-play-button,
    #virtualGuide & .vjs-button[title="Play"],
    #virtualGuide & .vjs-button[title="Play Video"],
    #virtualGuide & .vjs-button[title="Pause"],
    #virtualGuide & .vjs-button[title="Picture-in-Picture"],
    #virtualGuide & .vjs-button[title="Exit Picture-in-Picture"],
    #virtualGuide & .vjs-button[title="Fullscreen"],
    #virtualGuide & .vjs-button[title="Mute"],
    #virtualGuide & .vjs-mute-control[title="Unmute"],
    #virtualGuide & .vjs-button[title="Non-Fullscreen"],
    #virtualGuide & .vjs-button[title="Audio Track"],
    #virtualGuide & .vjs-button.vjs-vol-1[title="Mute"],
    #virtualGuide & .vjs-button.vjs-vol-2[title="Mute"],
    #virtualGuide & .vjs-button[title="Audio Track"],
    #virtualGuide & .vjs-button[title="Replay"],
    #virtualGuide & .vjs-button[title="Captions"],
    #virtualGuide & .vjs-theatermode-control {
        text-indent: -9999px;
        width: 26px;
        height: 26px;
        background-size: contain;
        filter: invert(100%);
        margin-top: 6.5px;
        margin-left: 10px;
        margin-bottom: 10px;
        margin-right: 10px;
        border-radius: 1px;
        padding: 0 0 0 0;
    }

    /* live button */
    #virtualGuide & .vjs-seek-to-live-control {
        border: transparent !important;
        height: 31px;
        display: block;
        margin-top: 5px;
    }

    #virtualGuide & .vjs-seek-to-live-control:focus {
        text-shadow: none;
        border-style: none !important;
        outline-style: none !important;
    }

    /* live button hover Effekte ?*/
    /*  #virtualGuide & .vjs-seek-to-live-control:focus,
     #virtualGuide & .vjs-seek-to-live-control:hover,
     #virtualGuide & .vjs-seek-to-live-control:active,
     #virtualGuide & .vjs-seek-to-live-control:visited
    {
        background: blue;
    } */

    /* live text */
    #virtualGuide & .vjs-seek-to-live-text {
        user-select: none;
        font-size: 16px;
        overflow: hidden;
        margin-top: 10px;
    }

    #virtualGuide & .vjs-seek-to-live-text:focus {
        outline: none;
    }

    /* red dot */
    #virtualGuide & .vjs-icon-placeholder {
        font-size: 16px;
        overflow: hidden;
        vertical-align: -2px;
    }

    /* progress bar */
    #virtualGuide & .vjs-progress-control {
        bottom: 10px;
    }

    /* white slide-bar */
    #virtualGuide & .vjs-play-progress.vjs-slider-bar {
    }

    #virtualGuide & .vjs-progress-holder {
    }

    /* white dot */
    #virtualGuide & .vjs-load-progress {
    }

    /* little line that attaches to cursor as you hover the progress bar */
    #virtualGuide & .vjs-mouse-display {
    }

    /* white progress bar and little circle at current position*/
    #virtualGuide & .vjs-play-progress {
    }

    /* volume slider */
    #virtualGuide & .vjs-volume-horizontal {
        margin-top: 5px;
    }
`

export default VideoPlayerReal
