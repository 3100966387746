import styled from "styled-components"
import { DetailNavLink } from "../contentArea/detailPages/DetailNavLink"
import * as React from "react"
import AvatarWithPresenceState from "./AvatarWithPresenceState"
import { Divider } from "../communicationArea/ContactEntry"

const ParticipantCellWrapper = styled.div`
    display: flex;
    flex-direction: column;
`
const ParticipantCell = styled(DetailNavLink)`
    display: flex;
    align-items: center;
    padding: 8px 0 8px 16px;
    cursor: pointer;

    &:hover {
        background-color: hsl(0, 0%, 94%);
    }
`
const ParticipantTitle = styled.div`
    font-weight: 300;
`
const ParticipantSubtitle = styled.div`
    font-size: 12px;
`

export interface Participants {
    id: string
    name: string
    pictureUrl?: string
    subtitle?: string
}

interface ParticipantListProps {
    participants: Participants[]
}

const ParticipantList: React.FC<ParticipantListProps> = (props) => {
    return (
        <>
            {props.participants.map((participant) => (
                <ParticipantCellWrapper key={participant.id}>
                    <ParticipantCell type="user" id={participant.id} name={participant.name}>
                        <AvatarWithPresenceState
                            userId={participant.id}
                            avatarSize={56}
                            badgeSize={15}
                            showAvatarBadge={true}
                            badgeRight={0.1}
                            badgeTop={37}
                            content={{ pictureUrl: participant.pictureUrl, alt: participant.name }}
                        />
                        <div style={{ marginLeft: "16px" }}>
                            <ParticipantTitle>{participant.name}</ParticipantTitle>
                            <ParticipantSubtitle>{participant.subtitle}</ParticipantSubtitle>
                        </div>
                    </ParticipantCell>
                    <Divider style={{ paddingLeft: "16px", margin: "0px" }} />
                </ParticipantCellWrapper>
            ))}
        </>
    )
}

export default ParticipantList
