import { useState, useEffect } from "react"
import * as React from "react"
import styled from "styled-components"
import { useLanguageState } from "../globalStates/LanguageState"
import AvatarWithPresenceState from "../ui/AvatarWithPresenceState"
import { useAppState } from "../globalStates/AppState"
import { useMeetingContext } from "../conference/context/MeetingContext"
import {
    BackendServiceError,
    listAttendeeInfos,
    AttendeeData,
    loadUserData,
    SpeakerResponse
} from "../backendServices/BackendServices"
import CalendarEntryModal, { CalendarEntryModalViewMode } from "../ui/CalendarEntryModal"
import {
    IconCamera,
    IconMuteChat,
    IconCalendarEntry,
    IconChevronLeft,
    IconThreeDotsMenu,
    IconSettings,
    IconUnMuteChat
} from "../ui/Icons"
import { ConversationParticipant } from "./ChatPage"
import { ConversationType } from "../API"
import { useChimeContext, getExternalMeetingId } from "../conference/context/ChimeContext"
import { ModalType } from "../backendServices/Types"
import { useLoggedInState } from "../globalStates/LoggedInUser"
import { useFavoriteState } from "../globalStates/Favorites"
import { useContactState } from "./ContactState"
import { DetailNavLink } from "../contentArea/detailPages/DetailNavLink"
import { isExplorationOrPostEventPhase, isPostEventPhase } from "../utils/EventPhaseChecker"
import branding from "../branding/branding"
import ContextMenu, { MenuItemProps } from "../ui/ContextMenu"
import { getIamPartOf } from "../globalStates/IAmPartOf"
import queryString from "query-string"
import { createActions, CommunicationModals } from "./CommunicationOptions"

const ChatHeaderContainer = styled.div`
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    padding-bottom: 1px;
    border-bottom: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"};
    transition: height 0.2s ease-out;
    color: ${branding.mainInfoColor ?? "black"};

    &.privateChat {
        height: 280px;
    }
    &.groupChat {
        height: 240px;
    }
    &.groupChatWithDescription {
        height: 300px;
    }
    &.collapsed {
        height: 62px;
        transition: height 0.2s ease-out;
    }
`
const ChatHeaderTop = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px;

    &.collapsed {
        align-items: center;
    }
`
const ChatHeaderBottom = styled.div`
    width: 100%;
    flex-grow: 1;
    position: relative;
    overflow: hidden;
    color: ${branding.primaryColor};
`

const ChatHeaderIconButtonsContainer = styled.div`
    display: table;
    table-layout: fixed;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    &.collapsed {
        display: none;
    }
    & :nth-child(1) {
        animation-delay: 100ms;
    }
    & :nth-child(2) {
        animation-delay: 200ms;
    }
`

const ConversationTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    flex-shrink: 1;
    overflow: hidden;
    cursor: pointer;
`
// TODO 2 line ellipsis
const ConversationTitle = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 700;
    text-align: center;
    font-size: 14px;
    color: ${branding.mainInfoColor ?? "black"};
    width: 100%;
`
const ConversationSubtitle = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
    text-align: center;
    font-size: 12px;
    color: ${branding.mainInfoColor ?? "black"};
    width: 100%;
`

const ConversationDescription = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    text-align: center;
    font-size: 12px;
    color: ${branding.mainInfoColor ?? "black"};
    width: 100%;
    padding: 10px 10px 0 10px;

    &.collapsed {
        display: none;
    }
`

const HoverButton = styled.div`
    background-color: hsl(0, 0%, 100%);
    padding: 10px 10px;
    transition: background-color 0.7s;
    border-radius: 25px;
    fill: white;
    cursor: pointer;
    &:hover {
        background-color: hsl(0, 0%, 80%);
    }

    &.invisible {
        visibility: hidden;
    }

    & svg {
        color: ${branding.primaryColor};
    }

    &.selected svg {
        fill: ${branding.primaryColor};
    }
`

interface ConversationCreationHeaderProps {
    title: string
    close: () => void
}

export const ConversationCreationHeader: React.FC<ConversationCreationHeaderProps> = (props) => {
    const onNavigateUp = () => {
        props.close()
    }

    const collapsedClassName = "collapsed"
    return (
        <ChatHeaderContainer className={collapsedClassName}>
            <ChatHeaderTop className={collapsedClassName}>
                <HoverButton onClick={onNavigateUp}>
                    {IconChevronLeft({ fill: branding.sideIconBar.sideIconColorDark })}
                </HoverButton>
                <ConversationTitleContainer style={{ cursor: "default" }}>
                    <ConversationTitle>{props.title}</ConversationTitle>
                </ConversationTitleContainer>
                <HoverButton className={"invisible"}>
                    {IconThreeDotsMenu({ fill: branding.sideIconBar.sideIconColorDark })}
                </HoverButton>
            </ChatHeaderTop>
        </ChatHeaderContainer>
    )
}

interface PrivateChatHeaderProps {
    opponent: ConversationParticipant
    isMuted: boolean
    setIsMuted: (isMuted: boolean) => void
    userConversationId: string
}

export const PrivateChatHeader: React.FC<PrivateChatHeaderProps> = (props) => {
    const appState = useAppState()
    const languageState = useLanguageState()
    const userState = useLoggedInState()
    const loggedInUserId = userState.user()?.profileId
    const favorites = useFavoriteState()
    const contactState = useContactState()
    const chime = useChimeContext()

    const strings = languageState.getStrings()
    const meeting = useMeetingContext()
    const [showTools, setShowTools] = useState<boolean>(false)
    const isBookmarked = favorites.is("person", props.opponent.id)
    const [connectionStatus, setConnectionStatus] = useState("")

    const [userTypeStatus, setUserTypeStatus] = useState("")

    const [modalType, setModalType] = useState<ModalType>("none")

    useEffect(() => {
        setConnectionStatus(contactState.getConnectionStatus(props.opponent.id))
        setUserTypeStatus(contactState.getUserType(props.opponent.id))
        // eslint-disable-next-line
    }, [contactState])

    useEffect(() => {
        loadUser(props.opponent.id)
        // eslint-disable-next-line
    }, [props.opponent])

    async function loadUser(userId: string) {
        if (!loggedInUserId) {
            return
        }

        try {
            const response = await loadUserData({ targetProfileId: userId, loggedInUserId: loggedInUserId })
            if ((response as BackendServiceError).httpStatus) {
                return
            }

            const userDataResponse = response as SpeakerResponse
            const person = userDataResponse.content

            contactState.setAll([
                {
                    id: person.id,
                    connectionStatus: person.myConnectionStatus,
                    userType: person.type
                }
            ])
        } catch (error: any) {
            return
        }
    }

    const menuCallback: (param: { bookmarkChanged?: boolean; modalType?: ModalType }) => void = ({
        bookmarkChanged,
        modalType
    }) => {
        if (modalType) {
            setModalType(modalType)
        }
    }

    const title = props.opponent.name
    const subtitle = [props.opponent.position, props.opponent.organization].join(" ")

    const collapsedClassName = showTools ? "expanded" : "collapsed"
    return (
        <ChatHeaderContainer className={collapsedClassName + " privateChat"}>
            <ChatHeaderTop className={collapsedClassName}>
                <HoverButton onClick={() => appState.setShowChatsTab(null)}>
                    {IconChevronLeft({ fill: branding.sideIconBar.sideIconColorDark })}
                </HoverButton>
                <ConversationTitleContainer>
                    {showTools && (
                        <div style={{ paddingLeft: "19px" }}>
                            <DetailNavLink type="user" id={props.opponent.id} name={props.opponent.name}>
                                <AvatarWithPresenceState
                                    userId={props.opponent.id}
                                    initPresenceByList={false}
                                    avatarSize={36}
                                    showAvatarBadge={true}
                                    badgeSize={12}
                                    badgeRight={18}
                                    badgeTop={22}
                                    content={{ pictureUrl: props.opponent.pictureUrl, alt: title }}
                                />
                            </DetailNavLink>
                        </div>
                    )}
                    {/* // TODO  Border for user type will be shown after adding props.opponent.type inside opponent*/}
                    <ConversationTitle onClick={() => setShowTools(!showTools)}>{title}</ConversationTitle>
                    {showTools && <ConversationSubtitle>{subtitle}</ConversationSubtitle>}
                </ConversationTitleContainer>
                <HoverButton onClick={() => setShowTools(!showTools)} className={showTools ? "selected" : ""}>
                    {IconThreeDotsMenu({ fill: branding.sideIconBar.sideIconColorDark })}
                </HoverButton>
            </ChatHeaderTop>
            <ChatHeaderBottom>
                <ChatHeaderIconButtonsContainer className={collapsedClassName}>
                    <ContextMenu
                        collapsed={!showTools}
                        items={() =>
                            createActions(
                                userState.user(),
                                props.opponent,
                                favorites,
                                contactState,
                                appState,
                                meeting,
                                chime,
                                strings,
                                isBookmarked,
                                connectionStatus,
                                userTypeStatus,
                                menuCallback,
                                undefined,
                                undefined,
                                true,
                                true
                            )
                        }
                    />
                    <CommunicationModals
                        show={modalType}
                        contact={props.opponent}
                        removePosition={true}
                        onHide={() => setModalType("none")}
                    />
                </ChatHeaderIconButtonsContainer>
            </ChatHeaderBottom>
        </ChatHeaderContainer>
    )
}

interface GroupChatHeaderProps {
    conversationId: string
    conversationType: ConversationType
    conversationDescription?: string
    conversationName?: string
    opponents: ConversationParticipant[]
    isMuted: boolean
    toggleMuteGroup?: () => Promise<boolean>
    userConversationId?: string
    clickableTitle?: boolean
    showDetails: boolean
    setShowDetails: (showDetails: boolean) => void
}

export const GroupChatHeader: React.FC<GroupChatHeaderProps> = (props) => {
    const appState = useAppState()
    const languageState = useLanguageState()
    const strings = languageState.getStrings()
    const meeting = useMeetingContext()
    const chime = useChimeContext()
    const [showCreateCalendarEntry, setShowCreateCalendarEntry] = useState<boolean>(false)
    const [showTools, setShowTools] = useState(false)

    const defaultName =
        props.conversationType === ConversationType.CALL
            ? strings.chatBranding.callChatDefaultName
            : strings.chatBranding.groupChat
    const title = props.conversationName ?? defaultName + " (" + (props.opponents.length + 1) + ")"
    const subtitle = props.opponents
        .map((op) => op.name)
        .concat(strings.chatBranding.youText)
        .join(", ")
    const queryParams: any = queryString.parse(window.location.search)
    const onMeetingButtonClicked = () => {
        setShowCreateCalendarEntry(true)
    }
    const onVideoButtonClicked = async () => {
        if (props.conversationType === ConversationType.CALENDARENTRY) {
            chime.createOrJoinMeeting(props.conversationId, "calenderEntry")
        } else {
            const resp = await listAttendeeInfos(getExternalMeetingId(props.conversationId, "call"))
            if ((resp as BackendServiceError).httpStatus) {
                return
            }
            const attendees = resp as AttendeeData[]
            if (attendees.length <= 0) {
                for await (const opponent of props.opponents) {
                    await meeting.sendInvite(opponent.id, { meetingId: props.conversationId, meetingKind: "call" })
                }
            } else {
                chime.createOrJoinMeeting(props.conversationId, "call")
            }
        }
    }
    const onMuteButtonClicked = async () => {
        if (!props.toggleMuteGroup) {
            return
        }
        const success = await props.toggleMuteGroup()
        if (!success) {
            // TODO Error handling
        }
    }
    const onConversationTitleClicked = () => {
        if (!props.showDetails) {
            setShowTools(!showTools)
        }
    }
    const onConversationDetailButtonClicked = () => {
        props.setShowDetails(true)
        setShowTools(false)
    }
    const onNavigateUp = () => {
        if (props.showDetails) {
            props.setShowDetails(false)
        } else {
            appState.setShowChatsTab(null)
        }
    }

    const collapsedClassName = showTools ? "expanded" : "collapsed"
    const groupChatClassName = props.conversationDescription ? "groupChatWithDescription" : "groupChat"

    return (
        <ChatHeaderContainer className={collapsedClassName + " " + groupChatClassName}>
            <ChatHeaderTop className={collapsedClassName}>
                <HoverButton onClick={onNavigateUp}>
                    {IconChevronLeft({ fill: branding.sideIconBar.sideIconColorDark })}
                </HoverButton>
                <ConversationTitleContainer onClick={onConversationTitleClicked}>
                    <ConversationTitle>{title}</ConversationTitle>
                    <ConversationSubtitle>{subtitle}</ConversationSubtitle>
                    <ConversationSubtitle> </ConversationSubtitle>
                </ConversationTitleContainer>
                <HoverButton
                    onClick={() => setShowTools(!showTools)}
                    className={props.showDetails ? "invisible" : showTools ? "selected" : ""}
                >
                    {IconThreeDotsMenu({ fill: branding.sideIconBar.sideIconColorDark })}
                </HoverButton>
            </ChatHeaderTop>
            {props.conversationDescription && (
                <ConversationDescription className={collapsedClassName}>{props.conversationDescription}</ConversationDescription>
            )}
            <ChatHeaderBottom>
                <ChatHeaderIconButtonsContainer className={collapsedClassName}>
                    <ContextMenu
                        collapsed={!showTools}
                        itemsPerRow={2}
                        items={() => {
                            const menuItems: MenuItemProps[] = []

                            menuItems.push({
                                disabled: branding.communicationArea.disableMeetingIcon || isPostEventPhase,
                                title: strings.communicationArea.chatToolCalendarEntry,
                                icon: IconCalendarEntry({ fill: branding.sideIconBar.sideIconColorDark }),
                                onClick: () => {
                                    onMeetingButtonClicked()
                                }
                            })

                            menuItems.push({
                                disabled:
                                    (isExplorationOrPostEventPhase && !(getIamPartOf(queryParams) === "onboarding")) ||
                                    branding.communicationArea.disableVideoIcon,
                                title: strings.communicationArea.chatToolVideoChat,
                                icon: IconCamera({ fill: branding.sideIconBar.sideIconColorDark }),
                                onClick: () => {
                                    onVideoButtonClicked()
                                }
                            })

                            menuItems.push({
                                disabled: branding.communicationArea.disableMuteIcon,
                                title: props.isMuted
                                    ? strings.communicationArea.chatToolUnmute
                                    : strings.communicationArea.chatToolMute,
                                icon: props.isMuted
                                    ? IconUnMuteChat({ fill: branding.sideIconBar.sideIconColorDark })
                                    : IconMuteChat({ fill: branding.sideIconBar.sideIconColorDark }),
                                onClick: () => {
                                    onMuteButtonClicked()
                                }
                            })

                            menuItems.push({
                                disabled: branding.communicationArea.disableSettingsIcon,
                                title: strings.chatBranding.detailsText,
                                icon: IconSettings({ fill: branding.sideIconBar.sideIconColorDark }),
                                onClick: () => {
                                    onConversationDetailButtonClicked()
                                }
                            })

                            return menuItems
                        }}
                    />
                </ChatHeaderIconButtonsContainer>
            </ChatHeaderBottom>
            {showCreateCalendarEntry && (
                <CalendarEntryModal
                    viewMode={CalendarEntryModalViewMode.CREATE}
                    sotUser={props.opponents.map((op) => {
                        return { id: op.id, firstName: op.name, logoUrl: op.pictureUrl }
                    })}
                    close={() => {
                        setShowCreateCalendarEntry(false)
                    }}
                />
            )}
        </ChatHeaderContainer>
    )
}
