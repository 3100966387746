import { useEffect } from "react"
import * as React from "react"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import styled, { keyframes } from "styled-components"
import { AppLoadingStatus } from "../../App"
import { cognitoCurrentAuthUser, cognitoLogin, DataPrivacyDoc, getMyProfileData } from "../../backendServices/BackendServices"
import branding from "../../branding/branding"
import { useLanguageState } from "../../globalStates/LanguageState"
import { useLoggedInState, User } from "../../globalStates/LoggedInUser"
import LanguageToggler from "../../ui/LanguageToggle"
import useWindowDimensions from "../../ui/WindowDimensionsHook"
import { device, MobileVersionContainer, DesktopVersionContainer } from "../../utils/Device"
import { trackNavigation } from "../../utils/GTMTracking"
import { CenterLoginStyleTicketButton } from "./CenterLoginSharedComponents"
import LoginFooter from "./LoginFooter"
import LoginModuleComponent, { isSessionValid } from "./LoginModule"
import useQueryParamLogin from "./QueryParamLogin"
import { defaultLogger as logger } from "../../globalStates/AppState"

const LoginRegistrationSiteRoot = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;

    &.siteAnimation-enter {
        opacity: 0;
        transition: opacity 1.1s ease-in-out;
    }
    &.siteAnimation-enter-active {
        opacity: 1;
    }

    &.siteAnimation-exit {
        opacity: 1;
        transition: opacity 1.1s ease-in-out;
        transition-delay: 0.4s;
    }
    &.siteAnimation-exit-active {
        opacity: 0;
    }
    &.siteAnimation-exit-done {
        display: none;
    }
`

const loginStepTransitionName = "loginStepAnimation"
const cssAnimationDuration = "0.6s"
const animationDuration = 600

export const slideInFromEast = keyframes`
    from {
        opacity: 0;
        transform: translateX(1000px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
`

export const slideInFromWest = keyframes`
    from {
        opacity: 0;
        transform: translateX(-1000px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
`

const AnimatedLoginStepItem = styled.div`
    position: absolute;
    top: ${branding.loginRegistrationSite.loginFormTopMargin ?? "108px"};
    bottom: 120px;
    left: 0px;
    right: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    &.${loginStepTransitionName}-enter-active {
        animation-duration: ${cssAnimationDuration};
        animation-timing-function: ease-in-out;
        animation-delay: 0s;
        animation-iteration-count: 1;
        animation-direction: normal;
        animation-fill-mode: forwards;
        animation-play-state: running;
    }
    &.${loginStepTransitionName}-exit-active {
        animation-duration: ${cssAnimationDuration};
        animation-timing-function: ease-in-out;
        animation-delay: 0s;
        animation-iteration-count: 1;
        animation-direction: reverse;
        animation-fill-mode: forwards;
        animation-play-state: running;
    }
    &.dir-east.${loginStepTransitionName}-enter-active {
        animation-name: ${slideInFromWest};
    }
    &.dir-east.${loginStepTransitionName}-exit-active {
        animation-name: ${slideInFromWest};
    }
    &.dir-west.${loginStepTransitionName}-enter-active {
        animation-name: ${slideInFromEast};
    }
    &.dir-west.${loginStepTransitionName}-exit-active {
        animation-name: ${slideInFromEast};
    }

    @media ${device.mobile} {
        bottom: 0;
        top: 60px;
    }

    @media (min-width: 768px) and (max-height: 800px) {
        top: 50px;
    }
`

const DefaultTicketButton = () => {
    const strings = useLanguageState().getStrings()
    return (
        <DefaultTicketButtonPanel onClick={() => window.open(`${branding.ticketSale.ticketsUrl}`, "_blank")}>
            {strings.loginRegistrationSite.buyTicketButton}
        </DefaultTicketButtonPanel>
    )
}

const DefaultTicketButtonPanel = styled.div`
    margin-top: 6px;
    & {
        text-align: center;
    }
    & button {
        color: #1675e0;
        background-color: #ffffff00;
    }
    & button:hover {
        text-decoration: underline;
    }
    & button:focus {
        outline: none;
    }
`

// Center login style

export const CenterLoginStyleRoot = styled.div`
    width: 100%;
    height: 100vh;
    position: relative;
    background-color: #fff;
    //background-position: center;
    padding: 0;
    background-size: cover;
    background-repeat: no-repeat;
    font-size: 1.1rem;

    @media ${device.tablet} {
        background-image: url("/branding/login-bg.jpg");
    }
`

export const CenterLoginStyleOverlay = styled.div`
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
    background-color: ${branding.loginRegistrationSite.centerLoginStyleOverlay};

    @media ${device.mobile} {
        background-color: transparent;
    }
`

const CenterLoginStyleHeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 2rem;
    position: relative;
    z-index: 10;

    & img {
        max-width: ${branding.loginRegistrationSite.centerLoginLogoMaxWidth ?? "150px"};
        max-height: ${branding.loginRegistrationSite.centerLoginLogoMaxHeightMobile ?? "17.25px"};
        object-fit: contain;
        user-select: none;

        @media ${device.tablet} {
            max-height: ${branding.loginRegistrationSite.centerLoginLogoMaxHeight ?? "50px"};
        }

        @media ${device.mobile} {
            margin-top: 3px;
        }
    }

    @media ${device.mobile} {
        padding-bottom: 10px;
        padding-left: 1rem;
        padding-right: 1rem;
    }
`
export enum ProfileResponseStatus {
    NONE,
    SUCCESS,
    FAILURE_EMAIL_INVALID,
    FAILURE_PRIVACY_DOCS_OUTDATED,
    FAILURE,
    WRONG_PASSWORD,
    TOKEN_INVALIDATED,
    WRONG_TOKEN,
    THIRD_PARTY_USER_NOT_FOUND,
    USER_CREATION_NOT_ALLOWED,
    NO_TCKET,
    LOGIN_BLOCKED,
    MAXIMUM_LOGIN_ATTEMPTS,
    TICKET_CONFIGURATION_ISSUE,
    TICKET_NOT_VALID,
    TICKET_ALREADY_REDEEMED,
    TICKET_FAILURE
}

interface LoginRegistrationSiteProps {
    dataPrivacyDoc: DataPrivacyDoc
    loadingStatus: AppLoadingStatus
}

const LoginRegistrationSite: React.FC<LoginRegistrationSiteProps> = (props) => {
    const userLink = useLoggedInState()
    const strings = useLanguageState().getStrings()
    const isLoggingInViaQueryParam = useQueryParamLogin(props.dataPrivacyDoc)
    const loginAbortController = new AbortController()
    const key = userLink.user() === undefined ? "user" : ""
    const directionClass = userLink.user() === undefined ? "dir-east" : "dir-west"

    const { useMobileDesign } = useWindowDimensions()

    useEffect(() => {
        const timeout = setTimeout(() => {
            localStorage.removeItem("logoutInProcess")
        }, 2000)

        trackNavigation("Login")
        localStorage.removeItem("presenceBeforeRefresh")
        return () => {
            loginAbortController.abort()
            clearTimeout(timeout)
        }
        // eslint-disable-next-line
    }, [])

    return (
        <LoginRegistrationSiteRoot>
            {props.loadingStatus === AppLoadingStatus.SUCCESS && !isLoggingInViaQueryParam && (
                <CenterLoginStyleRoot>
                    <CenterLoginStyleOverlay>
                        <CenterLoginStyleHeaderContainer>
                            <img
                                src={
                                    branding.loginRegistrationSite.useCustomLogoForMobile && useMobileDesign
                                        ? "/branding/logo_mobile.png"
                                        : "/branding/logo.png"
                                }
                                alt={strings.configuration.ogTitle}
                            />
                            <DesktopVersionContainer>
                                <TicketDisplay ticketBtnType="centerLogin" />
                            </DesktopVersionContainer>
                            <MobileVersionContainer>
                                <LanguageToggler
                                    fullToggler
                                    margin="0"
                                    fontSize="16px"
                                    color={branding.loginRegistrationSite.mobileLoginTextColor}
                                />
                            </MobileVersionContainer>
                        </CenterLoginStyleHeaderContainer>

                        <TransitionGroup>
                            <CSSTransition
                                key={key}
                                classNames={loginStepTransitionName}
                                timeout={animationDuration}
                                onExit={() => {
                                    loginAbortController.abort()
                                }}
                            >
                                <AnimatedLoginStepItem className={directionClass}>
                                    <LoginModuleComponent dataPrivacyDoc={props.dataPrivacyDoc} />
                                </AnimatedLoginStepItem>
                            </CSSTransition>
                        </TransitionGroup>

                        <LoginFooter />
                    </CenterLoginStyleOverlay>
                </CenterLoginStyleRoot>
            )}
        </LoginRegistrationSiteRoot>
    )
}

interface TicketDisplayProps {
    ticketBtnType?: string
}

function TicketDisplay(props: TicketDisplayProps) {
    if (branding.ticketSale.getYourTicketVisible)
        return props.ticketBtnType === "centerLogin" ? <CenterLoginStyleTicketButton /> : <DefaultTicketButton />
    return null
}

/**
 * 1. Check if some user is currently logged in (via data in local storage)
 * 2. Check any special requirements per loginType if user is still logged in
 * 3. Check generall requirements for user to be logged in
 *
 * => If there is some problem, log out. Otherwise update the user data
 */

export async function updateOrInvalidateSession(
    loggedIn: boolean,
    invalidateSession: () => void,
    userInfo: { profileId: string; beConnectionToken: string; email: string },
    setUser: (user?: User) => void,
    setMatchActive: (matchActive: boolean) => void,
    setSessionAndTicketValid: (isValid: boolean) => void
) {
    if (!loggedIn) return

    if (!(await isSessionValid())) {
        invalidateSession()
        return
    }
    const res = await getMyProfileData()
    if ((res as any).httpStatus === 403) {
        invalidateSession()
        setSessionAndTicketValid(false)
        return
    } else if (res.content.profile.profileId) {
        setMatchActive(res.content.profile.matchActive)
        setUser(res.content.profile as User)
        const isTicketValid = (res.content.profile as User)?.type !== "none"
        setSessionAndTicketValid(!branding.configuration.ticketBarrierPageEnabled || isTicketValid)
    }

    let cognitoSessionValid
    try {
        await cognitoCurrentAuthUser()
        cognitoSessionValid = true
    } catch {
        // Cognito session invalid. New cognito login should be invoked
        cognitoSessionValid = false
    }

    if (!cognitoSessionValid) {
        // We call cognito login just to refresh token -> explained more in GUIDE-4135
        try {
            await cognitoLogin(userInfo.profileId, userInfo.beConnectionToken, userInfo.email)
        } catch (e: any) {
            logger.error(e.message)
        }
    }
}

export default LoginRegistrationSite
