import { useEffect, useState } from "react"
import * as React from "react"
import styled from "styled-components"
import { isUserEdited } from "../../App"
import { getInterest } from "../../backendServices/BackendServices"
import { Category, Contact, ModalType } from "../../backendServices/Types"
import branding from "../../branding/branding"
import { CommunicationModals, createActions } from "../../communicationArea/CommunicationOptions"
import { useContactState } from "../../communicationArea/ContactState"
import { useChimeContext } from "../../conference/context/ChimeContext"
import { useMeetingContext } from "../../conference/context/MeetingContext"
import { useAppState } from "../../globalStates/AppState"
import { useFavoriteState } from "../../globalStates/Favorites"
import { useLanguageState } from "../../globalStates/LanguageState"
import { useLoggedInState } from "../../globalStates/LoggedInUser"
import { AvatarWithDefault } from "../../ui/AvatarWithDefault"
import CompanyDetails from "../../ui/CompanyDetails"
import ContextMenu from "../../ui/ContextMenu"
import {
    IconArrowDownLangSwitcher,
    IconArrowUpLangSwitcher,
    IconEmail,
    IconFacebook,
    IconFacebookOriginal,
    IconInstagram,
    IconInstagramOriginal,
    IconLanguages,
    IconLinkedIn,
    IconLinkedInOriginal,
    IconLocation,
    IconPhone,
    IconPinterest,
    IconPinterestOrginal,
    IconThreeDotsMenu,
    IconTwitter,
    IconTwitterOriginal,
    IconXing,
    IconXingOriginal,
    IconYouTube,
    IconYouTubeOriginal
} from "../../ui/Icons"
import { CategoriesItem } from "../../ui/ProfilePageStyledComponents"
import { useRecommendModal } from "../../ui/RecommendModal"
import { findCountryName } from "../myprofile/CountrySwitcherHelper"
import { getListOfLanguagesString } from "../myprofile/LanguageSwitcherHelper"
import MobileMyProfilePageContent, {
    BiographyContainer,
    ContactSectionButtonsContainer,
    ContactSectionIconContainer,
    ContactSectionTextContainer,
    InterestsTitleRow,
    InterestsViewRow,
    Paragraph,
    ProfileRoot,
    ProfileSectionRoot,
    ProfileSectionsContainer,
    ProfileSocialItem,
    ProfileSocialsWrapper,
    SectionTopButton,
    SocialMediaContainer,
    StyledButton,
    Title,
    UserImageDiv
} from "../myprofile/MobileMyProfilePageContent"
import { CategoryType, SocialMediaType } from "../myprofile/MyProfilePageContentBranding"
import { ModalRoot } from "./components/DetailPageSections"
import { ContactCategoryBindingsProps, EventDates } from "./PersonDetailPageContent"

const MobilePersonDetailPageContent: React.FunctionComponent<SectionProps> = (props: SectionProps) => {
    const userState = useLoggedInState()
    const loggedInUserId = userState.user()?.profileId
    const contactState = useContactState()
    const { RecommendModal, showRecommendModal } = useRecommendModal()

    const connectionStatus = props.contact ? contactState.getConnectionStatus(props.contact.id) : "UNRELATED"

    const [isMyProfile, setIsMyProfile] = useState<boolean>(false)

    useEffect(() => {
        if (props.contact.id === loggedInUserId || props.contact.sotUserId === loggedInUserId) {
            setIsMyProfile(true)
        } else {
            setIsMyProfile(false)
        }
    }, [props.contact]) // eslint-disable-line react-hooks/exhaustive-deps

    return isMyProfile ? (
        <MobileMyProfilePageContent />
    ) : (
        <ProfileRoot>
            <ProfileSectionsContainer>
                <ContactSection contact={props.contact} connectionStatus={connectionStatus} />
                <SocialMediaSection contact={props.contact} />

                {/* CalendarEntryModal component is not mobile responsive, so does not make sense to show this section on mobile screens currently */}
                {/* {(isOnboardingPhase || isLivePhase) && branding.personDetailPageContent.meetingSlotsSection.visible && (
                    <MeetingSlots user={props.contact} />
                )} */}

                {branding.myProfilePageContent.categoriesOrder.includes(CategoryType.INTERESTS) && (
                    <InterestsSection contact={props.contact} />
                )}

                {(branding.myProfilePageContent.categoriesOrder.includes(CategoryType.OFFERS) ||
                    branding.myProfilePageContent.categoriesOrder.includes(CategoryType.NEEDS)) && (
                    <OffersNeedsSection contact={props.contact} />
                )}

                <CompanySection contact={props.contact} />

                <EventDates type="UPCOMING" eventDates={props.contact.eventDates} showRecommendModal={showRecommendModal} />
                <EventDates type="PAST" eventDates={props.contact.eventDates} showRecommendModal={showRecommendModal} />

                <RecommendModal />
            </ProfileSectionsContainer>
        </ProfileRoot>
    )
}

export default MobilePersonDetailPageContent

interface SectionProps {
    contact: Contact
    connectionStatus?: string
}

const ContactSection: React.FunctionComponent<SectionProps> = (props: SectionProps) => {
    const lang = useLanguageState().getLanguage()
    const strings = useLanguageState().getStrings()

    const userState = useLoggedInState()

    const [showMenu, setShowMenu] = useState<boolean>(false)

    const currentUserInfo: string =
        props.contact.infotext && props.connectionStatus !== "CONNECTED"
            ? props.contact.infotext
            : props.contact.description?.text
            ? props.contact.description.text
            : ""

    const currentUserName: string = branding.myProfilePageContent.salutationVisible
        ? [props.contact.salutation, props.contact.firstName, props.contact.lastName].filter(Boolean).join(" ")
        : [props.contact.firstName, props.contact.lastName].filter(Boolean).join(" ")
    const localizedPosition = props.contact.position
    const currentUserPosition: string = [localizedPosition, props.contact.company]
        .filter(Boolean)
        .join(" " + strings.communicationArea.personCompanyLink + " ")
    const currentUserIndustry: string = props.contact.industry || ""

    return (
        <ProfileSectionRoot>
            {isUserEdited(userState) && (
                <SectionTopButton
                    onClick={() => {
                        setShowMenu(true)
                    }}
                >
                    {IconThreeDotsMenu({ fill: branding.sideIconBar.sideIconColorDark })}
                </SectionTopButton>
            )}
            <UserImageDiv>
                <AvatarWithDefault
                    size={80}
                    src={props.contact.logoUrl}
                    alt={[props.contact.firstName, props.contact.lastName].join(" ") ?? "#"}
                    backgroundSize="cover"
                />
            </UserImageDiv>
            <Title>{currentUserName}</Title>
            <Paragraph>{[currentUserPosition, currentUserIndustry].filter(Boolean).join(" | ")}</Paragraph>

            {props.contact.countrycode && (
                <Paragraph>
                    <ContactSectionIconContainer>
                        {IconLocation({ fill: branding.sideIconBar.sideIconColorDark })}
                    </ContactSectionIconContainer>

                    <ContactSectionTextContainer>{findCountryName(props.contact.countrycode, lang)}</ContactSectionTextContainer>
                </Paragraph>
            )}

            {props.contact.languages && props.contact.languages?.length! > 0 && (
                <Paragraph>
                    <ContactSectionIconContainer>
                        {IconLanguages({ fill: branding.sideIconBar.sideIconColorDark })}
                    </ContactSectionIconContainer>

                    <ContactSectionTextContainer>
                        {getListOfLanguagesString(props.contact.languages, lang)}
                    </ContactSectionTextContainer>
                </Paragraph>
            )}

            {currentUserInfo !== "" && (
                <BiographyContainer>
                    <Title>{strings.myProfilePageContent.biographyMobileLabel}</Title>

                    <Paragraph>{currentUserInfo}</Paragraph>
                </BiographyContainer>
            )}

            <ContactSectionButtonsContainer>
                {props.contact.phone && branding.personDetailPageContent.showPhoneNumber && (
                    <StyledButton>
                        <a href={"tel:" + props.contact.phone} style={{ color: branding.sideIconBar.sideIconColorDark }}>
                            <Paragraph style={{ marginTop: "auto", marginBottom: "auto" }}>
                                <ContactSectionIconContainer>
                                    {IconPhone({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "20" })}
                                </ContactSectionIconContainer>

                                <ContactSectionTextContainer>
                                    {strings.myProfilePageContent.phoneLabel}
                                </ContactSectionTextContainer>
                            </Paragraph>
                        </a>
                    </StyledButton>
                )}

                {props.contact.email && (
                    <StyledButton>
                        <a href={"mailto:" + props.contact.email} style={{ color: branding.sideIconBar.sideIconColorDark }}>
                            <Paragraph style={{ marginTop: "auto", marginBottom: "auto" }}>
                                <ContactSectionIconContainer>
                                    {IconEmail({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "20" })}
                                </ContactSectionIconContainer>

                                <ContactSectionTextContainer>
                                    {strings.myProfilePageContent.emailLabel}
                                </ContactSectionTextContainer>
                            </Paragraph>
                        </a>
                    </StyledButton>
                )}
            </ContactSectionButtonsContainer>

            {showMenu && <PersonMenuModal contact={props.contact} handleClose={() => setShowMenu(false)} />}
        </ProfileSectionRoot>
    )
}

const SocialMediaSection: React.FunctionComponent<SectionProps> = (props: SectionProps) => {
    const strings = useLanguageState().getStrings()

    function checkIfNoSocials(): boolean {
        const brandingList = branding.myProfilePageContent.availableSocialMedias

        if (props.contact.facebook && props.contact.facebook.length > 0 && brandingList.includes(SocialMediaType.FACEBOOK)) {
            return true
        }

        if (props.contact.twitter && props.contact.twitter.length > 0 && brandingList.includes(SocialMediaType.TWITTER)) {
            return true
        }

        if (props.contact.instagram && props.contact.instagram.length > 0 && brandingList.includes(SocialMediaType.INSTAGRAM)) {
            return true
        }

        if (props.contact.linkedIn && props.contact.linkedIn.length > 0 && brandingList.includes(SocialMediaType.LINKEDIN)) {
            return true
        }

        if (props.contact.youTube && props.contact.youTube.length > 0 && brandingList.includes(SocialMediaType.YOUTUBE)) {
            return true
        }

        if (props.contact.xing && props.contact.xing.length > 0 && brandingList.includes(SocialMediaType.XING)) {
            return true
        }

        if (props.contact.pinterest && props.contact.pinterest.length > 0 && brandingList.includes(SocialMediaType.PINTEREST)) {
            return true
        }

        return false
    }

    if (!checkIfNoSocials()) {
        return null
    }

    return (
        <ProfileSectionRoot>
            <SocialMediaContainer>
                <Title>{strings.myProfilePageContent.socialMediaSectionTitle}</Title>

                <ProfileSocialsWrapper>
                    {branding.myProfilePageContent.availableSocialMedias.map((item, index) => {
                        if (item === SocialMediaType.FACEBOOK) {
                            return (
                                <>
                                    {props.contact.facebook && (
                                        <ProfileSocialItem key={index} href={props.contact.facebook} target="_blank">
                                            {branding.socialMediaIcons.useOriginalSocialMediaIcons
                                                ? IconFacebookOriginal({ width: "20", height: "20" })
                                                : IconFacebook({
                                                      fill: branding.sideIconBar.sideIconColorDark,
                                                      width: "20",
                                                      height: "20"
                                                  })}
                                        </ProfileSocialItem>
                                    )}
                                </>
                            )
                        }

                        if (item === SocialMediaType.TWITTER) {
                            return (
                                <>
                                    {props.contact.twitter && (
                                        <ProfileSocialItem key={index} href={props.contact.twitter} target="_blank">
                                            {branding.socialMediaIcons.useOriginalSocialMediaIcons
                                                ? IconTwitterOriginal({ width: "20", height: "20" })
                                                : IconTwitter({
                                                      fill: branding.sideIconBar.sideIconColorDark,
                                                      width: "20",
                                                      height: "20"
                                                  })}
                                        </ProfileSocialItem>
                                    )}
                                </>
                            )
                        }

                        if (item === SocialMediaType.LINKEDIN) {
                            return (
                                <>
                                    {props.contact.linkedIn && (
                                        <ProfileSocialItem key={index} href={props.contact.linkedIn} target="_blank">
                                            {branding.socialMediaIcons.useOriginalSocialMediaIcons
                                                ? IconLinkedInOriginal({ width: "20", height: "20" })
                                                : IconLinkedIn({
                                                      fill: branding.sideIconBar.sideIconColorDark,
                                                      width: "20",
                                                      height: "20"
                                                  })}
                                        </ProfileSocialItem>
                                    )}
                                </>
                            )
                        }

                        if (item === SocialMediaType.XING) {
                            return (
                                <>
                                    {props.contact.xing && (
                                        <ProfileSocialItem key={index} href={props.contact.xing}>
                                            {branding.socialMediaIcons.useOriginalSocialMediaIcons
                                                ? IconXingOriginal({ width: "20", height: "20" })
                                                : IconXing({
                                                      fill: branding.sideIconBar.sideIconColorDark,
                                                      width: "20",
                                                      height: "20"
                                                  })}
                                        </ProfileSocialItem>
                                    )}
                                </>
                            )
                        }

                        if (item === SocialMediaType.INSTAGRAM) {
                            return (
                                <>
                                    {props.contact.instagram && (
                                        <ProfileSocialItem key={index} href={props.contact.instagram}>
                                            {branding.socialMediaIcons.useOriginalSocialMediaIcons
                                                ? IconInstagramOriginal({ width: "20", height: "20" })
                                                : IconInstagram({
                                                      fill: branding.sideIconBar.sideIconColorDark,
                                                      width: "20",
                                                      height: "20"
                                                  })}
                                        </ProfileSocialItem>
                                    )}
                                </>
                            )
                        }

                        if (item === SocialMediaType.YOUTUBE) {
                            return (
                                <>
                                    {props.contact.youTube && (
                                        <ProfileSocialItem key={index} href={props.contact.youTube}>
                                            {branding.socialMediaIcons.useOriginalSocialMediaIcons
                                                ? IconYouTubeOriginal({ width: "20", height: "20" })
                                                : IconYouTube({
                                                      fill: branding.sideIconBar.sideIconColorDark,
                                                      width: "20",
                                                      height: "20"
                                                  })}
                                        </ProfileSocialItem>
                                    )}
                                </>
                            )
                        }

                        if (item === SocialMediaType.PINTEREST) {
                            return (
                                <>
                                    {props.contact.pinterest && (
                                        <ProfileSocialItem key={index} href={props.contact.pinterest}>
                                            {branding.socialMediaIcons.useOriginalSocialMediaIcons
                                                ? IconPinterestOrginal({ width: "20", height: "20" })
                                                : IconPinterest({
                                                      fill: branding.sideIconBar.sideIconColorDark,
                                                      width: "20",
                                                      height: "20"
                                                  })}
                                        </ProfileSocialItem>
                                    )}
                                </>
                            )
                        }

                        return null
                    })}
                </ProfileSocialsWrapper>
            </SocialMediaContainer>
        </ProfileSectionRoot>
    )
}

const CompanySection: React.FunctionComponent<SectionProps> = (props: SectionProps) => {
    return (
        <>
            {props.contact.organizations && props.contact.organizations[0] && (
                <ProfileSectionRoot>
                    <SocialMediaContainer>
                        <CompanyDetails organization={props.contact.organizations[0]} />
                    </SocialMediaContainer>
                </ProfileSectionRoot>
            )}
        </>
    )
}

function getCategorySectionTitle(strings: any, type: CategoryType) {
    switch (type) {
        case CategoryType.INTERESTS:
            return strings.interestsTitle

        case CategoryType.OFFERS:
            return strings.offersTitle

        case CategoryType.NEEDS:
            return strings.needsTitle

        default:
            return strings.interestsTitle
    }
}

const InterestsSection: React.FunctionComponent<SectionProps> = (props: SectionProps) => {
    const [isEmpty, setIsEmpty] = useState<boolean>(false)

    if (isEmpty) {
        return null
    }

    return (
        <ProfileSectionRoot>
            <SocialMediaContainer>
                <ContactCategoryBindings type={CategoryType.INTERESTS} person={props.contact} setIsEmpty={setIsEmpty} />
            </SocialMediaContainer>
        </ProfileSectionRoot>
    )
}

const OffersNeedsSection: React.FunctionComponent<SectionProps> = (props: SectionProps) => {
    const [isOffersEmpty, setIsOffersEmpty] = useState<boolean>(false)
    const [isNeedsEmpty, setIsNeedsEmpty] = useState<boolean>(false)

    if (
        (!branding.myProfilePageContent.categoriesOrder.includes(CategoryType.OFFERS) &&
            !branding.myProfilePageContent.categoriesOrder.includes(CategoryType.NEEDS)) ||
        (isOffersEmpty && isNeedsEmpty)
    ) {
        return null
    }

    return (
        <ProfileSectionRoot>
            <SocialMediaContainer>
                <>
                    {branding.myProfilePageContent.categoriesOrder.map((item, index) => {
                        switch (item) {
                            case "OFFERS":
                                return (
                                    <ContactCategoryBindings
                                        key={index}
                                        type={CategoryType.OFFERS}
                                        person={props.contact}
                                        setIsEmpty={setIsOffersEmpty}
                                    />
                                )

                            case "NEEDS":
                                return (
                                    <ContactCategoryBindings
                                        key={index}
                                        type={CategoryType.NEEDS}
                                        person={props.contact}
                                        setIsEmpty={setIsNeedsEmpty}
                                    />
                                )

                            default:
                                return null
                        }
                    })}
                </>
            </SocialMediaContainer>
        </ProfileSectionRoot>
    )
}

const ViewMoreButtonContainer = styled.div`
    float: right;
    margin-top: -5px;
    margin-right: 10px;
`

const ViewMoreButtonTextContainer = styled.div`
    font-size: 12px;
    display: inline-block;
`

const ViewMoreButtonIconContainer = styled.div`
    display: inline-block;
    margin-left: 5px;
`

const ContactCategoryBindings: React.FunctionComponent<ContactCategoryBindingsProps> = React.memo((props) => {
    const langState = useLanguageState()
    const lang = langState.getLanguage()
    const strings = langState.getStrings().personDetailPageContent

    const userState = useLoggedInState()

    const categoriesLimitNumber = branding.personDetailPageContent.categoriesLimitNumber
    const [numberOfCategories, setNumberOfCategories] = useState<number>(0)

    const [isExpanded, setIsExpanded] = useState<boolean>(false)

    const [categories, setCategories] = useState<{ common: Category[]; unique: Category[] }>({ common: [], unique: [] })
    let content: JSX.Element = <div />

    useEffect(() => {
        loadCategories()
        // eslint-disable-next-line
    }, [props.person, lang])

    function loadCategories() {
        getInterest().then((res) => {
            if (res.content.interests || res.content.lookingforandoffering) {
                let selectedCategoryIds: String[] = []
                let allCategories: Category[] = []
                let myCategoriesTemp: any[] = []

                switch (props.type) {
                    case CategoryType.INTERESTS:
                        selectedCategoryIds = props.person.interests ?? []
                        allCategories = res.content.interests
                        myCategoriesTemp = userState.user()?.interests!
                        break

                    case CategoryType.OFFERS:
                        selectedCategoryIds = props.person.offering ?? []
                        allCategories = res.content.lookingforandoffering
                        myCategoriesTemp = userState.user()?.offering!
                        break

                    case CategoryType.NEEDS:
                        selectedCategoryIds = props.person.lookingfor ?? []
                        allCategories = res.content.lookingforandoffering
                        myCategoriesTemp = userState.user()?.lookingfor!
                        break
                }

                setNumberOfCategories(selectedCategoryIds.length)

                if (numberOfCategories > categoriesLimitNumber) {
                    setIsExpanded(true)
                }

                const commonCategories: Category[] = []
                const uniqueCategories: Category[] = []
                allCategories.forEach((i: Category) => {
                    if (myCategoriesTemp?.includes(i.id) && selectedCategoryIds?.includes(i.id)) commonCategories.push(i)
                    else if (myCategoriesTemp?.includes(i.id) === false && selectedCategoryIds?.includes(i.id))
                        uniqueCategories.push(i)
                })
                setCategories({ common: commonCategories, unique: uniqueCategories })

                if (props.setIsEmpty) {
                    if (commonCategories.length === 0 && uniqueCategories.length === 0) {
                        props.setIsEmpty(true)
                    }
                }
            }
        })
    }

    if (categories.unique.length || categories.common.length) {
        content = (
            <>
                <InterestsTitleRow>
                    <Title>{getCategorySectionTitle(strings, props.type)} </Title>
                    {numberOfCategories > categoriesLimitNumber && (
                        <ViewMoreButtonContainer onClick={() => setIsExpanded(!isExpanded)}>
                            <ViewMoreButtonTextContainer>{strings.viewMoreCategoriesButtonText}</ViewMoreButtonTextContainer>
                            <ViewMoreButtonIconContainer>
                                {isExpanded
                                    ? IconArrowUpLangSwitcher({ fill: branding.sideIconBar.sideIconColorDark })
                                    : IconArrowDownLangSwitcher({ fill: branding.sideIconBar.sideIconColorDark })}
                            </ViewMoreButtonIconContainer>
                        </ViewMoreButtonContainer>
                    )}
                </InterestsTitleRow>
                <InterestsViewRow>
                    {(isExpanded ? categories.common : categories.common.slice(0, categoriesLimitNumber)).map((category) => {
                        return (
                            <div key={category.id}>
                                <CategoriesItem common>{category.name}</CategoriesItem>
                            </div>
                        )
                    })}
                    {(isExpanded
                        ? categories.unique
                        : categories.unique.slice(0, categoriesLimitNumber - categories.common?.length!)
                    )
                        .slice(0, categoriesLimitNumber - categories.common.length!)
                        .map((category) => {
                            return (
                                <div key={category.id}>
                                    <CategoriesItem>{category.name}</CategoriesItem>
                                </div>
                            )
                        })}
                </InterestsViewRow>
            </>
        )
    } else {
        content = <></>
    }

    return content
})

const PersonMenuModal: React.FunctionComponent<{ contact: Contact; handleClose: () => void }> = (props) => {
    const person = props.contact
    const [modalType, setModalType] = useState<ModalType>("none")
    const strings = useLanguageState().getStrings()
    const userLink = useLoggedInState()
    const favoriteState = useFavoriteState()
    const appState = useAppState()
    const meeting = useMeetingContext()
    const chime = useChimeContext()
    const contactState = useContactState()

    const [connectionStatus, setConnectionStatus] = useState("")

    const [userTypeStatus, setUserTypeStatus] = useState("")

    const [isBookmarked, setIsBookmarked] = useState<boolean>(false)

    useEffect(() => {
        setUserTypeStatus(contactState.getUserType(person.id))

        setIsBookmarked(
            favoriteState.is("person", person.person ?? person.id) ||
                favoriteState.is("person", person.id) ||
                favoriteState.is("sotuser", person.id) ||
                favoriteState.is("sotuser", person.person ?? person.id)
        )

        setConnectionStatus(contactState.getConnectionStatus(person.id))
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        setConnectionStatus(contactState.getConnectionStatus(person.id))
        setUserTypeStatus(contactState.getUserType(person.id))
        setIsBookmarked(
            favoriteState.is("person", person.person ?? person.id) ||
                favoriteState.is("person", person.id) ||
                favoriteState.is("sotuser", person.id) ||
                favoriteState.is("sotuser", person.person ?? person.id)
        )
        // eslint-disable-next-line
    }, [person, contactState, favoriteState])

    const menuCallback: (param: { bookmarkChanged?: boolean; modalType?: ModalType }) => void = ({
        bookmarkChanged,
        modalType
    }) => {
        if (modalType) {
            setModalType(modalType)
        }
    }

    return (
        <>
            <ModalRoot show={true} animation={false} onHide={() => props.handleClose()} marginTop="120px">
                <ContextMenu
                    collapsed={false}
                    mobile={true}
                    items={() =>
                        createActions(
                            userLink.user(),
                            person,
                            favoriteState,
                            contactState,
                            appState,
                            meeting,
                            chime,
                            strings,
                            isBookmarked,
                            connectionStatus,
                            userTypeStatus,
                            menuCallback
                        )
                    }
                />
            </ModalRoot>
            <CommunicationModals show={modalType} contact={person} removePosition={true} onHide={() => setModalType("none")} />
        </>
    )
}
