import { useEffect, useState, HTMLAttributes } from "react"
import * as React from "react"
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useChimeContext, LocalVideoStatus } from '../context/ChimeContext';
import LocalVideo from './LocalVideo';
import { Button } from './Button';
import branding from '../../branding/branding';
import { DetailNavLink } from '../../contentArea/detailPages/DetailNavLink';
import { useLanguageState } from '../../globalStates/LanguageState';
import { IconMicrophoneOff, IconVolumeOff, IconShareScreen, IconShareScreenStop, IconRaiseHand, IconLowerHand, IconCamera, IconCameraOff, IconVolumeHigh, IconMicrophone, IconEndCall, IconSettings, IconChat } from '../../ui/Icons';
import { CommunicationCenterDisplayMode, useAppState } from '../../globalStates/AppState';
import { ChatConversationParam } from '../../communicationArea/ChatPage';
import { useLoggedInState } from '../../globalStates/LoggedInUser';
import { ConversationType } from '../../API';
import RemainingTime from './RemainingTime';
import { accessPresenceState, EventType } from '../../ui/PresenceIndicator';


const ControlsRoot = styled.div`
  display: flex;
  flex-wrap: nowrap;
  height: 80px;
  width: 100%;
  z-index: 10;
  margin: 0 auto;
`

const HostedByColumn = styled.div`
  width: 180px;
  padding: 20px 30px;

  & :first-child {
    font-family: ${branding.font1};
    font-size: 0.9rem;
  }
  & :nth-child(2) {
    font-family: ${branding.font2};
    font-size: 1.3rem;
  }
`

const ButtonsColumn = styled.div`
  flex-grow: 1;
  display: flex;
  flex-wrap: nowrap;
  margin: 0 auto;
  align-items: center;
  justify-content: center;

  & button {
    position: relative;
    margin: 0;
    border-radius: 0;
    width: 60px;
    height: 40px;

    @media (max-width: 1500px){
      width: 50px;
      height: 33px;
    }

    &:before{
      content: '';
      display: block;
      position: absolute;
      right: 0;
      height: 20px;
    }

    &:first-child {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    &:last-child {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;

      &:before{
        display: none;
      }
    }

    &:disabled {
      background-color: ${branding.communicationAreaDefaultAvatarColor};
    }
  }
`

const LocalVideoColumn = styled.div<{isRosterOpen?: boolean | null}>`
  justify-self: right;
  width: 145px;
  text-align: right;
  position: absolute;
  right: 0;


  ${({ isRosterOpen }) => !isRosterOpen && `
     @media(max-width: 1200px) {
      bottom: 65px;
  } 
  `}

  ${({ isRosterOpen }) => isRosterOpen && `
      @media(max-width: 1400px) {
        bottom: 65px;
    }
    `}

`


interface Props extends HTMLAttributes<HTMLDivElement> {
  hideLocalVideo?: boolean
  hideHostedBy?: boolean
  smallButtons?: boolean
  redirectToRoom?: boolean
  isDragging?: boolean
  isChatGroup?: boolean
}

export default function Controls(props: Props) {
  const chime = useChimeContext()
  const history = useHistory()
  const muted = chime.isMuted()
  const videoStatus = chime.getLocalVideoStatus()
  const appState = useAppState()
  const [hoster, setHoster] = useState<{ organizationId: string | undefined, organizationName: string | undefined } | null>(null)
  const strings = useLanguageState().getStrings()
  const profileId = useLoggedInState().user()?.profileId

  useEffect(() => {
    const meetingName = chime.getExternalMeetingId()

    let found = false
    for (const meetingRoomGroup of strings.meetingRoomGroups) {
      for (const meetingRoom of meetingRoomGroup.meetingRooms) {
        if (meetingRoom.id === meetingName) {
          setHoster(meetingRoomGroup)
          found = true
          break
        }
      }
      if (found)
        break
    }

    if (chime.getKind() === "breakout" && !chime.isMod()) {
      chime.realtimeMuteLocalAudio()
    }
  },
    // eslint-disable-next-line
    [])

  if (profileId === undefined) {
    return (<div>Logout in Progress</div>);
  } else {
    return (
      <ControlsRoot className={props.className}>
        {!props.hideHostedBy && hoster && hoster.organizationId && hoster.organizationName && <HostedByColumn>
          <div>hosted by</div><DetailNavLink id={hoster.organizationId} name={hoster.organizationName} type="organization" source="VC">{hoster.organizationName}</DetailNavLink>
        </HostedByColumn>}
        <RemainingTime />
        <ButtonsColumn>
          <Button
            tooltip={muted ? strings.conferenceTexts.unmute : strings.conferenceTexts.mute}
            icon={muted ? IconMicrophoneOff({ fill: branding.sideIconBar.sideIconColorDark }) : IconMicrophone({ fill: branding.sideIconBar.sideIconColorDark })}
            onClick={async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              if (props.isDragging) {
                return
              }

              e.stopPropagation()
              if (muted) {
                chime.realtimeUnmuteLocalAudio()
              } else {
                chime.realtimeMuteLocalAudio()
              }
              // Adds a slight delay to close the tooltip before rendering the updated text in it
              await new Promise(resolve => setTimeout(resolve, 10))
            }}
          />
          <Button
            tooltip={chime.getVolume() === 100 ? strings.conferenceTexts.soundOff : strings.conferenceTexts.soundOn}
            icon={chime.getVolume() === 100 ? IconVolumeHigh({ fill: branding.sideIconBar.sideIconColorDark }) : IconVolumeOff({ fill: branding.sideIconBar.sideIconColorDark })}
            onClick={async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              if (props.isDragging) {
                return
              }

              e.stopPropagation()
              chime.setVolume(chime.getVolume() === 0 ? 100 : 0)
            }}
          />

          <Button
            tooltip={videoStatus === LocalVideoStatus.Disabled ? strings.conferenceTexts.videoOn : strings.conferenceTexts.videoOff}
            icon={videoStatus === LocalVideoStatus.Enabled ? IconCamera({ fill: branding.sideIconBar.sideIconColorDark }) : IconCameraOff({ fill: branding.sideIconBar.sideIconColorDark })}
            disabled={!chime.hasWebcam()}
            onClick={async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              if (props.isDragging) {
                return
              }

              e.stopPropagation()
              chime.toggleLocalVideoTile()
            }}
          />
          <Button
            tooltip={chime.isLocalScreenSharingStarted() ? strings.conferenceTexts.shareScreenStop : strings.conferenceTexts.shareScreenStart}
            icon={chime.isLocalScreenSharingStarted() ? IconShareScreenStop({ fill: branding.sideIconBar.sideIconColorDark }) : IconShareScreen({ fill: branding.sideIconBar.sideIconColorDark })}
            disabled={(!chime.isLocalScreenSharingStarted() && chime.isScreenShareEnabled()) || ((chime.getKind() === "breakout" || chime.getKind() === "showroom") && !chime.isMod())}
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              if (props.isDragging) {
                return
              }

              e.stopPropagation()
              if (chime.isLocalScreenSharingStarted()) {
                chime.stopContentShare();
              } else {
                chime.startContentShareFromScreenCapture()
              }
            }}
          />
          <Button
            tooltip={chime.isHandRaised() ? strings.conferenceTexts.lowerHand : strings.conferenceTexts.raiseHand}
            icon={chime.isHandRaised() ? IconLowerHand({ fill: branding.sideIconBar.sideIconColorDark }) : IconRaiseHand({ fill: branding.sideIconBar.sideIconColorDark })}
            onClick={async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              if (props.isDragging) {
                return
              }

              e.stopPropagation()
              appState.setIsMyHandRaised(!chime.isHandRaised())
              chime.raiseHand(chime.getLocalAttendeeId()!, !chime.isHandRaised())
            }}
          />
          <Button
            tooltip={strings.conferenceTexts.openChat}
            icon={IconChat({ width: "20px", height: "20px", fill: branding.sideIconBar.sideIconColorDark })}
            onClick={async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              if (props.isDragging) {
                return
              }

              e.stopPropagation()

              const meetingKind = chime.getKind()
              const meetingId = chime.getExternalMeetingId()
              const conversationId = meetingId?.substr(3)
              switch (meetingKind) {
                case "call":
                  const roster = chime.getRoster();
                  const rosterKeys = Object.keys(roster)
                  const attendeeIds = rosterKeys.map(key => roster[key].id)
                  const opponentIds = attendeeIds.filter(id => id !== profileId)
                  if (!props.isChatGroup && opponentIds.length === 1 && opponentIds[0]) {
                    appState.setShowChatsTab(ChatConversationParam.privateConversationByOpponentId(opponentIds[0]))
                  } else if (conversationId) {
                    appState.setShowChatsTab(ChatConversationParam.conversationByConversationId(ConversationType.GROUP, conversationId))
                  }
                  break
                case "calenderEntry":
                  if (conversationId) {
                    appState.setShowChatsTab(ChatConversationParam.conversationByConversationId(ConversationType.CALENDARENTRY, conversationId))
                  }
                  break
                case "virtualCafe":
                case "showroom":
                case "breakout":
                case "conferenceroom":
                case "greenroom":
                case "roundtable":
                    appState.setIsMeetingChatShown(true)
                    appState.setIsRosterOpen(true)
                  break
                default:
                  // TODO behavior not yet defined
                  break
              }
            }}
          />
          <Button
            tooltip={strings.conferenceTexts.settings}
            icon={IconSettings({ fill: branding.sideIconBar.sideIconColorDark })}
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              if (props.isDragging) {
                return
              }

              e.stopPropagation()
              appState.setCommunicationCenterDisplayMode(CommunicationCenterDisplayMode.SETTINGS)
            }}
          />
          <Button
            tooltip={strings.conferenceTexts.leave}
            icon={IconEndCall({ fill: "#fff" })}
            color="#fff"
            backgroundColor="red"
            onClick={async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              if (props.isDragging) {
                return
              }

              e.stopPropagation()

              if (chime.isLocalScreenSharingStarted()) {
                chime.stopContentShare();
              }
              // change presence only if user is not on masterclasses watching live stream
              if (history.location.pathname.indexOf("/masterclasses/") < 0) {
                accessPresenceState.updateMyPresence(EventType.EVENT_END)
              }
              appState.setIsMyHandRaised(false)
              if (chime.getKind() === "call")
                window.sessionStorage.removeItem("currentTabCall")
              await chime.leaveRoom()
              if (props.redirectToRoom) {
                if (window.localStorage.getItem("routeBeforeCall")) {
                  history.push(window.localStorage.getItem("routeBeforeCall") as string)
                } else {
                  history.push("/");
                }
              }
            }}
          />
        </ButtonsColumn>
        {!props.hideLocalVideo && <LocalVideoColumn isRosterOpen={appState.isRosterOpen}>
          <LocalVideo />
        </LocalVideoColumn>}
      </ControlsRoot >
    );
  }
}
