import { format } from "date-fns"
import moment from "moment"
import { useEffect, useState } from "react"
import * as React from "react"
import styled from "styled-components"
import { loadExhibitorData } from "../../backendServices/BackendServices"
import { AccessProvider, Category, Company, EventDate } from "../../backendServices/Types"
import branding from "../../branding/branding"
import { useAppState } from "../../globalStates/AppState"
import { useLanguageState } from "../../globalStates/LanguageState"
import { useLoggedInState, User } from "../../globalStates/LoggedInUser"
import { useUserRestrictedAreaAccess } from "../../globalStates/UserRestrictedAreaAccess"
import BookmarkWithToggle from "../../ui/BookmarkWithToggle"
import { CalendarEntryModalViewMode, useCalendarEntryModal } from "../../ui/CalendarEntryModal"
import ContactModal from "../../ui/ContactModal"
import CrsPersons from "../../ui/CrsPersons"
import {
    IconCalendarEntry,
    IconCameraTag,
    IconLock,
    IconRoundTable,
    IconTicket,
    IconTypeformTag,
    IconVideo
} from "../../ui/Icons"
import useWindowDimensions from "../../ui/WindowDimensionsHook"
import { hasAccessToOrganization } from "../../utils/Authorization"
import { activateChannelBefore, isEventDateLive, momentWithoutTimezoneFromTimezonedMoment } from "../../utils/DateUtils"
import { device } from "../../utils/Device"
import { DetailNavLink, DetailNavLinkType } from "../detailPages/DetailNavLink"
import { ScheduleHelper } from "./ScheduleHelper"
import de from "date-fns/locale/de"
import en from "date-fns/locale/en-GB"
import BadgeArea from "../../ui/BadgeArea"

function getSize(windowSize: number) {
    return windowSize < 1200
        ? 400
        : windowSize < 1400
        ? 600
        : windowSize < 1800
        ? 800
        : windowSize < 2000
        ? 400
        : windowSize < 2400
        ? 600
        : 800
}

const TagsWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-left: 10px;
`
const TagWrapper = styled.div`
    width: 40px;
    height: 26px;
    border: 1px solid ${branding.programSchedule.tagBorderColor};
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
`
const TagIcon = styled.div`
    width: 18px;
`
interface TileTagsWrapperProps {
    videoOnDemandUrl: string
    typeformUrl: string
}
const TileTagsWrapper = (props: TileTagsWrapperProps) => {
    return (
        <TagsWrapper>
            {props.videoOnDemandUrl && (
                <TagWrapper>
                    <TagIcon>{IconCameraTag({})}</TagIcon>
                </TagWrapper>
            )}
            {((branding.programSchedule.videoOnDemandNeededToDisplayTypeformTag && props.videoOnDemandUrl) ||
                !branding.programSchedule.videoOnDemandNeededToDisplayTypeformTag) &&
                props.typeformUrl && (
                    <TagWrapper>
                        <TagIcon>{IconTypeformTag({})}</TagIcon>
                    </TagWrapper>
                )}
        </TagsWrapper>
    )
}

const PrivateEventMarkerRoot = styled.div<{ bottomPadding?: string; parentHeight?: number; alwaysSticky?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;

    &.tile-layout {
        width: 113%;
        position: sticky;
        top: ${(props) => (props.alwaysSticky || (props.parentHeight && props.parentHeight - 4 <= 370) ? "0" : "100%")};
        bottom: ${(props) => props.bottomPadding ?? "0"};
        margin-left: -6.5%;
        margin-right: -6.5%;
        height: 50px;
        background-color: #c4c4c4;

        & .private-event-marker-text {
            & p {
                font-size: 16px;
            }
        }
    }

    &.tile-layout-absolute {
        width: 106%;
        position: absolute;
        bottom: ${(props) => props.bottomPadding ?? "0"};
        margin-left: -6.5%;
        margin-right: -6.5%;
        height: 50px;
        background-color: #c4c4c4;

        & .private-event-marker-text {
            & p {
                font-size: 16px;
            }
        }
    }

    &.list-layout {
        width: 120px;
        height: 30px;
        border: ${branding.mainBorder};
        border-radius: 10px;
        margin-top: 20px;

        & .private-event-marker-text {
            & p {
                font-size: 12px;
                line-height: 30px; // height of the parent div (30px) - to be able to center text vertically
            }
        }
    }

    & .private-event-marker-text {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        margin-left: 10px;

        & p {
            font-family: ${branding.font1};
            margin: auto;
            color: #000;
        }
    }
`
interface PrivateEventMarkerProps {
    layout: "tile-layout" | "list-layout" | "tile-layout-absolute"
    accessProvider: AccessProvider | undefined
    height?: number
    bottomPadding?: string
    alwaysSticky?: boolean
}
const PrivateEventMarker: React.FC<PrivateEventMarkerProps> = (props) => {
    const strings = useLanguageState().getStrings()
    const iconSize = props.layout === "tile-layout" || props.layout === "tile-layout-absolute" ? "25px" : "14px"
    const displayTicketIcon = props.accessProvider === AccessProvider.TICKET

    return (
        <PrivateEventMarkerRoot
            className={props.layout}
            bottomPadding={props.bottomPadding}
            parentHeight={props.height}
            alwaysSticky={props.alwaysSticky}
        >
            {displayTicketIcon ? (
                <IconTicket width={iconSize} height={iconSize} />
            ) : (
                <IconLock width={iconSize} height={iconSize} />
            )}
            <div className="private-event-marker-text">
                <p>{strings.programSchedule.privateEventMarkerText}</p>
            </div>
        </PrivateEventMarkerRoot>
    )
}

const EventDateBackgroundFixer = styled.div`
    width: 350px;
    min-width: 350px;
    background: #fff;
    position: relative;
    z-index: 2;
`

type EventDateEntryRootProps = {
    marginTop: number
    marginBottom: number
    marginLeft: number
    height: number
    isOpen: boolean
    background: string
    border: string
}

const EventDateEntryRoot = styled.div<EventDateEntryRootProps>`
    border: ${branding.programSchedule.borderWidthForProgramTiles} solid ${(props) => props.border};
    border-radius: 5px;
    padding: 20px;
    padding-bottom: 0;
    overflow: hidden;
    background-color: ${(props) => props.background};
    position: relative;
    display: block;
    flex-wrap: wrap;
    color: ${branding.mainInfoColor};

    margin-top: ${(props) => props.marginTop + "px"};
    margin-left: ${(props) => props.marginLeft + "px"};
    min-height: ${(props) => props.height - 4 + "px"};
    height: ${(props) => (props.isOpen ? "auto" : props.height - 4 + "px")};
    min-width: 350px;
    width: 350px;
    -webkit-transition-duration: 0.4s;
    -ms-transition-duration: 0.4s;
    transition-duration: 0.4s;
    -webkit-transition-property: "height, min-height, margin-bottom";
    -ms-transition-property: "height, min-height, margin-bottom";
    transition-property: "height, min-height, margin-bottom";
    z-index: ${(props) => (props.isOpen ? "2" : "")};
    overflow: hidden;

    &:hover {
        background-color: #f2f2f2; // #f9f9f9;
        cursor: pointer;
        -webkit-transition: background-color 0.4s ease-in-out !important;
        -ms-transition: background-color 0.4s ease-in-out !important;
        transition: background-color 0.4s ease-in-out !important;
        overflow-y: auto;
    }
    z-index: 2;
`

const EventDateBadgeWraper = styled.div`
    display: inline-block;
    position: relative;
    padding-block-start: 5px;
    padding-block-end: 5px;
    padding-inline-start: 25px;
    padding-inline-end: 15px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.4);
    box-sizing: border-box;
    border-radius: 20px;
    justify-self: right;
`
const EventDateBadgeDot = styled.div`
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: #f44336;
    margin-left: -16px;
    margin-right: 5px;
    margin-bottom: 1px;
`

const EventHeaderDiv = styled.div`
    background-image: url("${(props) => props.style?.backgroundImage}");
    background-size: cover;
    background-position: left top;
    display: block;
    position: absolute;
    top: 0;
    right: 0px;
    width: 100%;
    height: 60px;
    z-index: 10;
`

const EventLogoBadgeContainer = styled.div`
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 30px;
    margin-top: ${(props) => props.style?.marginTop};
`
const EventDateTileHeaderWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 20;
    position: relative;
`

const EventDateFavBookmarkRoot = styled.div`
    right: 13px;
    top: 19px;
    font-size: 20px;
`
const TimeAndTagsWrapper = styled.div`
    display: flex;
    align-items: center;
`
const EventDateEntryTime = styled.div<{ upcoming?: boolean }>`
    font-size: ${(props) => (props.upcoming ? "0.9em" : "1em")};
    line-height: 1.2em;
    font-family: ${branding.font1};
    color: ${(props) => props.color};
    top: 25px;
`
const EventDateEntryName = styled.div`
    align-self: start;
    text-overflow: ellipsis;
    padding-right: 15px;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    margin-top: 20px;
    font-family: ${branding.font1};
`

const EventDateEntryLogoDiv = styled.div`
    height: 30px;
    & img {
        height: 100%;
        width: auto;
    }
`

const PersonColumn = styled.div`
    margin-top: 14px;
`

interface EventDateEntryComponentProps {
    eventDate: EventDate
    loggedInUser: User | undefined
    showContactModal: boolean
    selectedSpeaker: string
    width?: string
    height: number
    marginTop: number
    marginLeft?: number
    border?: string
    background?: string
    upcoming: boolean
    pointBadgeData?: Category
    setShowContactModal: (value: boolean) => void
    setSelectedSpeaker: (value: string) => void
}

const EventDateEntryComponent = (props: EventDateEntryComponentProps) => {
    const wrapperElement = React.createRef<HTMLDivElement>()
    const nameElement = React.createRef<HTMLDivElement>()
    const speakerElement = React.createRef<HTMLDivElement>()
    const teaserElement = React.createRef<HTMLDivElement>()
    const placeholderElement = React.createRef<HTMLDivElement>()
    const logoElement = React.createRef<HTMLDivElement>()
    const liveElement = React.createRef<HTMLDivElement>()
    const coverElement = React.createRef<HTMLDivElement>()
    const languageState = useLanguageState()
    const strings = languageState.getStrings()
    const lang = languageState.getLanguage()
    const IsEventLive = isEventDateLive(props.eventDate)
    const activateChannel = activateChannelBefore(props.eventDate)
    const isEventUnlocked = useUserRestrictedAreaAccess().isUnlocked(props.eventDate)
    const [extraHeight, setExtraHeight] = useState(0)

    let targetType: DetailNavLinkType
    let targetId: string
    if (
        activateChannel &&
        isEventUnlocked &&
        props.eventDate.channel &&
        props.loggedInUser &&
        (props.loggedInUser.type !== "guest" ||
            (props.eventDate.organizationId && hasAccessToOrganization(props.loggedInUser, props.eventDate.organizationId)))
    ) {
        targetType = "channel"
        targetId = props.eventDate.channel?.id
    } else {
        targetType = "eventdate"
        targetId = props.eventDate.id
    }

    const hourFormat = lang === "en" ? "hh:mm A" : "HH:mm"

    const start =
        props.eventDate.date && props.eventDate.start
            ? moment(props.eventDate.date + "T" + props.eventDate.start).format(hourFormat)
            : "Day start"

    const end =
        props.eventDate.end && props.eventDate.end
            ? moment(props.eventDate.date + "T" + props.eventDate.end).format(hourFormat)
            : "Day end"

    const eventDateBorderColorByOrganization = branding.programSchedule.customColorsForOrganizations.find(
        (organization: any) => props.eventDate?.organizationId! === organization.organizationId
    )?.borderColor

    const isLogoAvailable = () => {
        return props.eventDate.organizationLogoUrl
    }

    function getTileLayoutVersion(): "tile-layout" | "tile-layout-absolute" {
        if (
            (props.eventDate.persons?.length > 0 && props.height < 280) ||
            (props.eventDate.persons?.length > 0 && props.height <= 300 && branding.programSchedule.eventdateDescriptionVisible)
        ) {
            return "tile-layout"
        } else if (props.eventDate.persons?.length > 0 || props.height >= 280) {
            return "tile-layout-absolute"
        } else {
            return "tile-layout"
        }
    }

    useEffect(() => {
        let timer = setTimeout(() => {
            let hig = wrapperElement.current?.clientHeight || 0
            let xtraMargin = hig - props.height + 2 // 2 is border 1 + 1
            setExtraHeight(xtraMargin)
        }, 351)
        return () => {
            clearTimeout(timer)
        }
    }, [wrapperElement, props.height])

    useEffect(() => {
        changeEntryContentVisibility(
            wrapperElement.current!,
            nameElement.current!,
            speakerElement.current!,
            teaserElement.current!,
            placeholderElement.current!,
            logoElement.current!,
            liveElement.current!,
            coverElement.current!
        )
    }, [wrapperElement, nameElement, speakerElement, teaserElement, placeholderElement, logoElement, liveElement, coverElement])

    return (
        <div className="eventDate-entry-root" style={{ padding: "2px 0" }}>
            {props.showContactModal && (
                <ContactModal
                    userId={""}
                    personId={props.selectedSpeaker}
                    close={() => props.setShowContactModal(false)}
                ></ContactModal>
            )}
            <DetailNavLink name={props.eventDate.name} id={targetId} type={targetType} width={props.width ? props.width : ""}>
                <EventDateBackgroundFixer>
                    <EventDateEntryRoot
                        className="eventDateRoot"
                        ref={wrapperElement}
                        height={props.height}
                        marginTop={props.marginTop}
                        isOpen={false}
                        marginBottom={extraHeight}
                        marginLeft={props.marginLeft ? props.marginLeft : 0}
                        background={props.background ?? "#fff"}
                        border={eventDateBorderColorByOrganization ? eventDateBorderColorByOrganization : props.border ?? "#000"}
                    >
                        <EventHeaderDiv
                            ref={coverElement}
                            style={{
                                display: IsEventLive ? "" : "none",
                                backgroundImage: props.eventDate.liveStreamingPreviewUrl
                            }}
                        ></EventHeaderDiv>
                        <EventDateTileHeaderWrapper>
                            <TimeAndTagsWrapper>
                                <EventDateEntryTime
                                    upcoming={props.upcoming}
                                    color={IsEventLive ? "black" : branding.mainInfoColor}
                                >
                                    {props.upcoming && props.eventDate
                                        ? format(
                                              moment(props.eventDate.date).toDate(),
                                              strings.eventTiming.eventDaysFormatPatternShort,
                                              {
                                                  locale: lang === "de" ? de : en
                                              }
                                          ) + ","
                                        : ""}{" "}
                                    {start} - {end + (lang === "de" ? " Uhr" : "")}
                                </EventDateEntryTime>

                                {branding.programSchedule.showTileTags && (
                                    <TileTagsWrapper
                                        videoOnDemandUrl={props.eventDate.videoOnDemandUrl}
                                        typeformUrl={props.eventDate.typeformUrl}
                                    />
                                )}
                            </TimeAndTagsWrapper>

                            <EventDateFavBookmarkRoot>
                                <BookmarkWithToggle
                                    newBookmarkItem={true}
                                    fontSize={"15px"}
                                    color={branding.sideIconBar.sideIconColorDark}
                                    stroke={branding.sideIconBar.sideIconColorDark}
                                    favIconBasic={true}
                                    type={"eventdate"}
                                    id={props.eventDate.id}
                                    name={props.eventDate.name}
                                />
                            </EventDateFavBookmarkRoot>
                        </EventDateTileHeaderWrapper>
                        {(IsEventLive || isLogoAvailable()) && (
                            <EventLogoBadgeContainer style={{ marginTop: 20, marginBottom: "8px" }}>
                                {isLogoAvailable() && (
                                    <EventDateEntryLogoDiv style={{ display: isLogoAvailable() ? "" : "none" }}>
                                        <img
                                            src={
                                                props.eventDate.organizationLogoUrl && props.eventDate.organizationLogoUrl !== ""
                                                    ? props.eventDate.organizationLogoUrl
                                                    : "/branding/rsz_defaultgrey.png"
                                            }
                                            alt={props.eventDate.logoText}
                                            width="30"
                                            height="30"
                                            style={{ objectFit: "contain" }}
                                        />
                                    </EventDateEntryLogoDiv>
                                )}
                                {IsEventLive && (
                                    <EventDateBadgeWraper
                                        ref={liveElement}
                                        style={{
                                            gridColumn: isLogoAvailable() ? 2 : 1,
                                            justifySelf: isLogoAvailable() ? "right" : "left",
                                            display: IsEventLive ? "" : "none"
                                        }}
                                    >
                                        <EventDateBadgeDot />
                                        {strings.personDetailPageContent.liveSessionsTitle}
                                    </EventDateBadgeWraper>
                                )}
                            </EventLogoBadgeContainer>
                        )}

                        <BadgeArea categories={props.eventDate.categories || []} marginTop="10px" marginBottom={"5px"} />

                        <EventDateEntryName ref={nameElement} style={{ marginTop: 15 }}>
                            {props.eventDate.name}
                        </EventDateEntryName>

                        <PersonColumn ref={speakerElement}>
                            <CrsPersons
                                layoutType={1}
                                showModalPopup={true}
                                justifycontent="flex-start"
                                persons={[...props.eventDate.persons]}
                                onUserClick={(userId: string) => {
                                    props.setShowContactModal(true)
                                    props.setSelectedSpeaker(userId)
                                }}
                                personsToShow={3}
                                eventDateEntryContent={true}
                                width="30px"
                                minWidth="30px"
                                height="30px"
                                color="#575757"
                            />

                            {props.pointBadgeData &&
                                props.eventDate.categories
                                    ?.map((x) => x.alias)
                                    .includes(branding.programSchedule.pointsBadgeCategoryAlias) && (
                                    <PointsBadge
                                        textColor={props.pointBadgeData.colorText}
                                        fillColor={props.pointBadgeData.colorFill}
                                        borderColor={props.pointBadgeData.colorBorder}
                                    >
                                        {strings.programSchedule.pointsBadgeText}
                                    </PointsBadge>
                                )}
                        </PersonColumn>

                        {branding.programSchedule.eventdateDescriptionVisible && (
                            <DescriptionRoot className="mt-3">{props.eventDate.teaser}</DescriptionRoot>
                        )}

                        {props.eventDate.accessProvider !== AccessProvider.PUBLIC && props.eventDate.persons?.length === 0 && (
                            <div style={{ height: "50px" }}></div>
                        )}
                        <div style={{ marginBottom: "20px" }}></div>

                        {props.eventDate.accessProvider !== AccessProvider.PUBLIC && (
                            <PrivateEventMarker
                                layout={getTileLayoutVersion()}
                                accessProvider={props.eventDate.accessProvider}
                                height={props.height}
                            />
                        )}
                    </EventDateEntryRoot>
                </EventDateBackgroundFixer>
            </DetailNavLink>
        </div>
    )
}

/* #region  My schedule part */
interface EventDateTileRootProps {
    height: number
    marginTop: number
    marginLeft: number
    border: string
    opacity: string
    width?: string
    zIndex: number
}

const EventDateTileRoot = styled.div<EventDateTileRootProps>`
    height: ${(props) => props.height + "px"};
    margin-top: ${(props) => props.marginTop + "px"};
    margin-left: ${(props) => props.marginLeft + "px"};
    border: ${(props) => props.border};
    border-radius: 5px;
    padding: 10px;
    padding-bottom: 10px;
    background: #fff;
    min-width: 350px;
    width: ${(props) => (props.width ? props.width : "350px")};
    position: relative;
    cursor: pointer;
    overflow: hidden;
    z-index: ${(props) => props.zIndex};
    .opacity {
        opacity: ${(props) => props.opacity};
    }
    color: ${branding.mainInfoColor};
`

const TileFooter = styled.div<{ display: string }>`
    display: ${(props) => props.display};
`

const EventDateOrganizationLogo = styled.div<{ logoUrl: string }>`
    background: ${(props) => "url(" + props.logoUrl + ")"};
    width: 40px;
    height: 40px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
`

const TileTitleDiv = styled.div`
    max-width: 400px;
    max-height: 200px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`

const RoundTableAccess = styled.div<{ accessGranted: boolean }>`
    margin-top: 5px;
    border: 1px solid;
    color: ${(props) => (props.accessGranted ? "#00B300" : branding.dangerButtonColor)};
    border-radius: 20px;
    width: fit-content;
    text-align: center;
    font-size: 12px;
    padding: 5px;
    padding-top: 1px;
    padding-bottom: 2px;
`

interface SliderParentProps {
    height: string
    marginTop: string
    itemsCount: number
    size: number
}
const SliderParent = styled.div<SliderParentProps>`
    width: 100%;
    height: ${(props) => props.height};
    overflow-x: hidden;
    overflow-y: hidden;
    margin-top: ${(props) => props.marginTop};

    &:hover,
    &:focus {
        overflow-x: scroll;
        z-index: 1000;
    }

    &:hover,
    &:focus .sliderWrapper {
        background: rgba(255, 255, 255, 0.85);
    }

    .sliderWrapper {
        display: flex;
        width: ${(props) => props.itemsCount * props.size + "px"};
    }
`

const MeetingDescriptionDiv = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    font-style: normal;
    display: inline-block;
    width: 90%;
    white-space: nowrap;
`
const SliderItem = styled.div<{ width: number }>`
    display: inline-block;
    width: ${(props) => props.width + "px"};
`

export const PointsBadge = styled.div<{ textColor: string; fillColor: string; borderColor: string }>`
    display: inline-block;
    padding: 5px;
    color: ${(props) => props.textColor};
    background-color: ${(props) => props.fillColor};
    border-color: ${(props) => props.borderColor};
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    font-size: 12px;
    font-family: ${branding.font1};
`

const DescriptionRoot = styled.div`
    font-family: ${branding.font1};
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    width: 100%;

    &.list-view {
        margin-top: 5px;
        -webkit-line-clamp: 1;
    }
`

interface MyScheduleEntryProps {
    myScheduleObject: any
    marginTop: number
    height: number
    overlappingObjects?: any[] | null
    isAdded?: boolean
    upcoming?: boolean
    zIndex: number
    pointsBadgeData?: Category

    isMobile?: boolean
}

const MyScheduleEventDateEntry: React.FunctionComponent<MyScheduleEntryProps> = (props) => {
    const languageState = useLanguageState()
    const strings = languageState.getStrings()
    const language = languageState.getLanguage()
    const userAccessState = useUserRestrictedAreaAccess()
    const logedInUserId = useLoggedInState().user()?.profileId
    const organizationId = props.myScheduleObject.hasOwnProperty("organizationId") ? props.myScheduleObject.organizationId : null
    const isPrivate =
        (props.myScheduleObject.accessProvider && props.myScheduleObject.accessProvider !== AccessProvider.PUBLIC) || false
    const isEventUnlocked = userAccessState.isUnlocked(props.myScheduleObject)
    const [organization, setOrganization] = useState<Company | null>(null)
    const timezone = useAppState().timezone
    const { showCalendarEntryModal, CalendarModal } = useCalendarEntryModal()

    function checkEventTime() {
        //current dateTime
        const date = momentWithoutTimezoneFromTimezonedMoment(moment(), timezone).toDate()
        const currentDate = moment(date).format("YYYY-MM-DD")
        const currentTime = moment(date).format("HH:mm")
        const [currentHours, currentMinutes] = currentTime.split(":")
        const currentTimeStamp = parseInt(currentHours) * 60 + parseInt(currentMinutes)

        //event-meeting dateTime
        const eventDate = props.myScheduleObject.hasOwnProperty("title")
            ? moment(props.myScheduleObject.end).format("YYYY-MM-DD")
            : props.myScheduleObject.date
        const meetingTime = moment(new Date(props.myScheduleObject.end)).format("HH:mm")
        const eventTime = props.myScheduleObject.hasOwnProperty("title") ? meetingTime : props.myScheduleObject.end
        const [eventHours, eventMinutes] = eventTime.split(":")
        const eventTimeStamp = parseInt(eventHours) * 60 + parseInt(eventMinutes)

        if (eventDate < currentDate || (eventDate <= currentDate && eventTimeStamp < currentTimeStamp)) return true

        return false
    }

    useEffect(() => {
        if (organizationId) {
            loadExhibitorData({
                organizationid: organizationId,
                hideNewsdata: false,
                showPersonsEventDates: true
            }).then((data) => {
                setOrganization(data.content)
            })
        }
    }, [organizationId])

    function getFormattedTime(type: string) {
        let date = new Date()

        if (props.myScheduleObject.hasOwnProperty("startHour")) {
            if (type === "start") {
                date.setHours(props.myScheduleObject.startHour)
                date.setMinutes(props.myScheduleObject.startMinutes)
            } else {
                date.setHours(props.myScheduleObject.endHour)
                date.setMinutes(props.myScheduleObject.endMinutes)
            }
        } else {
            if (type === "start")
                date = momentWithoutTimezoneFromTimezonedMoment(moment(new Date(props.myScheduleObject.start)), timezone).toDate()
            else date = momentWithoutTimezoneFromTimezonedMoment(moment(new Date(props.myScheduleObject.end)), timezone).toDate()
        }
        return language === "en" ? moment(date).format("hh:mm a").toUpperCase() : moment(date).format("HH:mm")
    }
    const checkTime = checkEventTime()
    const activateChannel = activateChannelBefore(props.myScheduleObject)
    const isRoundtable = props.myScheduleObject.roundTableAccessStatus && props.myScheduleObject.roundTableAccessStatus.length > 0
    const roundtableAccessGranted = props.myScheduleObject.roundTableAccessStatus === "GRANTED"

    const acceptedParticipants = () => {
        return props.myScheduleObject.participants.items
            .filter((item: any) => item.status === "ACCEPTED" && item.user.id !== logedInUserId)
            .map((it: any) => " " + it.user.name)
    }

    const meetingDescription = (
        <>
            {props.myScheduleObject.hasOwnProperty("participants") && (
                <>
                    {organization &&
                        strings.programSchedule.meetingText +
                            " " +
                            organization.name +
                            `${acceptedParticipants.length > 0 ? " ( " + acceptedParticipants + ")" : ""}`}
                    {organization === null &&
                        strings.programSchedule.meetingText +
                            props.myScheduleObject.participants.items.map((item: any) => " " + item.user.name)}
                </>
            )}
        </>
    )

    return (
        <DetailNavLink
            name={props.myScheduleObject.hasOwnProperty("title") ? props.myScheduleObject.title : props.myScheduleObject.name}
            id={
                activateChannel && isEventUnlocked && props.myScheduleObject.channel
                    ? props.myScheduleObject.channel.id
                    : props.myScheduleObject.id
            }
            type={activateChannel && isEventUnlocked && props.myScheduleObject.channel ? "channel" : "eventdate"}
            onClick={(e) => {
                if (props.myScheduleObject.hasOwnProperty("title")) {
                    const selectedMeeting = props.myScheduleObject
                    e.preventDefault()

                    const viewMode =
                        selectedMeeting.userId === logedInUserId &&
                        moment(selectedMeeting.start).toDate().getFullYear() === moment().year() &&
                        moment(moment(selectedMeeting.start).toDate()).dayOfYear() >= moment().dayOfYear()
                            ? CalendarEntryModalViewMode.EDIT
                            : CalendarEntryModalViewMode.VIEW
                    showCalendarEntryModal({ calendarEntry: props.myScheduleObject, viewMode: viewMode, sotUser: [] })
                }
            }}
            width={props.isMobile ? "220px" : props.upcoming ? "365px" : "100%"}
        >
            <CalendarModal />
            {props.isMobile ? (
                <MobileEventDateEntryRoot key={props.myScheduleObject.id}>
                    <div style={{ flexDirection: "column" }}>
                        <MobileEventDateEntryTime>
                            {getFormattedTime("start") + " - " + getFormattedTime("end") + (language === "de" ? " Uhr" : "")}
                        </MobileEventDateEntryTime>

                        <MobileEventDateEntryTitle>
                            {props.myScheduleObject.hasOwnProperty("title")
                                ? props.myScheduleObject.title
                                : props.myScheduleObject.name}
                        </MobileEventDateEntryTitle>

                        {props.myScheduleObject.hasOwnProperty("teaser") && (
                            <MobileEventDateEntryDescription>{props.myScheduleObject.teaser}</MobileEventDateEntryDescription>
                        )}
                        {props.myScheduleObject.hasOwnProperty("participants") && (
                            <MobileEventDateEntryDescription>{meetingDescription}</MobileEventDateEntryDescription>
                        )}
                    </div>
                </MobileEventDateEntryRoot>
            ) : (
                <EventDateTileRoot
                    border={checkTime ? "1px solid rgba(0, 0, 0, .5)" : "1px solid " + branding.mainInfoColor}
                    opacity={checkTime ? ".5" : "1"}
                    height={props.height}
                    marginTop={props.marginTop}
                    marginLeft={0}
                    width={"100%"}
                    zIndex={props.zIndex}
                >
                    {/* padding bottom 50px(height of the private event marker div) -> to be able to see icons which are aligned with the bottom of the element */}
                    <div
                        style={{ paddingBottom: isPrivate ? "50px" : "0" }}
                        className="h-100 d-flex justify-content-between flex-column opacity mb-20"
                    >
                        <div className="d-flex justify-content-between align-items-start">
                            <div className="w-100">
                                <div>
                                    {getFormattedTime("start") +
                                        " - " +
                                        getFormattedTime("end") +
                                        (language === "de" ? " Uhr" : "")}
                                </div>
                                <TileTitleDiv>
                                    {props.myScheduleObject.hasOwnProperty("title")
                                        ? props.myScheduleObject.title
                                        : props.myScheduleObject.name}
                                </TileTitleDiv>
                                {isRoundtable && (
                                    <RoundTableAccess accessGranted={roundtableAccessGranted}>
                                        {roundtableAccessGranted
                                            ? strings.programSchedule.roundtableAccessGranted
                                            : strings.programSchedule.roundtableAccessRequested}
                                    </RoundTableAccess>
                                )}
                                {props.myScheduleObject.hasOwnProperty("participants") && (
                                    <MeetingDescriptionDiv className="d-flex justify-content-between w-100">
                                        {meetingDescription}
                                    </MeetingDescriptionDiv>
                                )}
                                {props.pointsBadgeData &&
                                    props.myScheduleObject.categories?.includes(
                                        branding.programSchedule.pointsBadgeCategoryAlias
                                    ) && (
                                        <PointsBadge
                                            textColor={props.pointsBadgeData.colorText}
                                            fillColor={props.pointsBadgeData.colorFill}
                                            borderColor={props.pointsBadgeData.colorBorder}
                                        >
                                            {strings.programSchedule.pointsBadgeText}
                                        </PointsBadge>
                                    )}
                                {props.myScheduleObject.hasOwnProperty("teaser") &&
                                    branding.programSchedule.eventdateDescriptionVisible && (
                                        <DescriptionRoot className="mt-3">{props.myScheduleObject.teaser}</DescriptionRoot>
                                    )}
                            </div>
                            <div className={"d-flex"}>
                                {props.myScheduleObject.hasOwnProperty("title") ? (
                                    <div>{IconCalendarEntry({ fill: branding.sideIconBar.sideIconColorDark })}</div>
                                ) : (
                                    <BookmarkWithToggle
                                        newBookmarkItem={true}
                                        color={branding.sideIconBar.sideIconColorDark}
                                        fontSize={"15px"}
                                        type={"eventdate"}
                                        id={props.myScheduleObject.id}
                                        name={props.myScheduleObject.name}
                                    />
                                )}
                            </div>
                        </div>
                        <TileFooter
                            className="justify-content-between align-items-end h-50"
                            display={props.myScheduleObject.hasOwnProperty("name") ? "flex" : "none"}
                        >
                            <div>
                                {isRoundtable
                                    ? IconRoundTable({ width: "20", height: "20", fill: branding.sideIconBar.sideIconColorDark })
                                    : IconVideo({ fill: branding.sideIconBar.sideIconColorDark })}
                            </div>
                            <EventDateOrganizationLogo
                                logoUrl={
                                    props.myScheduleObject.organizationLogoUrl
                                        ? props.myScheduleObject.organizationLogoUrl
                                        : "/branding/rsz_defaultgrey.png"
                                }
                            />
                        </TileFooter>
                    </div>

                    <div style={{ marginBottom: "10px" }}></div>

                    {isPrivate && (
                        <PrivateEventMarker
                            layout={
                                props.myScheduleObject.hasOwnProperty("participants") ? "tile-layout" : "tile-layout-absolute"
                            }
                            accessProvider={props.myScheduleObject.accessProvider}
                            bottomPadding="-10px"
                            alwaysSticky={true}
                        />
                    )}
                </EventDateTileRoot>
            )}
        </DetailNavLink>
    )
}

/* #endregion */

const MobileEventDateEntryTime = styled.div`
    display: flex;
    flex-direction: row;
    font-family: ${branding.font1};
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    margin-top: 15px;
    margin-left: 12px;
    overflow: hidden;
`

const MobileEventDateEntryTitle = styled.div`
    display: inherit;
    font-family: ${branding.font1};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    margin-top: 20px;
    margin-left: 12px;
    overflow: hidden;
    width: 180px;
    white-space: nowrap;
    text-overflow: ellipsis;
`

const MobileEventDateEntryDescription = styled.div`
    display: inherit;
    flex-direction: row;
    font-family: ${branding.font1};
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    margin-top: 5px;
    margin-left: 12px;
    overflow: hidden;
    width: 180px;
    white-space: nowrap;
    text-overflow: ellipsis;
`

const MobileEventDateEntryRoot = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    border-radius: 7px;
    border: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"};
    height: 95px;
    width: 205px;
    min-width: 205px;
    margin: ${branding.receptionPage.showfloorTileMargins ?? "0px 10px 20px 0px"};
`

type EntryProps = {
    eventDate: EventDate
    marginTop: number
    marginLeft?: number
    height: number
    width?: string
    mySchedule?: boolean
    myScheduleObject?: any
    overlappingObjects?: any[] | null
    isAdded?: boolean
    upcoming?: boolean
    zIndex?: number
    pointBadgeData?: Category
    background?: string
    border?: string
    customborderbyorganization?: string

    isMobile?: boolean
}

const EventDateEntry: React.FunctionComponent<EntryProps> = (props) => {
    const [showContactModal, setShowContactModal] = useState<boolean>(false)
    const [selectedSpeaker, setSelectedSpeaker] = useState<string>("")
    const loggedInUser = useLoggedInState().user()
    const windowSize = useWindowDimensions()

    /*
     PROBLEM: precalculating the height's is difficult. We do know the available height, line-height etc, but we cannot precalculate the needed amount of rows for the text.
     We could calculate that we have enough room for 4 lines of header, or 10 lines of teaser. But we won't know if the text for them will fill those lines.
    */

    if (props.overlappingObjects) {
        if (props.isAdded) {
            return <div></div>
        } else {
            const maxHeight = props.overlappingObjects.sort((a, b) => b.height - a.height)[0].height
            return (
                <SliderParent
                    height={maxHeight + "px"}
                    marginTop={props.marginTop + "px"}
                    itemsCount={props.overlappingObjects.length}
                    size={props.mySchedule ? getSize(windowSize.width) : 350}
                >
                    <div className="sliderWrapper">
                        {props.overlappingObjects.map((item, index) => {
                            return (
                                <SliderItem key={index} width={props.mySchedule ? getSize(windowSize.width) : 350}>
                                    {props.mySchedule ? (
                                        <MyScheduleEventDateEntry
                                            myScheduleObject={item.object}
                                            height={item.height}
                                            marginTop={0}
                                            zIndex={props.zIndex!}
                                            pointsBadgeData={props.pointBadgeData}
                                            isMobile={props.isMobile}
                                        />
                                    ) : (
                                        <EventDateEntryComponent
                                            eventDate={item.object}
                                            loggedInUser={loggedInUser}
                                            showContactModal={showContactModal}
                                            selectedSpeaker={selectedSpeaker}
                                            width="auto"
                                            height={item.height}
                                            marginTop={0}
                                            marginLeft={props.marginLeft}
                                            border={props.border}
                                            background={props.background}
                                            upcoming={props.upcoming ?? false}
                                            pointBadgeData={props.pointBadgeData}
                                            setShowContactModal={setShowContactModal}
                                            setSelectedSpeaker={setSelectedSpeaker}
                                        />
                                    )}
                                </SliderItem>
                            )
                        })}
                    </div>
                </SliderParent>
            )
        }
    } else if (props.mySchedule || props.isMobile) {
        return (
            <MyScheduleEventDateEntry
                myScheduleObject={props.myScheduleObject}
                height={props.height}
                marginTop={props.marginTop}
                upcoming={props.upcoming}
                zIndex={props.zIndex!}
                pointsBadgeData={props.pointBadgeData}
                isMobile={props.isMobile}
            />
        )
    } else {
        return (
            <EventDateEntryComponent
                eventDate={props.eventDate}
                loggedInUser={loggedInUser}
                showContactModal={showContactModal}
                selectedSpeaker={selectedSpeaker}
                width={props.width}
                height={props.height}
                marginTop={props.marginTop}
                marginLeft={props.marginLeft}
                border={props.border}
                background={props.background}
                upcoming={props.upcoming ?? false}
                pointBadgeData={props.pointBadgeData}
                setShowContactModal={setShowContactModal}
                setSelectedSpeaker={setSelectedSpeaker}
            />
        )
    }
}

export default EventDateEntry

const EventDateFavBookmarkRoot1 = styled.div`
    display: flex;
    flex: 1;

    &.center-inside * {
        display: flex;
        justify-content: center;
    }
`
const EventDateBadgeWraper1 = styled.div`
    display: inline-block;
    padding-block-start: 5px;
    padding-block-end: 5px;
    padding-inline-start: 25px;
    padding-inline-end: 15px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.4);
    box-sizing: border-box;
    border-radius: 20px;
    font-size: 12px;
`
const EventDateBadgeDot1 = styled.div`
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: #f44336;
    margin-left: -16px;
    margin-right: 5px;
    margin-bottom: 1px;
`

const SpeakersDiv = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    white-space: none;
    font-size: 14px;
`

type ListEntryColumnProps = {
    eventDates: EventDate[]
    helper: ScheduleHelper
    pointBadgeData?: Category
}

const ListEventDateEntryDataContainer = styled.div`
    display: flex !important;
    width: 100%;
    padding-right: 10px;
    margin-left: 25px;

    @media ${device.mobile} {
        margin-top: -4px;
    }
`
const ListEventDateEntryColumn: React.FunctionComponent<ListEntryColumnProps> = (props) => {
    const strings = useLanguageState().getStrings()
    const lang = useLanguageState().getLanguage()
    const userAccessState = useUserRestrictedAreaAccess()

    const hourFormat = lang === "en" ? "hh:mm A" : "HH:mm"

    return (
        <>
            {props.eventDates.map((eventDate, index) => {
                const isLive = isEventDateLive(eventDate)
                const activateChannel = activateChannelBefore(eventDate)
                const isEventUnlocked = userAccessState.isUnlocked(eventDate)
                let start = moment(eventDate.date + "T" + eventDate.start).format(hourFormat)
                let end = moment(eventDate.date + "T" + eventDate.end).format(hourFormat)
                const speakers = eventDate.persons.map((speaker) => {
                    let name = [speaker.title, speaker.firstName, speaker.lastName].filter(Boolean).join(" ")

                    let position = [speaker.position, speaker.organization]
                        .filter(Boolean)
                        .join(" " + strings.communicationArea.personCompanyLink + " ")

                    return " " + name + (position ? " (" + position + ")" : "")
                })

                return (
                    <DetailNavLink
                        key={index}
                        name={eventDate.name}
                        id={
                            activateChannel && isEventUnlocked && eventDate.channel
                                ? eventDate.channel?.id || eventDate.id
                                : eventDate.id
                        }
                        type={activateChannel && isEventUnlocked && eventDate.channel ? "channel" : "eventdate"}
                    >
                        <div
                            style={{
                                borderBottom: "1px solid" + branding.listDividerColor,
                                color: branding.mainInfoColor,
                                padding: "25px 0",
                                position: "relative"
                            }}
                        >
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div style={{ width: "100px" }}>
                                    <div
                                        style={{
                                            width: "95px",
                                            height: "95px",
                                            border: "1px solid #c9c9c9",
                                            borderRadius: "5px",
                                            objectFit: "contain",
                                            display: "flex",
                                            alignItems: "center"
                                        }}
                                    >
                                        <img
                                            src={
                                                eventDate.organizationLogoUrl && eventDate.organizationLogoUrl !== ""
                                                    ? eventDate.organizationLogoUrl
                                                    : "/branding/rsz_defaultgrey.png"
                                            }
                                            alt={eventDate.logoText}
                                            style={{ objectFit: "contain", width: "80%", margin: "0 auto" }}
                                        />
                                    </div>
                                </div>
                                <ListEventDateEntryDataContainer>
                                    <EventDateFavBookmarkRoot1>
                                        <div>
                                            <div className="d-flex">
                                                <div style={{ fontSize: "12px", fontWeight: 400, marginBottom: "10px" }}>
                                                    {start} - {end + (lang === "de" ? " Uhr" : "")} - {eventDate.location}{" "}
                                                </div>
                                                {branding.programSchedule.showTileTags && (
                                                    <TileTagsWrapper
                                                        videoOnDemandUrl={eventDate.videoOnDemandUrl}
                                                        typeformUrl={eventDate.typeformUrl}
                                                    />
                                                )}
                                            </div>
                                            <div
                                                style={{
                                                    fontSize: "16px",
                                                    fontWeight: "bold",
                                                    marginBottom: "10px",
                                                    color: branding.programSchedule.listViewTitleColor,
                                                    fontFamily: branding.programSchedule.listViewTitleFont
                                                }}
                                            >
                                                {eventDate.name}{" "}
                                            </div>

                                            {speakers && speakers.length > 0 && <SpeakersDiv>{"" + speakers}</SpeakersDiv>}

                                            {branding.programSchedule.eventdateDescriptionVisible && (
                                                <DescriptionRoot className="list-view">
                                                    <div style={{ fontSize: "12px" }}> {eventDate.teaser} </div>
                                                </DescriptionRoot>
                                            )}

                                            {eventDate.accessProvider !== AccessProvider.PUBLIC && (
                                                <PrivateEventMarker
                                                    layout="list-layout"
                                                    accessProvider={eventDate.accessProvider}
                                                />
                                            )}

                                            <BadgeArea categories={eventDate.categories || []} marginTop="8px" />
                                        </div>
                                    </EventDateFavBookmarkRoot1>
                                    {props.pointBadgeData &&
                                        eventDate.categories
                                            ?.map((x) => x.alias)
                                            ?.includes(branding.programSchedule.pointsBadgeCategoryAlias) && (
                                            <PointsBadge
                                                textColor={props.pointBadgeData.colorText}
                                                fillColor={props.pointBadgeData.colorFill}
                                                borderColor={props.pointBadgeData.colorBorder}
                                            >
                                                {strings.programSchedule.pointsBadgeText}
                                            </PointsBadge>
                                        )}
                                </ListEventDateEntryDataContainer>

                                {isLive && (
                                    <div
                                        style={{
                                            width: "150px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}
                                    >
                                        <EventDateBadgeWraper1>
                                            <EventDateBadgeDot1 />
                                            {strings.sideIconBar.liveMenuText}
                                        </EventDateBadgeWraper1>
                                    </div>
                                )}
                                <div className="d-flex align-items-start" style={{ width: "60px" }}>
                                    <EventDateFavBookmarkRoot1 className={"center-inside"} style={{ justifyContent: "flex-end" }}>
                                        <BookmarkWithToggle
                                            newBookmarkItem={true}
                                            fontSize={"15px"}
                                            color={branding.sideIconBar.sideIconColorDark}
                                            stroke={branding.sideIconBar.sideIconColorDark}
                                            favIconBasic={true}
                                            type={"eventdate"}
                                            id={eventDate.id}
                                            name={eventDate.name}
                                        />
                                    </EventDateFavBookmarkRoot1>
                                </div>
                            </div>
                        </div>
                    </DetailNavLink>
                )
            })}

            {props.eventDates && props.eventDates?.length === 4 && <div style={{ height: "40px" }} />}
        </>
    )
}

export { ListEventDateEntryColumn }

function changeEntryContentVisibility(
    wrapperElement: HTMLElement | null,
    nameElement: HTMLElement | null,
    speakerElement: HTMLElement | null,
    teaserElement: HTMLElement | null,
    placeholderElement: HTMLElement | null,
    logoElement: HTMLElement | null,
    liveElement: HTMLElement | null,
    coverElement: HTMLElement | null
) {
    if (
        !wrapperElement ||
        !nameElement ||
        !speakerElement ||
        !teaserElement ||
        !placeholderElement ||
        !logoElement ||
        !liveElement ||
        !coverElement
    )
        return
    setVisible(placeholderElement, true)
    let lastVisibleElement: HTMLElement = nameElement
    // content height without padding
    const availableContentHeight = getInnerHeight(wrapperElement)
    let height = getHeightForElement(
        nameElement, //
        // subtract height and margin of the speaker, if we have one, from the available space. because we want it to be visible, if possible
        isVisible(speakerElement) ? availableContentHeight - getHeightPlusMargin(speakerElement) : availableContentHeight,
        1
    )

    // if the new max-height of the name plus the speaker height and position is still bigger then the available space (possible because we want at least one line of the name)
    // => rerun the calculation for name height without considerating the speaker
    if (isVisible(speakerElement) && getHeightPlusMargin(speakerElement) + height > availableContentHeight) {
        height = getHeightForElement(nameElement, availableContentHeight, 1)
    }
    nameElement.style.height = height + "px"

    if (isVisible(speakerElement)) {
        setVisible(
            speakerElement,
            getOffsetTopWithoutParentPadding(speakerElement) + speakerElement.offsetHeight <= availableContentHeight
        )
        if (isVisible(speakerElement)) lastVisibleElement = speakerElement
    }

    if (isVisible(teaserElement)) {
        height = getHeightForElement(teaserElement, availableContentHeight, 0)
        if (height <= 0) setVisible(teaserElement, false)
        else teaserElement.style.height = height + "px"
        if (isVisible(teaserElement)) lastVisibleElement = teaserElement
    }

    if (availableContentHeight - (getOffsetTopWithoutParentPadding(lastVisibleElement) + lastVisibleElement.offsetHeight) < 100)
        wrapperElement.style.display = "flex"
    setVisible(placeholderElement, false)
}

function isVisible(element: HTMLElement): boolean {
    return element.style.display !== "none"
}

function setVisible(element: HTMLElement, visible: boolean) {
    element.style.display = visible ? "" : "none"
}

function getHeightPlusMargin(element: HTMLElement) {
    var computedStyle = getComputedStyle(element)
    return element.offsetHeight + parseFloat(computedStyle.marginTop)
}

function getHeightForElement(element: HTMLElement, innerHeight: number, miniumLineNumbers: number): number {
    const position = getOffsetTopWithoutParentPadding(element)
    const lineHeight = getLineHeightOrFontSize(element)
    const neededLinesForWholeText = Math.floor(element.offsetHeight / lineHeight)

    const lineToUse = Math.min(neededLinesForWholeText, Math.floor((innerHeight - position) / lineHeight))
    return Math.max(lineToUse, miniumLineNumbers) * lineHeight
}

function getInnerHeight(element: HTMLElement): number {
    var computedStyle = getComputedStyle(element)
    return element.clientHeight - (parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom))
}

function getLineHeightOrFontSize(element: HTMLElement) {
    var computedStyle = getComputedStyle(element)
    var lineHeight = parseFloat(computedStyle.lineHeight)
    if (isNaN(lineHeight)) return parseFloat(computedStyle.fontSize) * 1.2
    return lineHeight
}

function getOffsetTopWithoutParentPadding(element: HTMLElement): number {
    var computedStyleParent = getComputedStyle(element.parentElement!)
    return element.offsetTop - parseFloat(computedStyleParent.paddingTop)
}
