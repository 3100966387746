import { useState, useEffect } from "react"
import styled from "styled-components"
import HallSelect, { HallSelectProps } from "./HallSelect"
import ZoomRangeSlider from "../networkingGraph/components/ZoomRangeSlider"
import hallplan from "./HallplanWrapper"

interface ActionBarProps extends HallSelectProps {}

export default function ActionBar(props: ActionBarProps) {
    const [zoomStrengthValue, setZoomStrengthValue] = useState([1])

    useEffect(() => {
        const changeTargetHandler = () => {
            setZoomStrengthValue([1])
        }
        hallplan.addContentDidChangeTargetHandler(changeTargetHandler)
        return () => {
            hallplan.removeContentDidChangeTargetHandler(changeTargetHandler)
        }
    }, [])

    useEffect(() => {
        hallplan.setZoomScale(zoomStrengthValue[0])
    }, [zoomStrengthValue])

    return (
        <ActionBarRoot>
            <HallSelect {...props} />
            <ZoomRangeSlider min={1} max={5} zoomStrengthValue={zoomStrengthValue} setZoomStrengthValue={setZoomStrengthValue} />
        </ActionBarRoot>
    )
}

const ActionBarRoot = styled.div`
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;
    padding: 10px 20px;
`
