import * as React from "react"
import { Tooltip } from "react-bootstrap"
import branding from "../../branding/branding"
import styled from "styled-components"
import { SimpleOrganization } from "../ExhibitorsPageContent"
import BookmarkWithToggle from "../../ui/BookmarkWithToggle"
import { Entity } from "../../backendServices/Types"
import { ActionType, LayoutType } from "./actionsUtils"
import { TileSize, BasisPremiumConfig } from "../../branding/BasisPremiumBranding"
import { useFavoriteState } from "../../globalStates/Favorites"
import { CustomOverlayTrigger } from "../../ui/CustomOverlayTrigger"

interface ActionRootProps {
    display?: string
    strokeColor?: string
    visibility?: string
}
const ActionRoot = styled.div<ActionRootProps>`
    display: ${(props) => props.display};
    cursor: pointer;
    color: ${(props) => props.strokeColor};
    visibility: ${(props) => props.visibility};

    @media (max-width: 1200px) {
        padding-left: 1rem;
    }
`

interface EntityActionProps {
    tooltip: string
    action: ActionType
    layout: LayoutType
    strokeColor?: string
    icon: any
    organization?: SimpleOrganization
    entity: Entity
    basisPremiumConfig?: BasisPremiumConfig
    onAction(action: ActionType, organization: SimpleOrganization | undefined): void
    hideAction?: boolean
    customBookmarkFunction?: (id: string) => void
}

const EntityAction: React.FC<EntityActionProps> = (props) => {
    const favorites = useFavoriteState()
    const visibility =
        props.hideAction ||
        (props.basisPremiumConfig &&
            props.basisPremiumConfig.tileSize === TileSize.SMALL &&
            !branding.exhibitorsPageContent.iconsVisibleOnSmallTiles)
            ? "hidden"
            : "visible"
    const display =
        props.action === "MEETING"
            ? props.basisPremiumConfig
                ? props.basisPremiumConfig.tileSize === TileSize.SMALL || props.basisPremiumConfig.tileSize === TileSize.QUARTER
                    ? "none"
                    : "block"
                : "block"
            : "block"

    const handleAction = (action: ActionType, organization: SimpleOrganization | undefined, event: any) => {
        event.preventDefault()
        event.stopPropagation()
        props.onAction(action, organization)
    }

    return (
        <CustomOverlayTrigger
            trigger={["focus", "click", "hover"]}
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={
                <Tooltip id="tooltip" style={{ fontFamily: branding.font1 }}>
                    {props.tooltip}
                </Tooltip>
            }
        >
            {props.action === "BOOKMARK" ? (
                <ActionRoot
                    visibility={visibility}
                    display={display}
                    className={props.basisPremiumConfig?.tileSize === "SMALL" ? "mr-0" : "inherit"}
                >
                    <BookmarkWithToggle
                        newBookmarkItem={true}
                        fontSize={"25px"}
                        color={
                            props.layout === "lobby-layout"
                                ? "#FFF"
                                : props.layout === "tile-layout"
                                ? props.strokeColor
                                : branding.exhibitorsPageContent.listLayoutActionsIconColor ??
                                  branding.sideIconBar.sideIconColorDark
                        }
                        stroke={
                            props.layout === "lobby-layout"
                                ? "#FFF"
                                : props.layout === "tile-layout"
                                ? props.strokeColor
                                : undefined
                        }
                        favIconBasic={props.layout === "tile-layout"}
                        type={props.entity.entityType === "networking_user" ? "sotuser" : props.entity.entityType}
                        id={props.entity.id}
                        name={(props.entity as any).name}
                        customFunction={() => {
                            if (props.customBookmarkFunction) {
                                props.customBookmarkFunction(props.entity.id)
                            }

                            if (props.entity.entityType === "networking_user") {
                                const persons = (props.entity as any).persons ?? []

                                persons.forEach((person: any) => {
                                    if (favorites.is("sotuser", props.entity.id) !== favorites.is("person", person.id)) {
                                        favorites.toggle("person", person.id)
                                    }
                                })
                            } else if (props.entity.entityType === "person") {
                                if (
                                    favorites.is(props.entity.entityType, props.entity.id) !==
                                    favorites.is("sotuser", props.entity.id)
                                ) {
                                    favorites.toggle("sotuser", props.entity.id)
                                }
                                if (
                                    favorites.is(props.entity.entityType, props.entity.id) !==
                                    favorites.is("person", (props.entity as any).userId)
                                ) {
                                    favorites.toggle("person", (props.entity as any).userId)
                                }

                                if (
                                    favorites.is(props.entity.entityType, props.entity.id) !==
                                    favorites.is("sotuser", (props.entity as any).userId)
                                ) {
                                    favorites.toggle("sotuser", (props.entity as any).userId)
                                }
                            }
                        }}
                    />
                </ActionRoot>
            ) : (
                <ActionRoot
                    className={props.basisPremiumConfig?.tileSize === "SMALL" ? "mr-0" : "mr-4"}
                    visibility={visibility}
                    display={display}
                    onClick={(e) => handleAction(props.action, props.organization!, e)}
                >
                    {props.icon}
                </ActionRoot>
            )}
        </CustomOverlayTrigger>
    )
}

export default EntityAction
