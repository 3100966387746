import styled from "styled-components"
import * as React from "react"
import { Button } from "react-bootstrap"
import branding from "../../branding/branding"
import { useLanguageState } from "../../globalStates/LanguageState"

const ErrorContentPanel = styled.div`
    min-width: 200px;
    max-width: 300px;
    font-family: ${branding.font1};
`

const ErrorImagePanel = styled.div`
    width: 100%;
    height: 300px;
    background: url("/branding/emptyresult.jpg");
    background-size: cover;
`

const TitlePanel = styled.div`
    font-size: 20px;
    font-weight: bold;
    text-align: center;
`

const DescriptionPanel = styled.div`
    font-size: 14px;
    margin-top: 10px;
    text-align: center;
`

const ReloadButton = styled(Button)`
    margin-top: 45px;
    width: 100%;
    max-width: 300px;
    height: 40px;
    border-radius: 5px;
    background: linear-gradient(
        90deg,
        ${branding.loginRegistrationSite.errorSiteButtonColor1} 0%,
        ${branding.loginRegistrationSite.errorSiteButtonColor2} 100%
    );
    border: none;
    font-family: ${branding.font2};
    font-size: 16px;
    letter-spacing: 0.3em;

    &:active {
        background: linear-gradient(
            90deg,
            ${branding.loginRegistrationSite.errorSiteButtonColor1} 0%,
            ${branding.loginRegistrationSite.errorSiteButtonColor2} 100%
        );
    }
`

interface ErrorSiteProps {}

const ErrorSite: React.FC<ErrorSiteProps> = (props) => {
    const languageState = useLanguageState()
    const strings = languageState.getStrings()

    function onReloadClicked() {
        window.location.reload()
    }
    return (
        <ErrorContentPanel>
            <ErrorImagePanel></ErrorImagePanel>
            <TitlePanel>{strings.errorSite.errorTitle}</TitlePanel>
            <DescriptionPanel>{strings.errorSite.errorDescription}</DescriptionPanel>
            <ReloadButton variant="primary" onClick={onReloadClicked}>
                {strings.errorSite.reloadButton}
            </ReloadButton>
        </ErrorContentPanel>
    )
}

export default ErrorSite
