import * as React from "react"
import { useState, createState, State } from "@hookstate/core"
import { Link } from 'react-router-dom'
import * as H from 'history';
import { UserOrganizationVisitSource } from '../backendServices/BackendServices';


interface NavigationSourceState {
    src: UserOrganizationVisitSource
    searchKrit?: string
}

const useWrapState = ((state: State<NavigationSourceState>) => {
    return {
        get: () => {
            return state.get().src
        },
        getKrit: () => {
            return state.get().searchKrit
        },
        set: (src: UserOrganizationVisitSource) => {
            state.merge({ src: src })
        },
        setKrit: (searchKrit?: string) => {
            state.merge({ searchKrit: searchKrit })
        },
    }
})
const state = createState({ src: 'UNKNOWN' } as NavigationSourceState)
export const useNavigationSource = () => useWrapState(useState(state))

interface TrackingLinkProps {
    src: UserOrganizationVisitSource
    to: H.LocationDescriptor<H.LocationState> | ((location: H.Location<H.LocationState>) => H.LocationDescriptor<H.LocationState>)
    doClick?: Function
    style?: React.CSSProperties
    children?: React.ReactNode
}

const TrackingLink: React.FC<TrackingLinkProps> = ({ src, to, doClick, style, children }) => {
    const navSrc = useNavigationSource()

    const onClick = (e: any) => {
        navSrc.set(src)
        if (doClick) {
            doClick(e)
        }
    }
    return (
        < Link onClick={onClick} to={to} style={style} children={children} />
    )
}

export default TrackingLink
