import moment from "moment"
import queryString from "query-string"
import * as React from "react"
import { Button, Modal } from "react-bootstrap"
import styled from "styled-components"
import {
    BackendServiceError,
    CalendarEntry,
    doConnectAction,
    findChatConversation,
    MakeConnectionResponse,
    setMuteStatus,
    syncFavorites
} from "../backendServices/BackendServices"
import { ModalType, ShareTargetType } from "../backendServices/Types"
import branding from "../branding/branding"
import { MeetingStatusCode } from "../conference/context/ChimeContext"
import { useMeetingContext } from "../conference/context/MeetingContext"
import { buildDetailLink } from "../contentArea/detailPages/DetailNavLink"
import { useAppState } from "../globalStates/AppState"
import { getIamPartOf } from "../globalStates/IAmPartOf"
import { useLanguageState } from "../globalStates/LanguageState"
import { User } from "../globalStates/LoggedInUser"
import CalendarEntryModal, { CalendarEntryModalViewMode } from "../ui/CalendarEntryModal"
import GuestModal from "../ui/GuestModal"
import {
    IconBlockContact,
    IconBookmarkButton,
    IconBookmarkButtonFilled,
    IconCalendarEntry,
    IconCamera,
    IconChat,
    IconClose,
    IconConnect,
    IconConnectWithdraw,
    IconDecline,
    IconDownloadVCard,
    IconRecommend,
    IconRemoveStaff,
    IconReport,
    VGIcon
} from "../ui/Icons"
import RecommendModal from "../ui/RecommendModal"
import ReportModal from "../ui/ReportModal"
import SayHelloModal from "../ui/SayHelloModal"
import { hasAccessToUser } from "../utils/Authorization"
import { isExplorationOrPostEventPhase, isPostEventPhase } from "../utils/EventPhaseChecker"
import { setInitialStates } from "../utils/SayHello"
import { ChatConversationParam } from "./ChatPage"
import { useContactState } from "./ContactState"
import { saveVCard } from "./VCard"

const MainModal = styled(Modal)`
    .modal-content {
        padding: 10px;
        border-radius: 5px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    }
    .modal-header {
        /* display: inline-flex;
        border-bottom: none; */
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        border: none;
        padding: 20px 0 20px 0;
    }

    .modal-body {
        font-family: ${branding.font1};
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        display: flex;
        padding: 10px 20px;
        white-space: pre-line;
        word-break: break-word;
    }

    .modal-footer {
        justify-content: space-between;
        border: none;
    }

    .close {
        outline: 0;
        position: absolute;
        right: 20px;
        top: 15px;
        font-size: 46px;
        font-weight: 10;
        line-height: 1.66666667;
        color: #000;
        width: 30px;
        padding: 0 2px;
        background: none;
        border-style: none;
        margin-right: 1px;
        margin-top: -16px;
    }

    .btn-primary {
        display: inline-block;
        border: none;
        font-family: ${branding.font1};
        :focus {
            outline: none;
            cursor: pointer;
        }
    }

    .btn-secondary {
        display: inline-block;
        border: none;
        font-family: ${branding.font1};
        :focus {
            outline: none;
            cursor: pointer;
        }
    }
`
// const TitleImage = styled.div`
//     font-size: 30px;
// `

const Title = styled.div`
    width: auto;
    height: 20px;
    margin-left: 20px;
    margin-top: 15px;
    font-family: ${branding.font1};
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
`

const CancelButton = styled.div`
    display: block;
    width: 28%;
    height: 30px;
    color: ${branding.recommendModal.cancelBtnPrimaryTextColor ?? "#000"};
    background-color: transparent;
    border: 1px solid ${branding.recommendModal.cancelBtnPrimaryBorderColor}!important;
    border-radius: 5px;
    font-size: 12px;
    line-height: 17px;
    font-family: ${branding.font1};
    margin-right: 15px;

    :hover {
        background-color: ${branding.recommendModal.submitBtnPrimaryOnHoverBgColor} !important;
        color: ${branding.recommendModal.submitBtnPrimaryOnHoverTextColor} !important;
    }

    :focus {
        cursor: pointer;
        outline: none;
        border: none;
    }
`
const ConfirmButton = styled(Button)`
    display: block;
    width: 28%;
    height: 30px;
    color: ${branding.recommendModal.submitBtnPrimaryTextColor} !important;
    background-color: ${branding.recommendModal.submitBtnPrimaryBgColor}!important;
    border: 1px solid ${branding.recommendModal.submitBtnPrimaryBorderColor}!important;
    border-radius: 5px;
    font-size: 12px;
    line-height: 17px;
    font-family: ${branding.font1};

    :hover {
        background-color: ${branding.recommendModal.submitBtnPrimaryOnHoverBgColor} !important;
        color: ${branding.recommendModal.submitBtnPrimaryOnHoverTextColor} !important;
    }
`
const CloseButton = styled.div`
    position: absolute;
    right: 30px;
    top: 30px;
    cursor: pointer;
    svg {
        color: ${branding.mainInfoColor};
    }
`
const SubmitButtonContainer = styled.div`
    width: 100%;
    justify-content: flex-end;
    display: inline-flex;
    margin-bottom: 20px;
`
// const ConfirmButton = styled.div`
//     width: 40%;
//     height: 30px;
//     cursor: pointer;
//     font-size: 12px;
//     border-radius: 20px;
//     font-size: 12px;
//     font-family: ${branding.font1};
//     color: ${branding.recommendModal.submitBtnPrimaryTextColor} !important;
//     background-color: ${branding.recommendModal.submitBtnPrimaryBgColor}!important;
//     :hover {
//         background-color: ${branding.recommendModal.submitBtnPrimaryOnHoverBgColor} !important;
//         color: ${branding.recommendModal.submitBtnPrimaryOnHoverTextColor} !important;
//     }
// `

function getSayHelloTexts(status: string, strings: any) {
    const conn = setInitialStates(status)
    switch (conn.messageType) {
        case "cancelRequestMessage":
            return {
                hint: strings.contactEntry.cancelRequestMessage,
                title: strings.contactEntry.cancelRequestTitle,
                icon: IconConnectWithdraw({ fill: branding.sideIconBar.sideIconColorDark })
            }
        case "cancelConnectionMessage":
            return {
                hint: strings.contactEntry.cancelConnectionMessage,
                title: strings.contactEntry.disconnectTitle,
                icon: IconRemoveStaff({ fill: branding.sideIconBar.sideIconColorDark })
            }
        case "ignoredMessage":
            return {
                hint: strings.contactEntry.ignoredMessage,
                title: strings.contactEntry.connectTitle,
                icon: IconConnect({ fill: branding.sideIconBar.sideIconColorDark })
            }
        case "blockedMessage":
            return {
                hint: strings.contactEntry.ignoredMessage,
                title: strings.contactEntry.connectTitle,
                icon: IconConnect({ fill: branding.sideIconBar.sideIconColorDark })
            }
        case "openRequestMessage":
            return {
                hint: strings.contactEntry.openRequestMessageHint,
                title: strings.contactEntry.openRequestMessage,
                icon: IconConnect({ fill: branding.sideIconBar.sideIconColorDark })
            }
        case "sendRequestMessage":
            return {
                hint: strings.contactEntry.sendRequestMessage,
                title: strings.contactEntry.connectTitle,
                icon: IconConnect({ fill: branding.sideIconBar.sideIconColorDark })
            }
        default:
            return { title: "test", hint: "" }
    }
}

function doVCard(person: any) {
    saveVCard([person])
}

export async function updateBlocked(loggedInUserId: any | undefined, person: any, contactState: any) {
    const isBlocking = contactState.getConnectionStatus(person.id) === "BLOCKING"
    const response = await doConnectAction({
        profileId: loggedInUserId!,
        targetProfileId: person.id,
        action: isBlocking ? "cancel" : "block",
        message: null
    })

    let conversation = await findChatConversation(loggedInUserId, person.id)
    if (conversation) {
        setMuteStatus(conversation.userConversationId, !isBlocking)
    }

    if ((response as BackendServiceError).httpStatus) {
        // TODO ERROR
    } else {
        const newStatus = (response as MakeConnectionResponse).content.profile.myConnectionStatus
        person.myConnectionStatus = newStatus
        contactState.setConnectionStatus(person.id, newStatus)
    }
}

export const doCall = (personId: string, meeting: any, chime: any, callback: (param: { modalType: ModalType }) => void) => {
    const meetingParam = meeting.getCurrentMeetingParam()
    if (meetingParam) {
        meeting.sendInvite(personId, meetingParam)
    } else if (chime.getMeetingStatus().meetingStatus === MeetingStatusCode.Succeeded) callback({ modalType: "call" })
    else meeting.sendInvite(personId)
}

const doChat = (personId: string, appState: any) => {
    appState.setShowChatsTab(ChatConversationParam.privateConversationByOpponentId(personId))
}

const updateBookmarkedStatus = (
    loggedInUserId: any | undefined,
    person: any,
    favorites: any,
    date: Date,
    callback: (param: { bookmarkChanged: boolean }) => void,
    sotUser?: boolean
) => {
    const userType = sotUser ? "sotuser" : "person"

    function syncFav(id: string, type: string) {
        syncFavorites({
            profileId: loggedInUserId,
            body: {
                currentTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                lastSyncTime: favorites.getLastSyncTime(),
                changedFavorites: [
                    {
                        id: id,
                        kind: type.toUpperCase(),
                        deleted: favorites.is(type, id) ? false : true,
                        lastModified: moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
                    }
                ]
            }
        })
            .then((resp) => {
                favorites.setLastSyncTime(new Date())
                callback({ bookmarkChanged: true })
            })
            .catch((e: { message: React.SetStateAction<string> }) => {
                // syncFavorites failed, logged in BackendServices
            })
    }

    if (loggedInUserId) {
        if (person.person && person.type === "speaker") {
            favorites.toggle("person", person.person)
        }

        favorites.toggle(userType, person.id)

        if (userType === "sotuser") {
            if (favorites.is(userType, person.id) !== favorites.is("person", person.id)) {
                favorites.toggle("person", person.id)
            }
            if (favorites.is(userType, person.id) !== favorites.is("person", person.person)) {
                favorites.toggle("person", person.person)
            }
        } else if (userType === "person") {
            if (favorites.is(userType, person.id) !== favorites.is("sotuser", person.id)) {
                favorites.toggle("sotuser", person.id)
            }
            if (favorites.is(userType, person.id) !== favorites.is("person", person.person)) {
                favorites.toggle("person", person.person)
            }
        }

        syncFav(person.id, "person")
        syncFav(person.id, "sotuser")
        syncFav(person.person, "person")
    }
}

export interface ConfirmCallProps {
    show: boolean
    onHide: () => void
    sotUserId: string
}

export const ConfirmCall: React.FC<ConfirmCallProps> = ({ show, onHide, sotUserId }) => {
    const strings = useLanguageState().getStrings()
    const meeting = useMeetingContext()
    const appState = useAppState()

    return (
        <MainModal show={show} onHide={onHide} backdrop="static" centered animation={false}>
            <Modal.Header>
                <Modal.Title style={{ display: "inline-flex" }}>
                    <Title>{strings.conferenceTexts.changingRoomConfirmationTitle}</Title>
                </Modal.Title>
                <CloseButton onClick={onHide}>
                    {IconClose({ fill: branding.recommendModal.closeIconColor, width: "15", height: "15" })}
                </CloseButton>
            </Modal.Header>
            <Modal.Body>{strings.conferenceTexts.changingRoomConfirmationText}</Modal.Body>
            <Modal.Footer>
                <SubmitButtonContainer>
                    <CancelButton onClick={onHide} className="d-flex align-items-center justify-content-center">
                        {strings.globalTexts.cancel}
                    </CancelButton>
                    <ConfirmButton
                        type="submit"
                        onClick={() => {
                            appState.setIsMyHandRaised(false)
                            meeting.sendInvite(sotUserId)
                            onHide()
                        }}
                        className="d-flex align-items-center justify-content-center"
                    >
                        {strings.conferenceTexts.changingRoomConfirmationAccept ?? strings.globalTexts.confirm}
                    </ConfirmButton>
                </SubmitButtonContainer>
            </Modal.Footer>
        </MainModal>
    )
}

export default ConfirmCall

export interface Action {
    disabled: boolean
    title: string
    hint: string
    icon: VGIcon
    onClick: () => void
}

export const chatAction = (
    connectionStatus: string,
    strings: any,
    personId: string,
    appState: any,
    hideChat: boolean,
    disableUserButtons?: boolean,
    accessToUserGranted?: boolean,
    enableGuestModal?: Function
) => {
    const userButtonsDisabled = disableUserButtons !== undefined ? disableUserButtons : false
    return {
        disabled: userButtonsDisabled || connectionStatus === "BLOCKED" || connectionStatus === "BLOCKING",
        title: strings.contactEntry.sendMessageTitle,
        hint: strings.contactEntry.sendMessageHint,
        icon: IconChat({ fill: branding.sideIconBar.sideIconColorDark }),
        onClick: () => {
            if (accessToUserGranted) {
                doChat(personId, appState)
            } else {
                if (enableGuestModal) {
                    enableGuestModal()
                }
            }
        },
        hideOnMobile: true,
        hideOnDesktop: hideChat
    }
}

export const callAction = (
    connectionStatus: string,
    strings: any,
    personId: string,
    meeting: any,
    chime: any,
    callback: (param: { bookmarkChanged?: boolean; modalType?: ModalType }) => void,
    disableUserButtons?: boolean,
    accessToUserGranted?: boolean,
    enableGuestModal?: Function
) => {
    let userIsInMeeting = false
    const queryParams: any = queryString.parse(window.location.search)
    const userButtonsDisabled = disableUserButtons !== undefined ? disableUserButtons : false
    // Only calculate if the Menu is open,
    const roster = chime.getRoster()
    for (let rosterKey in roster) {
        if (roster[rosterKey].id === personId) {
            userIsInMeeting = true
            break
        }
    }
    return {
        disabled:
            (isExplorationOrPostEventPhase && !(getIamPartOf(queryParams) === "onboarding")) ||
            userButtonsDisabled ||
            connectionStatus === "BLOCKED" ||
            connectionStatus === "BLOCKING" ||
            branding.contactEntry.disableVideoChatIcon ||
            (meeting.getCurrentMeetingParam() !== undefined && (chime.hasMaxAttendees() || userIsInMeeting)),
        title: strings.contactEntry.startCallTitle,
        hint: meeting.getCurrentMeetingParam() ? strings.contactEntry.inviteToCallHint : strings.contactEntry.startCallHint,
        icon: IconCamera({ fill: branding.sideIconBar.sideIconColorDark, width: "23", height: "23" }),
        onClick: () => {
            if (accessToUserGranted) {
                doCall(personId, meeting, chime, callback)
            } else {
                if (enableGuestModal) {
                    enableGuestModal()
                }
            }
        },
        hideOnMobile: true
    }
}

export const declineAction = (declineMeeting: () => void, strings: any) => {
    return {
        disabled: false,
        title: strings.calendarEntryListView.declineButtonTitle,
        hint: strings.calendarEntryListView.declineButtonTitle,
        icon: IconClose({ fill: branding.sideIconBar.sideIconColorDark }),
        onClick: declineMeeting
    }
}

export const createActions = (
    loggedInUser: User | undefined,
    person: any,
    favorites: any,
    contactState: any,
    appState: any,
    meeting: any,
    chime: any,
    strings: any,
    isBookmarked: boolean,
    connectionStatus: string,
    userType: string,
    callback: (param: { bookmarkChanged?: boolean; modalType?: ModalType }) => void,
    additionalActions?: Action[],
    disableUserButtons?: boolean,
    sotUser?: boolean,
    hideChat?: boolean
) => {
    const isBlocked = connectionStatus === "BLOCKED"
    const isBlocking = connectionStatus === "BLOCKING"
    const isConnected = connectionStatus === "CONNECTED"
    const date = new Date()
    const sayHello = getSayHelloTexts(connectionStatus, strings)

    const accessToUserGranted = hasAccessToUser(loggedInUser, person)

    function enableGuestModal() {
        callback({ modalType: "guest" })
    }

    const userButtonsDisabled = disableUserButtons !== undefined ? disableUserButtons : false

    const networkingFunctionsDisabled = !loggedInUser?.matchActive && connectionStatus === "UNRELATED"

    let result = [
        {
            disabled: isBlocked || isBlocking || branding.contactEntry.disableBookmarkIcon,
            title: isBookmarked ? strings.contactEntry.unbookmarkTitle : strings.contactEntry.bookmarkTitle,
            hint: isBookmarked ? strings.contactEntry.unbookmarkHint : strings.contactEntry.bookmarkHint,
            icon: isBookmarked
                ? IconBookmarkButtonFilled({ fill: branding.sideIconBar.sideIconColorDark })
                : IconBookmarkButton({ fill: branding.sideIconBar.sideIconColorDark }),
            onClick: () => {
                if (accessToUserGranted) {
                    updateBookmarkedStatus(loggedInUser?.profileId, person, favorites, date, callback, sotUser)
                } else {
                    enableGuestModal()
                }
            },
            hideOnMobile: false
        },
        chatAction(
            connectionStatus,
            strings,
            person.id,
            appState,
            hideChat || false,
            userButtonsDisabled || networkingFunctionsDisabled,
            accessToUserGranted,
            enableGuestModal
        ),
        callAction(
            connectionStatus,
            strings,
            person.id,
            meeting,
            chime,
            callback,
            userButtonsDisabled || networkingFunctionsDisabled,
            accessToUserGranted,
            enableGuestModal
        ),
        {
            disabled:
                userButtonsDisabled ||
                isBlocked ||
                isBlocking ||
                isPostEventPhase ||
                networkingFunctionsDisabled ||
                branding.contactEntry.disableMeetingIcon,
            title: strings.contactEntry.requestMeetingTitle,
            hint: strings.contactEntry.requestMeetingHint,
            icon: IconCalendarEntry({ fill: branding.sideIconBar.sideIconColorDark }),
            onClick: () => {
                if (accessToUserGranted) {
                    callback({ modalType: "meeting" })
                } else {
                    enableGuestModal()
                }
            },
            hideOnMobile: true
        },
        {
            disabled:
                userButtonsDisabled ||
                isBlocked ||
                isBlocking ||
                networkingFunctionsDisabled ||
                branding.contactEntry.disableConnectIcon,
            title: sayHello.title,
            hint: sayHello.hint,
            icon: sayHello.icon,
            onClick: () => {
                if (accessToUserGranted) {
                    callback({ modalType: "connect" })
                } else {
                    enableGuestModal()
                }
            },
            hideOnMobile: true
        },
        {
            disabled: isBlocked || isBlocking || branding.contactEntry.disableShareIcon,
            title: strings.contactEntry.shareTitle,
            hint: strings.contactEntry.shareHint,
            icon: IconRecommend({ fill: branding.sideIconBar.sideIconColorDark }),
            onClick: () => {
                callback({ modalType: "share" })
            },
            hideOnMobile: false
        },
        {
            disabled: userButtonsDisabled || isBlocked || networkingFunctionsDisabled || branding.contactEntry.disableBlockIcon,
            title: isBlocking ? strings.contactEntry.unblockPersonTitle : strings.contactEntry.blockPersonTitle,
            hint: isBlocking ? strings.contactEntry.unblockPersonHint : strings.contactEntry.blockPersonHint,
            icon: isBlocking
                ? IconDecline({ fill: branding.sideIconBar.sideIconColorDark })
                : IconBlockContact({ fill: branding.sideIconBar.sideIconColorDark }),
            onClick: () => {
                if (accessToUserGranted) {
                    updateBlocked(loggedInUser?.profileId, person, contactState)
                } else {
                    enableGuestModal()
                }
            },
            hideOnMobile: false
        },
        {
            disabled: !isConnected || networkingFunctionsDisabled || branding.contactEntry.disableDownloadIcon,
            title: strings.contactEntry.downloadVCardTitle,
            hint: strings.contactEntry.downloadVCardHint,
            icon: IconDownloadVCard({ fill: branding.sideIconBar.sideIconColorDark }),
            onClick: () => {
                if (accessToUserGranted) {
                    doVCard(person)
                } else {
                    enableGuestModal()
                }
            },
            hideOnMobile: true
        },
        {
            disabled: isBlocking || networkingFunctionsDisabled || branding.contactEntry.disableReportIcon,
            title: strings.communicationArea.reportText,
            hint: strings.communicationArea.reportHint,
            icon: IconReport({ fill: branding.sideIconBar.sideIconColorDark }),
            onClick: () => {
                if (accessToUserGranted) {
                    callback({ modalType: "report" })
                } else {
                    enableGuestModal()
                }
            }
        }
    ]
    if (additionalActions) {
        result = result.concat(additionalActions)
    }
    return result
}
export interface CommunicationModalsProps {
    show: ModalType
    contact: any
    calendarEntry?: CalendarEntry
    onHide: () => void
    onWithdrawRequest?: (contactId: string) => void
    removePosition?: boolean // remove absolute position when say hello is called inside meeting
}

export const CommunicationModals: React.FC<CommunicationModalsProps> = ({
    contact,
    show,
    calendarEntry,
    onHide,
    onWithdrawRequest,
    removePosition
}) => {
    const contactState = useContactState()

    switch (show) {
        case "connect":
            return (
                <SayHelloModal
                    targetId={contact.id}
                    handleClose={onHide}
                    removePosition={removePosition}
                    setSayHelloResponseStatus={(connectStatus) => {
                        if (connectStatus === "UNRELATED" && onWithdrawRequest) {
                            onWithdrawRequest(contact.id)
                        }
                        contactState.setConnectionStatus(contact.id, connectStatus)
                    }}
                />
            )
        case "meeting":
            return (
                <CalendarEntryModal
                    calendarEntry={calendarEntry}
                    viewMode={calendarEntry ? CalendarEntryModalViewMode.VIEW : CalendarEntryModalViewMode.CREATE}
                    sotUser={contact ? [contact!] : undefined}
                    close={onHide}
                />
            )
        case "call":
            return <ConfirmCall show={true} onHide={onHide} sotUserId={contact.id} />
        case "share": {
            const link = buildDetailLink(
                contact.person ?? contact.id,
                `/person/${contact.firstName}_${contact.lastName}`,
                contact.person || contact.eventDates ? "person" : "user"
            )

            return (
                <RecommendModal
                    targetId={contact.id}
                    type={ShareTargetType.SOTUSER}
                    link={`https://${window.location.hostname}` + link}
                    sotUser={[]}
                    close={onHide}
                ></RecommendModal>
            )
        }
        case "report": {
            return <ReportModal show={true} onHide={onHide} userToReportId={contact.id} />
        }
        case "guest": {
            return <GuestModal close={onHide} />
        }
    }
    return null
}
