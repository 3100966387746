import { SyntheticEvent, useEffect, useRef, useState } from "react"
import * as React from "react"
import { useAppState } from "../../globalStates/AppState"
import {
    loadChannelFirstEventDate,
    BackendServiceError,
    EventDateChannelFirstDetailsResponse
} from "../../backendServices/BackendServices"
import moment from "moment"
import { useUserRestrictedAreaAccess } from "../../globalStates/UserRestrictedAreaAccess"
import PictureInPictureVideoPlayerReal from "./PictureInPictureVideoPlayerReal"
import PictureInPictureVideoPlayerDummy from "./PictureInPictureVideoPlayerDummy"
import { EventDate } from "../../backendServices/Types"
import styled from "styled-components"
import CenteredLoader from "../../ui/CenteredLoader"
import { useRouteMatch } from "react-router-dom"
import { videoRoomPageRoute } from "../../navigationArea/RoutePaths"
import { Resizable, ResizeCallbackData } from "react-resizable"
import Draggable, { DraggableData, DraggableEvent, DraggableEventHandler } from "react-draggable"
import { IconClose } from "../../ui/Icons"
import { useLanguageState } from "../../globalStates/LanguageState"
import { MeetingStatusCode, useChimeContext } from "../../conference/context/ChimeContext"
import { accessPresenceState, EventType } from "../../ui/PresenceIndicator"

/* parent component that switches between PictureInPictureVideoPlayerReal and -Dummy depending on user access to the eventDate,
 * it can look different depending on the following states: locked-ticket, locked-backoffice, unlocked, loading, error
 */

function calculateChange(currentEventEndDate: string, nextEventStartDate: string) {
    return moment(nextEventStartDate, "YYYY-MM-DD HH:mm").diff(moment(currentEventEndDate, "YYYY-MM-DD HH:mm")) / 2
}

function calculateEnd(currentEventEndDate: string) {
    const format = "YYYY-MM-DD HH:mm"
    return moment(moment(currentEventEndDate).format(format)).diff(moment().format(format))
}

export interface PipPlayerSize {
    width: number
    height: number
}
export interface PipPlayerPosition {
    x: number
    y: number
}

export interface ResizeState {
    width: number
    height: number
    relativeLeft?: number | any
    relativeTop?: number | any
}

const PictureInPictureVideoPlayer: React.FC = () => {
    const appState = useAppState()
    const userAccessState = useUserRestrictedAreaAccess()
    const [currentEventDate, setCurrentEventDate] = useState<EventDate | undefined>()
    const channel = appState.liveStreamChannel
    const userControlsEnabled = true
    const isVideoPage = useRouteMatch(videoRoomPageRoute)
    const chime = useChimeContext()
    const strings = useLanguageState().getStrings()
    const [errorOccured, setErrorOccured] = useState(false)
    const realPlayerRef = useRef(null)
    const initialResizeState: ResizeState = {
        width: 426,
        height: 240,
        relativeLeft: 0,
        relativeTop: 0
    }
    const [resizeState, setResizeState] = useState<ResizeState>(initialResizeState)
    const [deltaSize, setDeltaSize] = useState<PipPlayerPosition>({ x: 0, y: 0 })
    const initialPosition: PipPlayerPosition = {
        x: window.innerWidth - resizeState.width - 40,
        y: window.innerHeight - resizeState.height - 48
    }

    const [pipPlayerPosition, setPipPlayerPosition] = useState<PipPlayerPosition>(initialPosition)

    useEffect(() => {
        function loadData() {
            if (!isVideoPage && appState.liveStreamChannel) {
                loadChannelFirstEventDate(appState.liveStreamChannel?.id).then((data) => {
                    if ((data as BackendServiceError).httpStatus) {
                    } else {
                        const resp = data as EventDateChannelFirstDetailsResponse
                        appState.setLiveStreamChannel(
                            Object.assign({}, appState.liveStreamChannel, { eventDate: resp.currentEventDate })
                        )

                        const currentEventDateTemp = resp.currentEventDate
                        setCurrentEventDate(currentEventDateTemp)

                        if (resp.currentEventDate && !resp.nextEventDate) {
                            const remainingCurrentEventDate = calculateEnd(resp.currentEventDate?.enddatetime)
                            if (remainingCurrentEventDate > 0) {
                                remainingCurrentEventDateTimer = window.setTimeout(() => {
                                    loadData()
                                }, remainingCurrentEventDate)
                            }
                        } else if (resp.currentEventDate && resp.nextEventDate) {
                            const currentEventDateEndTime = resp.currentEventDate.enddatetime
                            const remainingCurrentEventDate = calculateEnd(currentEventDateEndTime)
                            if (remainingCurrentEventDate > 0) {
                                remainingCurrentEventDateTimer = window.setTimeout(() => {
                                    // Trigger on event end
                                    // reload data at halfway mark between end of current and beginning of next event
                                    const changeExecution = calculateChange(
                                        currentEventDateEndTime,
                                        resp.nextEventDate?.startdatetime!
                                    )
                                    changeExecutionTimer = window.setTimeout(() => {
                                        loadData()
                                    }, changeExecution)
                                }, remainingCurrentEventDate)
                            }
                        }
                    }
                })
            }
        }

        let remainingCurrentEventDateTimer: number
        let changeExecutionTimer: number

        loadData()
        return () => {
            clearTimeout(remainingCurrentEventDateTimer)
            clearTimeout(changeExecutionTimer)
        }
        // eslint-disable-next-line
    }, [appState.liveStreamChannel?.url, isVideoPage])

    useEffect(() => {
        if (currentEventDate && !userAccessState.isLoaded(currentEventDate))
            userAccessState.fetchUserAccessForSingleRestrictedArea(currentEventDate)
    }, [currentEventDate]) // eslint-disable-line

    useEffect(() => {
        appState.setPipPlayerActive(realPlayerRef != null && realPlayerRef?.current !== null)
        // eslint-disable-next-line
    }, [realPlayerRef])

    let startClickX = 0
    let startClickY = 0
    let resizing = false

    const handleDragStart: DraggableEventHandler = (e: DraggableEvent, data: DraggableData) => {
        if (resizing) {
            return false
        }
        if ((e as any).hasOwnProperty("nativeEvent")) {
            e = (e as any).nativeEvent
        }

        if (e instanceof MouseEvent) {
            const me = e as MouseEvent
            startClickX = me.screenX
            startClickY = me.screenY
        }
        e.stopPropagation()
        e.preventDefault()
    }

    const handleDragStop: DraggableEventHandler = (e: DraggableEvent, data: DraggableData) => {
        if (e instanceof MouseEvent) {
            const me = e as MouseEvent
            // only handle click if not dragging, no error occured and close button was not event target
            if (startClickX === me.screenX && startClickY === me.screenY) {
                if (!errorOccured) {
                    if (me.target) {
                        const target = me.target as any
                        if (target.tagName.toLowerCase() !== "path") {
                            handleClick()
                        }
                    }
                }
            } else {
                // save size and position of player for rerenders
                setPipPlayerPosition({
                    x: data.x + deltaSize.x, // account for change in size from resizing
                    y: data.y + deltaSize.y
                })
            }
        }
        e.stopPropagation()
    }

    const handleResizeStart = (event: SyntheticEvent) => {
        resizing = true
        event.stopPropagation()
        event.preventDefault()
    }

    const handleResize = (e: SyntheticEvent<Element, Event>, data: ResizeCallbackData) => {
        setResizeState((prevState: ResizeState) => {
            let newLeft = prevState.relativeLeft
            let newTop = prevState.relativeTop
            const deltaHeight = data.size.height - prevState.height
            const deltaWidth = data.size.width - prevState.width

            if (newLeft === undefined) {
                newLeft = 0
            }
            if (newTop === undefined) {
                newTop = 0
            }

            if (data.handle[0] === "n") {
                newTop -= deltaHeight
            } else if (data.handle[0] === "s") {
            }
            if (data.handle[data.handle.length - 1] === "w") {
                newLeft -= deltaWidth
            } else if (data.handle[data.handle.length - 1] === "e") {
            }

            const newResizeState: ResizeState = {
                width: data.size.width,
                height: data.size.height,
                relativeLeft: newLeft,
                relativeTop: newTop
            }
            return newResizeState
        })
    }

    const handleResizeStop = (event: SyntheticEvent, data: ResizeCallbackData) => {
        // save position of player for rerenders
        const deltaX = -(deltaSize.x - resizeState.relativeLeft)
        const deltaY = -(deltaSize.y - resizeState.relativeTop)
        setPipPlayerPosition({ x: pipPlayerPosition.x + deltaX, y: pipPlayerPosition.y + deltaY })
        setDeltaSize({ x: resizeState.relativeLeft, y: resizeState.relativeTop })
        resizing = false
        event.stopPropagation()
        event.preventDefault()
    }

    function closePlayer() {
        if (chime.getMeetingStatus().meetingStatus !== MeetingStatusCode.Succeeded) {
            accessPresenceState.updateMyPresence(EventType.EVENT_END)
        }
        appState.setLiveStreamChannel(null)
    }

    const handleClick = () => {
        if (null !== realPlayerRef.current) {
            const player = realPlayerRef.current as any
            player.handleClick()
        }
    }

    let content = null
    if (!channel || isVideoPage || !currentEventDate) {
        return null
    } else if (!userAccessState.isLoaded(currentEventDate)) {
        content = (
            // position Player in bottom right corner in vertical alignment with the conference overlay or where user placed it
            <LoadingPlayerRoot
                positionY={pipPlayerPosition.y + "px"}
                positionX={pipPlayerPosition.x + "px"}
                width={resizeState.width + "px"}
                height={resizeState.height + "px"}
            >
                <CenteredLoader />
            </LoadingPlayerRoot>
        )
    } else {
        content = (
            <>
                {
                    // if event is not private or user has access,
                    // we can display real PictureInPicture Video Player
                    // otherwise, we will display dummy PictureInPicture Video Player
                    userAccessState.isUnlocked(currentEventDate) ? (
                        <PictureInPictureVideoPlayerReal
                            userControlsEnabled={userControlsEnabled}
                            resizeState={resizeState}
                            errorOccured={errorOccured}
                            setErrorOccured={setErrorOccured}
                            ref={realPlayerRef}
                        />
                    ) : (
                        <PictureInPictureVideoPlayerDummy
                            resizeState={resizeState}
                            accessProvider={currentEventDate.accessProvider}
                        />
                    )
                }
            </>
        )
    }
    return (
        <>
            <Draggable
                // position Player in bottom right corner in vertical alignment with the conference overlay or where user placed it
                defaultPosition={{ x: pipPlayerPosition.x - deltaSize.x, y: pipPlayerPosition.y - deltaSize.y }} // account for change in size from resizing
                onStart={handleDragStart}
                onStop={handleDragStop}
            >
                <Resizable
                    onResizeStart={handleResizeStart}
                    onResize={handleResize}
                    onResizeStop={handleResizeStop}
                    width={resizeState.width}
                    height={resizeState.height}
                    className="box absolutely-positioned"
                    resizeHandles={["sw", "se", "nw", "ne"]} // handles in all for corners
                    // resizeHandles={['sw', 'se', 'nw', 'ne', 's', 'e', 'w', 'n']}     // handles in four corners and on four edges
                    axis="both"
                    handleSize={[8, 8]}
                    lockAspectRatio={true}
                    minConstraints={[240, 135]}
                    maxConstraints={[2400, 1350]}
                >
                    <ResizableRoot
                        className={"picture-in-picture-player"}
                        style={{
                            top: resizeState.relativeTop,
                            left: resizeState.relativeLeft,
                            width: resizeState.width,
                            height: resizeState.height
                        }}
                    >
                        <CloseIconRoot
                            className="pip-close-button"
                            title={strings.videoPlayerBranding.closeText}
                            onClick={closePlayer}
                            onTouchStartCapture={closePlayer}
                        >
                            <IconClose fill="#FFF" stroke="#FFF" />
                        </CloseIconRoot>
                        {content}
                    </ResizableRoot>
                </Resizable>
            </Draggable>
        </>
    )
}

interface PictureInPicturePlayerRootProps {
    positionY: string
    positionX: string
    width: string
    height: string
}

const LoadingPlayerRoot = styled.div<PictureInPicturePlayerRootProps>`
    ${(props) => `width: ${props.width};`}
    ${(props) => `height: ${props.height};`}
    position: absolute;
    z-index: 100;
    border: 1px solid rgba(0, 0, 0, 0.5);
    background-color: #000;
`

export const ResizableRoot = styled.div`
    // opacity is used here, because display: none causes the player to flicker on resize
    .react-resizable-handle {
        opacity: 0;

        @media (max-width: 769px) {
            opacity: 1;
        }
    }
    :hover .react-resizable-handle {
        opacity: 1;
    }

    position: absolute !important;
    z-index: 15000 !important;
    /* https://developers.google.com/web/fundamentals/performance/rendering/stick-to-compositor-only-properties-and-manage-layer-count#use_transform_and_opacity_changes_for_animations */
    will-change: transform;

    #virtualGuide & .layoutRoot {
        display: flex;
        background: #eee;
        margin-bottom: 20px;
        flex-wrap: wrap;
    }
    #virtualGuide & .absoluteLayout {
        height: 600px;
        position: relative;
        justify-content: center;
        align-items: center;
    }
    #virtualGuide & .scaledLayout {
        width: 125%;
        left: -12.5%;
        transform: scale(0.75);
        margin-top: -7.5%;
    }

    #virtualGuide & .box {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: black;
        text-align: center;
        box-sizing: border-box;
        overflow: hidden;
        position: relative;
    }
    #virtualGuide & .box .text {
        text-align: center;
    }

    #virtualGuide & .absolutely-positioned {
        position: absolute !important;
    }
    #virtualGuide & .left-aligned {
        left: 0;
    }
    #virtualGuide & .right-aligned {
        right: 0;
    }
    #virtualGuide & .top-aligned {
        top: 0;
    }
    #virtualGuide & .bottom-aligned {
        bottom: 0;
    }

    #virtualGuide & .custom-box {
        overflow: visible;
    }
    #virtualGuide & .custom-handle {
        position: absolute;
        width: 8px;
        height: 8px;
        background-color: #1153aa;
        opacity: 0.75;
        border-radius: 4px;
    }
    #virtualGuide & .custom-handle-sw {
        bottom: -4px;
        left: -4px;
        cursor: sw-resize;
    }
    #virtualGuide & .custom-handle-se {
        bottom: -4px;
        right: -4px;
        cursor: se-resize;
    }
    #virtualGuide & .custom-handle-nw {
        top: -4px;
        left: -4px;
        cursor: nw-resize;
    }
    #virtualGuide & .custom-handle-ne {
        top: -4px;
        right: -4px;
        cursor: ne-resize;
    }
    #virtualGuide & .custom-handle-w,
    #virtualGuide & .custom-handle-e {
        top: 50%;
        margin-top: -4px;
        cursor: ew-resize;
    }
    #virtualGuide & .custom-handle-w {
        left: -4px;
    }
    #virtualGuide & .custom-handle-e {
        right: -4px;
    }
    #virtualGuide & .custom-handle-n,
    #virtualGuide & .custom-handle-s {
        left: 50%;
        margin-left: -4px;
        cursor: ns-resize;
    }
    #virtualGuide & .custom-handle-n {
        top: -4px;
    }
    #virtualGuide & .custom-handle-s {
        bottom: -4px;
    }
    #virtualGuide & .react-resizable {
        position: relative;
    }
    #virtualGuide & .react-resizable-handle {
        position: absolute;
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        background-origin: content-box;
        box-sizing: border-box;
        background: url("/videoPlayerIcons/arrow-head-down.svg");
        filter: invert(100%);
        /* background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+'); */
        background-position: bottom right;
        padding: 0 3px 3px 0;
        z-index: 10000;
    }
    #virtualGuide & .react-resizable-handle-sw {
        bottom: 0;
        left: 0;
        cursor: sw-resize;
        transform: rotate(45deg);
    }
    #virtualGuide & .react-resizable-handle-se {
        bottom: 0;
        right: 0;
        cursor: se-resize;
        transform: rotate(-45deg);
    }
    #virtualGuide & .react-resizable-handle-nw {
        top: 0;
        left: 0;
        cursor: nw-resize;
        transform: rotate(135deg);
    }
    #virtualGuide & .react-resizable-handle-ne {
        top: 0;
        right: 0;
        cursor: ne-resize;
        transform: rotate(225deg);
    }
    #virtualGuide & .react-resizable-handle-w,
    #virtualGuide & .react-resizable-handle-e {
        top: 50%;
        margin-top: -10px;
        cursor: ew-resize;
    }
    #virtualGuide & .react-resizable-handle-w {
        left: 0;
        transform: rotate(90deg);
    }
    #virtualGuide & .react-resizable-handle-e {
        right: 0;
        transform: rotate(270deg);
    }
    #virtualGuide & .react-resizable-handle-n {
        left: 50%;
        margin-left: -10px;
        cursor: ns-resize;
        transform: rotate(180deg);
    }

    #virtualGuide & .react-resizable-handle-s {
        left: 50%;
        margin-left: -10px;
        cursor: ns-resize;
    }
    #virtualGuide & .react-resizable-handle-n {
        top: 0;
        transform: rotate(180deg);
    }
    #virtualGuide & .react-resizable-handle-s {
        bottom: 0;
    }
`

const CloseIconRoot = styled.div`
    position: absolute;
    top: 16px;
    right: 20px;
    z-index: 10001;
    cursor: pointer;
    // opacity is used here, because display: none can cause the player to flicker on resize
    opacity: 0;

    @media (max-width: 769px) {
        opacity: 1;
    }

    ${ResizableRoot}:hover & {
        opacity: 1;
    }
`

export default PictureInPictureVideoPlayer
