// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import { useState, useEffect } from "react"

import * as React from "react"
import styled from "styled-components"
import { detect } from "detect-browser"
import { useLanguageState } from "../../globalStates/LanguageState"
import branding from "../../branding/branding"
import {
    IconCamera,
    IconMicrophone,
    IconVolumeHigh,
    IconArrowHeadDown,
    IconArrowHeadUp,
    IconCheckmarkGreen
} from "../../ui/Icons"
import { useDevices } from "../hooks/useDevices"

const DeviceSwitcherRoot = styled.div`
    font-family: ${branding.font1};
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    margin-left: 15px;
    margin-right: 10px;
    background-color: #fff;
    width: inherit;
`

const DeviceSwitcherRowRoot = styled.div`
    display: grid;
    grid-template-columns: 15% 65% 15%;
    padding-bottom: 10px !important;
    margin-top: 5px;
    margin-bottom: 5px;
    color: ${branding.deviceSwitcher.deviceSwitcherRowColor ?? "#000"};
`

const browserCheckResult = detect()
const isFirefox = browserCheckResult && browserCheckResult.type === "browser" && browserCheckResult.name === "firefox"
const isSafari = browserCheckResult && browserCheckResult.type === "browser" && browserCheckResult.name === "safari"

interface DeviceSwitcherRowProps {
    icon: any
    showOptions: boolean
    setShowOptions: (show: boolean) => void
    currentDevice?: any
    devicesList: any
    title: string
    noDevicesLabel: string
    setDevice: (deviceId: string, deviceLabel: string) => void
}

const DeviceSwitcherRow: React.FunctionComponent<DeviceSwitcherRowProps> = React.memo((props: DeviceSwitcherRowProps) => {
    return (
        <>
            <DeviceSwitcherRowRoot style={{ cursor: "pointer" }} onClick={() => props.setShowOptions(!props.showOptions)}>
                {props.icon}
                <p>{props.currentDevice?.label || props.title}</p>
                <div style={{ marginLeft: "25px" }}>
                    {props.showOptions
                        ? IconArrowHeadUp({ fill: branding.sideIconBar.sideIconColorDark })
                        : IconArrowHeadDown({ fill: branding.sideIconBar.sideIconColorDark })}
                </div>
                <br />
            </DeviceSwitcherRowRoot>
            {props.showOptions && (
                <>
                    {props.devicesList.length > 0 &&
                        props.devicesList.map((device: any) => {
                            return (
                                <DeviceSwitcherRowRoot
                                    key={device.deviceId}
                                    id={device.deviceId}
                                    style={{ cursor: "pointer" }}
                                    onClick={async () => {
                                        props.setDevice(device?.deviceId!, device?.label!)
                                    }}
                                >
                                    <div />
                                    <p style={{ color: device.deviceId === props.currentDevice?.value ? "#00B300" : "black" }}>
                                        {device.label}
                                    </p>
                                    <div
                                        style={{
                                            marginLeft: "25px",
                                            visibility: device.deviceId === props.currentDevice?.value ? "visible" : "hidden"
                                        }}
                                    >
                                        {IconCheckmarkGreen}
                                    </div>
                                    <br />
                                </DeviceSwitcherRowRoot>
                            )
                        })}
                    {props.devicesList.length === 0 && (
                        <DeviceSwitcherRowRoot
                            style={{ cursor: "pointer" }}
                            onClick={() => props.setShowOptions(!props.showOptions)}
                        >
                            <div />
                            <p>{props.noDevicesLabel}</p>
                            <div />
                            <br />
                        </DeviceSwitcherRowRoot>
                    )}
                </>
            )}
        </>
    )
})

export function checkIfLocalStorageItemExists(item: string, special?: boolean): boolean {
    if (
        special ||
        (localStorage.getItem(item) && localStorage.getItem(item) !== null && localStorage.getItem(item)?.length! > 0)
    ) {
        return true
    }

    return false
}

const DeviceSwitcher: React.FunctionComponent = () => {
    const deviceSwitcherState = useDevices()
    const strings = useLanguageState().getStrings().deviceSwitcher
    const [showVideoInputOptions, setShowVideoInputOptions] = useState<boolean>(false)
    const [showAudioInputOptions, setShowAudioInputOptions] = useState<boolean>(false)
    const [showAudioOutputOptions, setShowAudioOutputOptions] = useState<boolean>(false)

    if (!isSafari) {
        deviceSwitcherState.ensureDevices(
            checkIfLocalStorageItemExists("audioinputdevices") &&
                checkIfLocalStorageItemExists("audiooutputdevices", isFirefox || isSafari || false) &&
                checkIfLocalStorageItemExists("videoinputdevices")
        )
    }

    useEffect(() => {
        if (isSafari && (showVideoInputOptions === true || showAudioInputOptions === true)) {
            deviceSwitcherState.ensureDevices(
                checkIfLocalStorageItemExists("audioinputdevices") &&
                    checkIfLocalStorageItemExists("audiooutputdevices", isFirefox || isSafari || false) &&
                    checkIfLocalStorageItemExists("videoinputdevices")
            )
        }
    }, [showVideoInputOptions, showAudioInputOptions]) //eslint-disable-line

    return (
        <DeviceSwitcherRoot>
            <DeviceSwitcherRow
                icon={IconCamera({ fill: branding.sideIconBar.sideIconColorDark })}
                showOptions={showVideoInputOptions}
                setShowOptions={setShowVideoInputOptions}
                currentDevice={deviceSwitcherState.currentVideoInputDevice()}
                devicesList={
                    localStorage.getItem("videoinputdevices")
                        ? JSON.parse(localStorage.getItem("videoinputdevices")!)
                        : deviceSwitcherState.videoInputDevices()
                }
                title={strings.videoInputLabel}
                noDevicesLabel={strings.noVideoInputsLabel}
                setDevice={deviceSwitcherState.setVideoInputDevice}
            />

            <DeviceSwitcherRow
                icon={IconMicrophone({ fill: branding.sideIconBar.sideIconColorDark })}
                showOptions={showAudioInputOptions}
                setShowOptions={setShowAudioInputOptions}
                currentDevice={deviceSwitcherState.currentAudioInputDevice()}
                devicesList={
                    localStorage.getItem("audioinputdevices")
                        ? JSON.parse(localStorage.getItem("audioinputdevices")!)
                        : deviceSwitcherState.audioInputDevices()
                }
                title={strings.audioInputLabel}
                noDevicesLabel={strings.noAudioInputsLabel}
                setDevice={deviceSwitcherState.setAudioInputDevice}
            />

            {!isFirefox && !isSafari && (
                <DeviceSwitcherRow
                    icon={IconVolumeHigh({ fill: branding.sideIconBar.sideIconColorDark })}
                    showOptions={showAudioOutputOptions}
                    setShowOptions={setShowAudioOutputOptions}
                    currentDevice={deviceSwitcherState.currentAudioOutputDevice()}
                    devicesList={
                        localStorage.getItem("audiooutputdevices")
                            ? JSON.parse(localStorage.getItem("audiooutputdevices")!)
                            : deviceSwitcherState.audioOutputDevices()
                    }
                    title={strings.audioOutputLabel}
                    noDevicesLabel={strings.noAudioOutputsLabel}
                    setDevice={deviceSwitcherState.setAudioOutputDevice}
                />
            )}
        </DeviceSwitcherRoot>
    )
}

export default DeviceSwitcher
