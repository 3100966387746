import { useState, useEffect } from "react"
import branding from "../../branding/branding"
import { useHistory } from "react-router-dom"
import { registerWithPassword, registerWithSSO as registerWithToken } from "./LoginHelper"
import { useLoggedInState } from "../../globalStates/LoggedInUser"
import { useFavoriteState } from "../../globalStates/Favorites"
import { DataPrivacyDoc } from "../../backendServices/BackendServices"

export default function useQueryParamLogin(dataPrivacyDoc: DataPrivacyDoc) {
    const loggedInContext = useLoggedInState()
    const favoriteContext = useFavoriteState()
    const [isLoggingInViaQueryParam, setIsLoggingInViaQueryParam] = useState(true)
    const history = useHistory()

    useEffect(() => {
        async function doLogin() {
            const params = new URLSearchParams(window.location.search)
            const password = params.get(branding.loginRegistrationSite.passwordQueryParam)
            const email = params.get(branding.loginRegistrationSite.emailQueryParam)
            const token = params.get("token")

            if (password && email) {
                params.delete(branding.loginRegistrationSite.passwordQueryParam)
                params.delete(branding.loginRegistrationSite.emailQueryParam)
                history.replace({
                    search: params.toString(),
                })

                await registerWithPassword(loggedInContext, favoriteContext, email, password, dataPrivacyDoc).then((resp) => {
                    // Nothing else todo. Either this login works, or we fall back an the loginKind login defined in the branding
                    localStorage.removeItem("logoutReason")
                })
            } else if (token) {
                params.delete("token")
                history.replace({
                    search: params.toString(),
                })

                await registerWithToken(loggedInContext, favoriteContext, token, dataPrivacyDoc).then((resp) => {
                    // Nothing else todo. Either this login works, or we fall back an the loginKind login defined in the branding
                    localStorage.removeItem("logoutReason")
                })
            }
            setIsLoggingInViaQueryParam(false)
        }
        if (dataPrivacyDoc) doLogin()
    }, [dataPrivacyDoc]) // eslint-disable-line

    return isLoggingInViaQueryParam
}
