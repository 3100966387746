// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import { useEffect, useRef, useState, useMemo } from "react"

import * as React from "react"
import { useHistory, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useChimeContext, MeetingStatusCode, ShallowVideoTileState } from './context/ChimeContext';
import RemoteVideo from './components/RemoteVideo';
import { AttendeeData } from '../backendServices/BackendServices';
import { useLanguageState } from '../globalStates/LanguageState';
import { throttle } from 'lodash';
import RosterAttendeeType from './types/RosterAttendeeType';
import Controls from './components/Controls';
import Draggable from 'react-draggable';
import branding from "../branding/branding";
import { meetingPageRoute } from '../navigationArea/RoutePaths';
import { useActiveSpeakerContext } from './context/ActiveSpeakerContext';

export default function ConferenceOverlay() {
  const audioElement = useRef(null)
  const chime = useChimeContext()
  const isMeetingPage = useRouteMatch(meetingPageRoute)
  const meetingStatus = chime.getMeetingStatus().meetingStatus
  const isConferenceOverlayVisible = meetingStatus === MeetingStatusCode.Succeeded && !isMeetingPage
  useEffect(() => {
    if (meetingStatus !== MeetingStatusCode.Succeeded)
      return

    if (audioElement && audioElement.current)
      chime.bindAudioElement(audioElement)
    return () => {
      chime.unbindAudioElement()
    }
  },
    // eslint-disable-next-line
    [meetingStatus])

  return <>
    <audio ref={audioElement} style={{ display: 'none' }} muted={chime.getVolume() === 0} />
    {isConferenceOverlayVisible && <VideoOverlay />}
  </>
}

const VideoOverlayRoot = styled.div`
  position: absolute;
  left: 100px;
  bottom: 109px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 180px;
  width: 240px;
  cursor: pointer;
  z-index: 1000;

  .customLoader {
    border: none;
  }

  & {
    & > :last-child {
        display: block;
    }
  }

  & video + div {
    display: none; /** no name tag */
  }
`

// const StyledContentVideo = styled(ContentVideo)`
//   max-height: 100%;
//   background: ${branding.videoBackground};
// `

const NoAttendees = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: ${branding.mainInfoColor ?? "black"};
  font-family: ${branding.font1};
  font-size: ${branding.conferenceTexts.noAttendeesLabelTextSize ?? "16px"};
  color: #fff;
`
const RoomNameLabel = styled.div`
  display: none;
  position: absolute;
  bottom: 8px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 100%;
  padding: 3px 10px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background-color: #000;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const StyledControls = styled(Controls)`
  position: relative;
  bottom: 0px;
  height: 40px;
  margin-top: 2px;

  box-shadow: 0px 5px 15px 1px rgba(0, 0, 0, 0.4);
  border-radius: 10px;

  & button {
    height: 40px;
    width: 40px;
    border-radius: none;

    &:nth-child(5) {
      display: none;
    }
    &:nth-child(6) {
      display: none;
    }
  }
`
interface AttendeeDataWithType extends AttendeeData {
  type: "video" | "avatar"
}

function VideoOverlay() {
  const chime = useChimeContext()
  const videoElement = useRef<HTMLVideoElement>(null)
  const history = useHistory()
  const remoteTiles = chime.getRemoteTiles()
  const roster = chime.getRoster()
  const activeSpeakerContext = useActiveSpeakerContext()
  const activeSpeakers = activeSpeakerContext.getActiveSpeakers()
  const [displayedAttendee, setDisplayedAttendee] = useState<null | AttendeeDataWithType>(null)


  const strings = useLanguageState().getStrings()
  const [isDragging, setIsDragging] = useState(false)



  const rosterCheck = (roster: { [attendeeId: string]: RosterAttendeeType }, remoteTiles: ShallowVideoTileState[], activeSpeakers: string[], currentAttendee: null | { id: string, type: "video" | "avatar", name?: string }) => {
    const rosterKeys = Object.keys(roster)
    // if there is only one entry in the roster, the local user is it, therefore we are waiting for others
    if (rosterKeys.length <= 1) {
      setDisplayedAttendee(null)
      return
    }
    let rosterKeyToUse: string = ""
    // If we do have a attendee currently and he's still speaking, do nothing
    if (currentAttendee && activeSpeakers.indexOf(currentAttendee.id!) >= 0) {
      return
    }
    // Find the first speaker that is not the local user, and differs from the currentAttendee
    for (let activeSpeaker of activeSpeakers) {
      if (activeSpeaker !== chime.getLocalAttendeeId() && (currentAttendee === null || currentAttendee.id !== activeSpeaker)) {
        rosterKeyToUse = activeSpeaker
        break
      }
    }
    // Nobody speaking? And no currentAttendee? Select the first roster entry
    if (!rosterKeyToUse && (!currentAttendee || currentAttendee.id === "tmp")) {
      for (let rosterKey of rosterKeys) {
        if (rosterKey !== chime.getLocalAttendeeId()) {
          rosterKeyToUse = rosterKey
          break
        }
      }
    }

    // Try to find a corresponding remoteTile
    let remoteTileToUse: ShallowVideoTileState | null = null
    for (let remoteTile of remoteTiles) {
      if (remoteTile && remoteTile.boundAttendeeId === rosterKeyToUse)
        remoteTileToUse = remoteTile
    }
    // If remoteTile found, display it
    if (remoteTileToUse) {
      if (!currentAttendee || currentAttendee.id !== rosterKeyToUse) {
        chime.bindVideoElement(
          remoteTileToUse.tileId!,
          (videoElement.current as HTMLVideoElement)
        )
        setDisplayedAttendee({ id: rosterKeyToUse!, type: "video" })
      }
    }
    // Otherwise show an avatar for the name of the person
    else {
      // Show Avatar
      if (roster[rosterKeyToUse] && (!currentAttendee || currentAttendee.id !== rosterKeyToUse)) {
        setDisplayedAttendee({ ...roster[rosterKeyToUse], type: "avatar" })
      }
    }
  }

  const [throttledRosterCheck] = useThrottledCallback(rosterCheck, 2000);
  const tmpRoster = Object.keys(roster).join(",")
  const tmpRemoteVideoAttendees = remoteTiles.map(tile => tile.boundAttendeeId).join(",")
  const tmpActiveSpeakers = activeSpeakers.join(",")

  // show the current speaker
  useEffect(() => {
    if (chime.isScreenShareEnabled())
      return

    if (displayedAttendee)
      throttledRosterCheck(roster, remoteTiles, activeSpeakers, displayedAttendee)
    else
      rosterCheck(roster, remoteTiles, activeSpeakers, displayedAttendee)
  },
    // eslint-disable-next-line
    [history.location, tmpRoster, tmpRemoteVideoAttendees, tmpActiveSpeakers]);

  let mode: "empty" | "avatar" | "video" | "screenShare" = "empty"
  if (chime.isScreenShareEnabled())
    mode = "screenShare"
  else if (displayedAttendee?.type === "avatar")
    mode = "avatar"
  else if (displayedAttendee?.type === "video")
    mode = "video"
  else
    mode = "empty"

  let roomName = ""
  if (chime.getKind() === "virtualCafe") {
    for (let group of strings.meetingRoomGroups) {
      for (let meetingRoom of group.meetingRooms) {
        if (meetingRoom.id === chime.getExternalMeetingId()) {
          roomName = meetingRoom.title
          break
        }
      }
      if (roomName)
        break
    }
  }

  return (
    <Draggable bounds="html" onDrag={() => { setIsDragging(true) }} >
      <VideoOverlayRoot
        style={{ "display": "block" }}
        onClick={(event: React.MouseEvent) => {
          if (!isDragging) {
            chime.gotoCurrentMeeting()
            event.stopPropagation()
          }

          setIsDragging(false)
        }}
      >
        <div style={{
          height: '140px',
          boxShadow: '0px 5px 15px 1px rgba(0, 0, 0, 0.4)',
        }}
        >
          {mode === "screenShare" && <NoAttendees>{strings.conferenceTexts.currentlySharingScreen}</NoAttendees> }
          {/* && <StyledContentVideo guestBannerHeight={0} /> */}
          {
            // display change instead of dom appending because video must be present when we try to bind it in the useEffect
          }
          <div style={{ display: mode === "video" ? "block" : "none" }}>
            <RemoteVideo
              videoElementRef={videoElement}
              attendeeId={displayedAttendee ? displayedAttendee.id! : null}
            />
          </div>
          {
            mode === "avatar" &&
            <RemoteVideo
              attendeeId={displayedAttendee!.id!}
              attendeeName={displayedAttendee!.name}
              attendeeImg={displayedAttendee!.avatarUrl}
              attendeePosition={displayedAttendee!.position}
              attendeeCompany={displayedAttendee!.company}
              avatarSize={60}
            />
          }
          {mode === "empty" && <NoAttendees>{strings.conferenceTexts.noAttendees}</NoAttendees>}
          {roomName && <RoomNameLabel>{roomName}</RoomNameLabel>}
        </div>
        <StyledControls isDragging={isDragging} hideLocalVideo={true} hideHostedBy={true} smallButtons={true} redirectToRoom={false} />
      </VideoOverlayRoot >
    </Draggable >
  )
}

function useThrottledCallback(callback: any, delay: number) {

  const callbackfunc = useMemo(() => throttle(callback, delay), [callback, delay]);

  return [callbackfunc]
}