import { useEffect, useRef } from "react"
import * as React from "react"
import { NavLink, useRouteMatch } from "react-router-dom"
import styled from "styled-components"
import branding from "../branding/branding"
import { useLanguageState } from "../globalStates/LanguageState"
import { IconArrowBreadcrumb } from "../ui/Icons"
import { DesktopVersionContainer } from "../utils/Device"
import { trackNavigation } from "../utils/GTMTracking"
import { meetingPageRoute } from "./RoutePaths"
import useWindowDimensions from "../ui/WindowDimensionsHook"

const BreadcrumbContainer = styled.div<{ showShadow?: boolean; fillBackground?: boolean }>`
    box-shadow: ${(props) => (props.showShadow ? branding.primaryScrollDarkShadowTTB : "initial")};
    background: ${(props) => (props.fillBackground ? "#fff" : "initial")};
    position: relative;
    z-index: 10;
`

const BreadcrumbRoot = styled.div<{ foregroundColor: string }>`
    display: flex;
    color: ${(props) => props.foregroundColor};
    font: ${branding.topBar.breadcrumbTextFontFamily};
    font-size: ${branding.topBar.breadcrumbTextFontSize};
    text-transform: ${branding.topBar.breadcrumbTextTransform};
    letter-spacing: ${branding.topBar.breadcrumbLetterSpacing};
    flex-flow: row;
    flex-wrap: nowrap;
    width: 100%;
    cursor: pointer;
    white-space: nowrap;
    align-items: center;
    height: 20px;
    font-weight: ${branding.topBar.breadcrumbTextFontWeight};
`

const BreadcrumbItemSimple = styled.span`
    font-family: ${branding.topBar.breadcrumbTextFontFamily};
    font-size: ${branding.topBar.breadcrumbTextFontSize};
    line-height: 20px;
    text-transform: ${branding.topBar.breadcrumbTextTransform};
    letter-spacing: ${branding.topBar.breadcrumbLetterSpacing};
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
`
export interface BreadcrumbItem {
    to: string
    name: string
    onClick?: () => void
}

interface BreadcrumbProps {
    breadcrumb: BreadcrumbItem[]
    noTracking?: boolean
    trackedLocation?: string
    setRef?: Function
    customPadding?: string
    disableVirtPagePath?: boolean
    showShadow?: boolean
    fillBackground?: boolean
}

const Breadcrumb: React.FunctionComponent<BreadcrumbProps> = (props: BreadcrumbProps) => {
    let isMeetingPage = useRouteMatch(meetingPageRoute)
    const breadcrumbRef = useRef<HTMLDivElement>(null)

    const { isMobile } = useWindowDimensions()

    const strings = useLanguageState().getStrings()

    let textColor = isMeetingPage ? "#fff" : branding.mainInfoColor
    let textTransformValue: any = branding.topBar.breadcrumbTextTransform

    var trackedLocation = props.trackedLocation
    if (!trackedLocation && !props.noTracking) {
        const l = props.breadcrumb.length
        if (l > 0) {
            const loc = props.breadcrumb[l - 1]
            if (loc && loc?.to && loc?.to !== "") {
                trackedLocation = loc?.to || ""
            }
        }
    }

    const breadcrumpHash = props.breadcrumb?.map((e) => e?.to || "").join()
    useEffect(() => {
        if (props.disableVirtPagePath) {
            return
        }

        let pageTitle: string = props.breadcrumb[props.breadcrumb.length - 1]?.name || ""

        if (pageTitle.startsWith(strings.conferenceTexts.callWith)) {
            pageTitle = strings.conferenceTexts.callWith
        }

        trackNavigation(pageTitle)
    }, [breadcrumpHash]) // eslint-disable-line

    const buildBreadcrumbItem = (breadcrumbItem: BreadcrumbItem, index: number, last: boolean) => {
        if (!breadcrumbItem) return null
        if (last) {
            return (
                <BreadcrumbItemSimple
                    onClick={(e: any) => {
                        if (breadcrumbItem.onClick) {
                            e.stopPropagation()
                            breadcrumbItem.onClick()
                        }
                    }}
                    style={{ color: textColor }}
                    key={index}
                >
                    {breadcrumbItem.name}
                </BreadcrumbItemSimple>
            )
        } else {
            return (
                <React.Fragment key={index}>
                    <NavLink
                        onClick={(e: any) => {
                            if (breadcrumbItem.onClick) {
                                e.stopPropagation()
                                breadcrumbItem.onClick()
                            }
                        }}
                        style={{
                            fontFamily: branding.topBar.breadcrumbTextFontFamily,
                            color: textColor,
                            fontSize: branding.topBar.breadcrumbTextFontSize,
                            fontWeight: branding.topBar.breadcrumbTextFontWeight as
                                | "inherit"
                                | "-moz-initial"
                                | "initial"
                                | "revert"
                                | "unset"
                                | "bold"
                                | "normal"
                                | (number & {})
                                | "bolder"
                                | "lighter"
                                | undefined,
                            lineHeight: "20px",
                            letterSpacing: branding.topBar.breadcrumbLetterSpacing,
                            textTransform: textTransformValue
                        }}
                        to={breadcrumbItem.to}
                    >
                        {breadcrumbItem.name}
                    </NavLink>
                    <div style={{ marginRight: "5px", marginLeft: "5px" }}>
                        {IconArrowBreadcrumb({
                            fill: isMeetingPage ? branding.sideIconBar.sideIconColorLight ?? "#fff" : "currentColor"
                        })}
                    </div>
                </React.Fragment>
            )
        }
    }

    useEffect(() => {
        if (breadcrumbRef && breadcrumbRef.current && props.setRef) {
            props.setRef(breadcrumbRef)
        }
        // eslint-disable-next-line
    }, [breadcrumbRef])

    if (isMobile) return null

    return (
        <DesktopVersionContainer>
            <BreadcrumbContainer
                ref={breadcrumbRef}
                style={{ padding: props.customPadding || "25px 25px 15px 25px" }}
                showShadow={props.showShadow}
                fillBackground={props.fillBackground}
            >
                <BreadcrumbRoot foregroundColor={textColor!}>
                    {props.breadcrumb.map((breadcrumbItem, index) =>
                        buildBreadcrumbItem(breadcrumbItem, index, index >= props.breadcrumb.length - 1)
                    )}
                </BreadcrumbRoot>
            </BreadcrumbContainer>
        </DesktopVersionContainer>
    )
}

export default Breadcrumb
