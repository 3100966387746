import { useEffect, useRef } from "react"
import * as React from "react"

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref: React.RefObject<any>, onCLickOutside: () => void) {
    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                onCLickOutside()
            }
        }

        document.addEventListener("mousedown", handleClickOutside)
        return () => document.removeEventListener("mousedown", handleClickOutside)
    }, [ref, onCLickOutside])
}

interface OutsideAlerterProps {
    children: any
    onClickOutside: () => void
}

/**
 * Component that alerts if you click outside of it
 */
export default function OutsideAlerter(props: OutsideAlerterProps) {
    const wrapperRef = useRef(null)
    useOutsideAlerter(wrapperRef, props.onClickOutside)

    return <div ref={wrapperRef}>{props.children}</div>
}
