import * as React from "react"
import styled from 'styled-components'
import branding from "../../../branding/branding";
import AutoComplete from 'react-autocomplete'
import Highlighter from "react-highlight-words";
import { useLanguageState } from '../../../globalStates/LanguageState';
import { IconClearSearchItem, IconSearchSmall } from '../../../ui/Icons';
import { viewType } from '../NetworkingPageContentBranding';




const AutocompleteHighlighterRoot = styled.div<{ hideDropdown: boolean }>`
    width: 350px;
    position: relative;
    z-index: 1;
    /* margin-left: 25px;
    margin-top: 25px; */
    /* top: .5rem;  */
    .rs-picker-menu{
        width: 100%!important;
        border-radius: 0;
        border-bottom: ${branding.networkingArea.filterBorderBottomColor};
        background-color:  ${branding.dangerButtonColor};
    }
    & .position-autocomplete input{
        width: 100%;
        border: none;
        border-radius: 5px;
        border: ${branding.networkingArea.filterBorderBottomColor};
        color: ${branding.networkingArea.filterInputColor};
        outline: none;
        padding-left: 40px;
        height: 35px;

       &:not(:focus) {
           border: 1px solid #d9d9d9; 
        }
       &::placeholder{
        color: ${branding.networkingArea.filterPlaceholderColor} !important;
        }
    }

    /* Autocomplete dropdown menu root  */
    & .position-autocomplete > div:first-of-type > div:first-of-type{
       border: ${branding.networkingArea.filterBorderBottomColor};
       box-shadow: none;
       font-family: ${branding.font1}
    }
    & .search-icon{
        position: absolute;
        height: 100%;
        left: 15px;
        top: 67%;
        transform: translateY(-50%);
        color: ${branding.networkingArea.filterIconColor};
    }

    & .clear-icon{
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
    }

    & .position-autocomplete > div:first-of-type > div:first-of-type{
        display: ${props => props.hideDropdown ? "none" : "block"};
    }

    /* & input::placeholder {
    color: ${branding.networkingArea.filterIconColor};} */
/* 
    & input:focus {
    border: 2px solid "#red"}; */
 
`

const AHItem = styled.div`
    width: 100%;
    background-color: white;
    color: #727272;
    cursor: pointer;
    & .user-attribute-highlighter {
        padding: 8px;
        display: inline-block;
    }
    &:hover {
        background-color:${branding.networkingArea.autocompleteItemHoverColor};
        color: #fff;
    }
    &:hover mark{
        color: #fff;
    }
`

interface AutocompleteSearchProps {
    currentViewType: viewType
    userAttributeFilter: string
    searchValue: string
    userAttributes: string[]
    onHandleSearch(searchValue: string): void
    onSetUserAttributeFilter: (value: React.SetStateAction<string>) => void
    onSetSearchString: (value: React.SetStateAction<string>) => void
}

const AutocompleteSearch = (props: AutocompleteSearchProps) => {
    const languageState = useLanguageState()
    const strings = languageState.getStrings()
    const isrelevantOrInterestView = props.currentViewType === viewType.RELEVANT_TILES || props.currentViewType === viewType.USERS_FILTERED_BY_INTEREST

    return (
        <AutocompleteHighlighterRoot className={'autocomplete-root'} hideDropdown={isrelevantOrInterestView}>
            <div className={'position-autocomplete'}>
                <AutoComplete
                    wrapperStyle={{ width: '100%' }}
                    items={!isrelevantOrInterestView ? props.userAttributes.map(label => { return { label: label } }) : []}
                    getItemValue={(item) => item.label}
                    onChange={(e) => { isrelevantOrInterestView ? props.onHandleSearch(e.target.value) : props.onSetUserAttributeFilter(e.target.value); }}
                    onSelect={value => isrelevantOrInterestView ? props.onSetSearchString(value) : props.onSetUserAttributeFilter(value)}
                    inputProps={{ placeholder: strings.networkingArea.searchPlaceholderText, style: { fontFamily: branding.font1, fontSize: "12px"} }}
                    renderItem={(item, isHighlighted) =>
                        <AHItem>
                            <Highlighter
                                className={'user-attribute-highlighter'}
                                caseSensitive={false}
                                searchWords={[props.userAttributeFilter]}
                                highlightStyle={{ display: "inline", backgroundColor: "transparent", fontWeight: "bold", padding: 0 }}
                                textToHighlight={item.label}
                            />
                        </AHItem>
                    }
                    value={isrelevantOrInterestView ? props.searchValue : props.userAttributeFilter}
                />
            </div>
            <div>
                <span
                    onClick={() =>  props.userAttributeFilter !== "" ? props.onSetUserAttributeFilter("") : props.onHandleSearch("")}
                    className={'clear-icon'}
                    style={{ visibility: props.searchValue !== "" || props.userAttributeFilter !== "" ? 'visible' : 'hidden' }}>
                    {IconClearSearchItem({ fill: branding.networkingArea.filterIconColor })}
                </span>
            </div>
            <div className={'search-icon'}>{IconSearchSmall({ fill: branding.networkingArea.filterIconColor })}</div>
        </AutocompleteHighlighterRoot>
    )
}

export default AutocompleteSearch